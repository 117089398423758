import React, { useState, useEffect, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import "date-fns";

import { DateField, getTrip, updateTrip } from "./utils";

const EditTrip = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const [trip, setTrip] = useState();

  useEffect(() => {
    getTrip(id).then(setTrip);
  }, [id]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      updateTrip(id, trip)
        .then(() => {
          history.push("/show/" + id);
        })
        .catch((error) => console.error("Error updating trip: ", error));

      return false;
    },
    [id, trip, history]
  );

  const setStartDate = useCallback(
    (date) => {
      setTrip({ ...trip, startDate: date.toDateString() });
    },
    [setTrip, trip]
  );

  const setEndDate = useCallback(
    (date) => {
      setTrip({ ...trip, endDate: date.toDateString() });
    },
    [setTrip, trip]
  );

  const setTitle = useCallback(
    (e) => {
      setTrip({ ...trip, title: e.target.value });
    },
    [setTrip, trip]
  );

  return (
    <div className="container">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">EDIT TRIP</h3>
        </div>
        <div className="panel-body">
          <h4>
            <Link to={"/Trips"} className="btn btn-primary">
              Trip List
            </Link>
          </h4>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label for="title">Title:</label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={trip.title}
                onChange={setTitle}
                placeholder="Title"
              />
            </div>
            <Form.Row>
              <Form.Group as={Col}>
                <DateField
                  label="Start Date"
                  value={trip.startDate}
                  onChange={setStartDate}
                  maxDate={trip.endDate}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <DateField
                  label="End Date"
                  value={trip.endDate}
                  onChange={setEndDate}
                  minDate={trip.startDate}
                />
              </Form.Group>
            </Form.Row>
            <button type="submit" className="btn btn-success">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditTrip);
