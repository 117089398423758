import React, { useEffect, useState } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditor_config } from "../../../Constants/urls";
import { updateTrip } from "../utils";

const TransferDescription = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [comment, setComment] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [carTransfer, setCarTransfer] = useState([]);

  const handleComment = (e, index, editor) => {
    setComment(
      (carTransfer[index].transferComments.comment = editor.getData())
    );
  };

  useEffect(() => {
    console.log("TRANSFER: ", trip);
    if (!trip?.transfers.length && !trip?.transfer) return;

    const baseCall =
      Object.prototype.toString.call(trip.transfers || trip.transfer) ===
      "[object Object]"
        ? [trip.transfers || trip.transfer]
        : trip.transfers || trip.transfer;

    console.log(baseCall);
    setCarTransfer(baseCall);
  }, []);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      transfers: carTransfer,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {rendorFlashMessage()}
      {carTransfer.map((data, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>Transfer Car {index + 1}</h5>
            </div>
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group>
                <Form.Label>
                  <b>Comments</b>
                </Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  config={ckeditor_config}
                  onChange={(e, editor) => handleComment(e, index, editor)}
                  data={data.transferComments.comment}
                  value={data.transferComments.comment}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      ))}
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Transfer Comments
      </button>
    </>
  );
};

export default withRouter(TransferDescription);
