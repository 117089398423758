import React, { useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { updateTrip } from "../utils";

const MarkerMapLocation = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [destLatitude, setdestLatitude] = useState("");
  const [destLongitude, setdestLongitude] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [allFlights, setAllFlights] = useState([]);

  useEffect(() => {
    if (trip?.flights) {
      const baseCall =
        Object.prototype.toString.call(trip.flights) === "[object Object]"
          ? [trip.flights]
          : trip.flights;
      // const baseCall = trip.flights.mapMarkerLocation;
      // setLatitude(baseCall.latitude);
      // setlongitude(baseCall.longitude);
      // setdestLatitude(baseCall.destLatitude);
      // setdestLongitude(baseCall.destLongitude);
      setAllFlights(baseCall);
    }
  }, [trip?.flights]);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      flights: allFlights,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };
  return (
    <>
      {rendorFlashMessage()}
      <>
        {allFlights.map((data, index) => (
          <div key={index}>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <h5>Flight {index + 1}</h5>
              </div>
            </div>
            <hr />
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    placeholder="Latitude"
                    name="latitude"
                    value={data.mapMarkerLocation.latitude}
                    onChange={(e) =>
                      setLatitude(
                        (allFlights[index].mapMarkerLocation.latitude =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    placeholder="Longitude"
                    name="longitude"
                    value={data.mapMarkerLocation.longitude}
                    onChange={(e) =>
                      setLongitude(
                        (allFlights[index].mapMarkerLocation.longitude =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Dest Latitude</Form.Label>
                  <Form.Control
                    placeholder="Dest Latitude"
                    name="destLatitude"
                    value={data.mapMarkerLocation.destLatitude}
                    onChange={(e) =>
                      setdestLatitude(
                        (allFlights[index].mapMarkerLocation.destLatitude =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Dest Longitude</Form.Label>
                  <Form.Control
                    placeholder="Dest Longitude"
                    name="destLongitude"
                    value={data.mapMarkerLocation.destLongitude}
                    onChange={(e) =>
                      setdestLongitude(
                        (allFlights[index].mapMarkerLocation.destLongitude =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </div>
        ))}
      </>
      <button onClick={onSubmit} className="marginButtonBig stickyButton">
        Save Details.
      </button>
    </>
  );
};

export default withRouter(MarkerMapLocation);
