import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import ResturantDetail from "./ResturantDetail";
import ResturantDescription from "./ResturantDescription";
import ResturantPhoto from "./ResturantPhoto";
import ResturantMapMarker from "./ResturantMapMarker";
import Preview from "./Preview";

const Resturant = ({ trip }) => {
  const [ResturantFillDetails, setResturantFillDetails] =
    useState("resturantDetails");

  const renderElementForResturant = (ResturantFillDetails) => {
    if (ResturantFillDetails === "resturantDetails")
      return <ResturantDetail trip={trip} />;
    if (ResturantFillDetails === "Description")
      return <ResturantDescription trip={trip} />;
    if (ResturantFillDetails === "resturantPhoto")
      return <ResturantPhoto trip={trip} />;
    if (ResturantFillDetails === "resturantLocation")
      return <ResturantMapMarker trip={trip} />;
    if (ResturantFillDetails === "Previewresturant")
      return <Preview trip={trip} />;
  };

  return (
    <>
      <button
        className={
          ResturantFillDetails === "resturantDetails"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setResturantFillDetails("resturantDetails")}
      >
        Details
      </button>
      <button
        className={
          ResturantFillDetails === "Description"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setResturantFillDetails("Description")}
      >
        Description
      </button>
      <button
        className={
          ResturantFillDetails === "resturantPhoto"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setResturantFillDetails("resturantPhoto")}
      >
        Photo
      </button>
      <button
        className={
          ResturantFillDetails === "resturantLocation"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setResturantFillDetails("resturantLocation")}
      >
        Map Marker Location
      </button>
      <button
        className={
          ResturantFillDetails === "Previewresturant"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setResturantFillDetails("Previewresturant")}
      >
        Preview
      </button>
      <br />
      <br />
      {renderElementForResturant(ResturantFillDetails)}
    </>
  );
};

export default withRouter(Resturant);
