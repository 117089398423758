import React, { useCallback, useEffect, useState } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";

import { updateTrip, Field, DateTimeField } from "../utils";
import {
  dateFromTime,
  stringFromDate,
  timeFromDate,
} from "../../../utils/date-utils";

const TrainDetails = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [arrivalDate, setArrivalDate] = useState(stringFromDate(new Date()));
  const [arrivalTime, setArrivalTime] = useState(timeFromDate(new Date()));

  const [departureDate, setDepartureDate] = useState(
    stringFromDate(new Date())
  );
  const [departureTime, setDepartureTime] = useState(timeFromDate(new Date()));

  const [companyName, setCompanyName] = useState("");
  const [destinationStation, setDestinationStation] = useState("");
  const [originStation, setOriginStation] = useState("");
  const [trainName, setTrainName] = useState("");
  const [trainNumber, setTrainNumber] = useState("");

  const [isSave, setIsSave] = React.useState(false);
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);

  const onArrivalDateChange = useCallback(
    (date) => {
      setArrivalDate(stringFromDate(date));
    },
    [setArrivalDate]
  );

  const onArrivalTimeChange = useCallback(
    (time) => {
      setArrivalTime(timeFromDate(time));
    },
    [setArrivalTime]
  );

  const onDepartureDateChange = useCallback(
    (date) => {
      setDepartureDate(stringFromDate(date));
    },
    [setDepartureDate]
  );

  const onDepartureTimeChange = useCallback(
    (time) => {
      setDepartureTime(timeFromDate(time));
    },
    [setDepartureTime]
  );

  useEffect(() => {
    const {
      trains: { trainDetails },
    } = trip;

    setArrivalDate(trainDetails.arrivalDate);
    setArrivalTime(trainDetails.arrivalTime);
    setDepartureDate(trainDetails.departureDate);
    setDepartureTime(trainDetails.arrivalTime);
    setCompanyName(trainDetails.companyName);
    setDestinationStation(trainDetails.destinationStation);
    setOriginStation(trainDetails.originStation);
    setTrainName(trainDetails.trainName);
    setTrainNumber(trainDetails.trainNumber);
  }, [
    trip.id,
    setArrivalDate,
    setArrivalTime,
    setDepartureDate,
    setDepartureTime,
    setCompanyName,
    setDestinationStation,
    setOriginStation,
    setTrainName,
    setTrainNumber,
  ]);

  const onSubmit = useCallback(() => {
    const newTrain = {
      trainDetails: {
        arrivalDate,
        arrivalTime,
        departureDate,
        departureTime,
        companyName,
        destinationStation,
        originStation,
        trainName,
        trainNumber,
      },
    };

    trip.trains = newTrain;
    updateTrip(trip.id, archiveID, {
      trains: newTrain,
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  }, [
    arrivalDate,
    arrivalTime,
    departureDate,
    departureTime,
    companyName,
    destinationStation,
    originStation,
    trainName,
    trainNumber,
    trip.id,
    setIsSave,
  ]);

  const renderFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {renderFlashMessage()}
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Train Number</Form.Label>
            <Form.Control
              placeholder="Train Number"
              onChange={(e) => setTrainNumber(e.target.value)}
              value={trainNumber}
              name="trainNumber"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Train Name</Form.Label>
            <Form.Control
              placeholder="Train Name"
              onChange={(e) => setTrainName(e.target.value)}
              value={trainName}
              name="trainName"
            />
          </Form.Group>
          <Form.Group>
            <DateTimeField
              dateLabel="Departure Date"
              date={departureDate ? departureDate : minDate}
              setDate={onDepartureDateChange}
              timeLabel="Departure Time"
              time={departureTime ? dateFromTime(departureTime) : new Date()}
              setTime={onDepartureTimeChange}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Company</Form.Label>
            <Form.Control
              placeholder="SNCF"
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
              name="companyName"
            />
          </Form.Group>
          <Form.Group>
            <DateTimeField
              dateLabel="Arrival Date"
              date={arrivalDate ? arrivalDate : maxDate}
              setDate={onArrivalDateChange}
              timeLabel="Arrival Time"
              time={arrivalTime ? dateFromTime(arrivalTime) : new Date()}
              setTime={onArrivalTimeChange}
              minDate={departureDate}
              maxDate={maxDate}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Field
            label="Origin"
            as="textarea"
            placeholder="Origin"
            onChange={(e) => setOriginStation(e.target.value)}
            value={originStation}
            name="originStation"
          />
          <Field
            label="Destination"
            as="textarea"
            placeholder="Brussel-South Railway, 060 Sain-Gilles, Belgium"
            onChange={(e) => setDestinationStation(e.target.value)}
            value={destinationStation}
            name="destinationStation"
          />
        </Form.Row>
      </Form>
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Train Details
      </button>
    </>
  );
};

export default withRouter(TrainDetails);
