import React, { useEffect, useState } from "react";
import "date-fns";
import { withRouter } from "react-router-dom";

const Preview = ({ trip }) => {
  const [allFlights, setAllFlights] = useState([]);

  useEffect(() => {
    if (trip?.flights) {
      const baseCall =
        Object.prototype.toString.call(trip.flights) === "[object Object]"
          ? [trip.flights]
          : trip.flights;
      setAllFlights(baseCall);
    }
  }, [trip?.flights]);

  var flightdetails;
  if (allFlights == "") {
    flightdetails = " no flights";
  } else {
    flightdetails = "Flight Preview";
  }

  return (
    <>
      <p>
        {flightdetails}

        <>
          {allFlights ? (
            <>
              {allFlights.map((data, index) => (
                <div key={index}>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Flight {index + 1}</h5>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-3">
                      <h5>Airports</h5>
                      <h6>Origin</h6>
                      Departure Date & Time: {data.airports.departureDate}{" "}
                      {data.airports.departureTime}
                      <br />
                      Name: {data.airports.airportName}
                      <br />
                      Origin: {data.airports.airportOrigin}
                      <br />
                      Origin City: {data.airports.originCity}
                      <br />
                      Origin Terminal: {data.airports.originTerminal}
                      <br />
                      {/* Arrival Time: <br/> */}
                      <br />
                      <h6>Destination</h6>
                      Arrival Date & Time: {data.airports.arrivalDate}{" "}
                      {data.airports.arrivalTime}
                      {/* Departure Time: <br/> */}
                      <br />
                      Destination: {data.airports.airportDestination} <br />
                      Destination Name {data.airports.airportDestinationName}
                      <br />
                      Destination City: {data.airports.destinationCity}
                      <br />
                      Destination Terminal: {data.airports.destinationTerminal}
                      <br />
                    </div>
                    <div className="col-md-3">
                      <h5>Details</h5>
                      Airline Name: {data.details.airLineName}
                      <br />
                      Airline Code: {data.details.airlineCode}
                      <br />
                      Flight Number: {data.details.flightNumber}
                      <br />
                      Selected Date: {data.details.selectedDate}
                      <br />
                    </div>
                    <div className="col-md-3">
                      <h5>Map Location</h5>
                      Destination Latitude:{" "}
                      {data.mapMarkerLocation.destLatitude}
                      <br />
                      Destination Longitude:{" "}
                      {data.mapMarkerLocation.destLongitude}
                      <br />
                      Latitude: {data.mapMarkerLocation.latitude}
                      <br />
                      Longtitude: {data.mapMarkerLocation.longtiture}
                      <br />
                    </div>
                    <div className="col-md-3">
                      <h5>Plane</h5>
                      Confirmation No: {data.plane.conFirmationNo}
                      <br />
                      Seats: {data.plane.Seats}
                      <br />
                      Class of Service: {data.plane.ClassOfService}
                      <br />
                      Meal Service: {data.plane.MealService}
                      <br />
                      <br />
                      <h6>
                        <b>Aircraft's plane</b>
                      </h6>
                      Equipiment: {data.plane.Equipiment}
                      <br />
                      <br />
                      <h6>
                        <b>Code-share plane</b>
                      </h6>
                      Codeshare:{" "}
                      {data.plane.Codeshare === "checked" ? "Yes" : "No"}
                      <br />
                      Codeshare Information:{" "}
                      {data.plane.CodeshareInformationComments}
                      <br />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            " "
          )}
        </>
      </p>
    </>
  );
};

export default withRouter(Preview);
