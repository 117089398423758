import React, { useCallback, useEffect, useState } from "react";
import { Form, Col, Alert, Row } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import { createCarRental } from "../controllers/car-rentals";
import {
  updateTrip,
  imageUpload,
  useIndexedState,
  FormImage,
  DateTimeField,
  Field,
  TripImage,
} from "../utils";
import {
  dateFromTime,
  stringFromDate,
  timeFromDate,
} from "../../../utils/date-utils";

const AddRentalCar = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [isSave, setIsSave] = React.useState(false);
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);
  const [cars, setCars] = useState([]);
  const { modifyAt, push, removeAt, withModifyAt } = useIndexedState(
    cars,
    setCars
  );

  const upload = withModifyAt((car, modify) =>
    imageUpload("cars", car.imageFile).then((url) => modify({ ...car, url }))
  );

  const removeImage = modifyAt((car) => ({
    ...car,
    url: null,
  }));

  const setCompanyName = modifyAt((car, e) => ({
    ...car,
    carRentalsDetails: {
      ...car.carRentalsDetails,
      companyName: e.target.value,
    },
  }));

  const setCompanyPhone = modifyAt((car, e) => ({
    ...car,
    carRentalsDetails: {
      ...car.carRentalsDetails,
      companyPhone: e.target.value,
    },
  }));

  const setPickUpLocation = modifyAt((car, e) => ({
    ...car,
    carRentalsDetails: {
      ...car.carRentalsDetails,
      pickUpLocation: e.target.value,
    },
  }));

  const setDropLocation = modifyAt((car, e) => ({
    ...car,
    carRentalsDetails: {
      ...car.carRentalsDetails,
      dropLocation: e.target.value,
    },
  }));

  const setStartDate = modifyAt((car, date) => ({
    ...car,
    carRentalsDetails: {
      ...car.carRentalsDetails,
      startDate: stringFromDate(date),
    },
  }));

  const setStartTime = modifyAt((car, time) => ({
    ...car,
    carRentalsDetails: {
      ...car.carRentalsDetails,
      startTime: timeFromDate(time),
    },
  }));

  const setEndDate = modifyAt((car, date) => ({
    ...car,
    carRentalsDetails: {
      ...car.carRentalsDetails,
      endDate: stringFromDate(date),
    },
  }));

  const setEndTime = modifyAt((car, time) => ({
    ...car,
    carRentalsDetails: {
      ...car.carRentalsDetails,
      endTime: timeFromDate(time),
    },
  }));

  const setImageFile = modifyAt((car, e) => ({
    ...car,
    imageFile: e.target.files[0],
  }));

  const addCarDetails = push(() =>
    createCarRental(new Date(), minDate, maxDate)
  );

  useEffect(() => {
    if (!trip.carRentals) return;

    setCars(trip.carRentals);
  }, [trip.id]);

  const onSubmit = useCallback(() => {
    const newCarRentals = cars.map((x) => ({
      ...x,
      imageFile: null,
    }));

    trip.carRentals = newCarRentals;
    updateTrip(trip.id, archiveID, {
      carRentals: newCarRentals,
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  }, [trip.id, cars, setIsSave]);

  const renderFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {renderFlashMessage()}
      {cars.map((data, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>Rental Car {index + 1}</h5>
            </div>
            <div className="col-md-6 text-right">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={removeAt(index)}
              >
                Remove
              </Button>
            </div>
          </div>
          <hr />
          <Form>
            <Form>
              <Form.Group as={Row} className="ml-1">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  style={{ maxWidth: "90%" }}
                  placeholder="Company"
                  as="textarea"
                  name="companyName"
                  value={data.carRentalsDetails.companyName}
                  onChange={setCompanyName(index)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="mr-2">Photo</Form.Label>
                <FormImage onChange={setImageFile(index)} />
                <br />
                <Button
                  onClick={upload(index)}
                  className="btn btn-success btn-sm"
                >
                  Upload
                </Button>
                <Button
                  onClick={removeImage(index)}
                  className="btn btn-danger btn-sm remove"
                >
                  Remove
                </Button>
              </Form.Group>
              <Form.Group as={Col}>
                <TripImage
                  src={data.url}
                  alt="Rental car preview"
                  height="130px"
                  width="160px"
                />
              </Form.Group>
            </Form>
            <Form.Row>
              <Form.Group>
                <DateTimeField
                  dateLabel="Pickup Date"
                  date={
                    data.carRentalsDetails.startDate
                      ? data.carRentalsDetails.startDate
                      : minDate
                  }
                  setDate={setStartDate(index)}
                  timeLabel="Pickup Time"
                  time={
                    data.carRentalsDetails.startTime
                      ? dateFromTime(data.carRentalsDetails.startTime)
                      : new Date()
                  }
                  setTime={setStartTime(index)}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Field
                label="Phone"
                placeholder="+41 123456789"
                value={data.carRentalsDetails.companyPhone}
                onChange={setCompanyPhone(index)}
              />
              <Form.Group>
                <DateTimeField
                  dateLabel="Drop Date"
                  date={
                    data.carRentalsDetails.endDate
                      ? data.carRentalsDetails.endDate
                      : maxDate
                  }
                  setDate={setEndDate(index)}
                  timeLabel="Drop Time"
                  time={
                    data.carRentalsDetails.endTime
                      ? dateFromTime(data.carRentalsDetails.endTime)
                      : new Date()
                  }
                  setTime={setEndTime(index)}
                  minDate={data.carRentalsDetails.startDate}
                  maxDate={maxDate}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Field
                label="Pick Up Location"
                value={data.carRentalsDetails.pickUpLocation}
                onChange={setPickUpLocation(index)}
              />
              <Field
                label="Drop Location"
                value={data.carRentalsDetails.dropLocation}
                onChange={setDropLocation(index)}
              />
            </Form.Row>
          </Form>
        </div>
      ))}
      <button className="marginButtonffor" onClick={addCarDetails}>
        Add More
      </button>
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Car Rental Details
      </button>
    </>
  );
};

export default withRouter(AddRentalCar);
