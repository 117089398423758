import firebase from "firebase";
import "firebase/storage";

// https://firebase.google.com/docs/web/setup?authuser=0

// See firebase setup in above google firebase documentation url
export const config = {
  // ================================================================
  //TJ's Firebase PRODUCTION Credentials
  // =================================================================
  apiKey: "AIzaSyBByLrl2eZBJj8CwsdMVWvfq1mSe5ZvpLs",
  authDomain: "tripthing-48348.firebaseapp.com",
  databaseURL: "https://tripthing-48348.firebaseio.com",
  projectId: "tripthing-48348",
  storageBucket: "tripthing-48348.appspot.com",
  messagingSenderId: "461095309195",
  appId: "1:461095309195:web:e439bc1d5be5110120a98e",
  measurementId: "G-9BXZ4EWE57",
  // ================================================================
  //AMAN'S Firebase TESTING Credentials
  //DO NOT USE WITH PERMISSION, IT ONLY USED FOR FETCHING THE DATA
  // =================================================================
  // apiKey: "AIzaSyAbTSbYkZGt6BfDjhsrRIJutCCwTtZlS6w",
  // authDomain: "golf-testing-2d98a.firebaseapp.com",
  // databaseURL: "https://golf-testing-2d98a.firebaseio.com",
  // projectId: "golf-testing-2d98a",
  // storageBucket: "golf-testing-2d98a.appspot.com",
  // messagingSenderId: "18769333878",
  // appId: "1:18769333878:web:d151c951ab425b35b19957",
  // measurementId: "G-83K4XG7VN7"
  // ================================================================
  //MANNY'S Firebase Original Credentials
  //DO NOT USE WITHOUT PERMISSION, IT ONLY USED FOR FETCHING THE DATA
  // =================================================================
  // apiKey: "AIzaSyBnLuX_O66ADhqmB8x7e-g5t3UrExwlX3s",
  // authDomain: "golf-away-tours.firebaseapp.com",
  // databaseURL: "https://golf-away-tours.firebaseio.com",
  // projectId: "golf-away-tours",
  // storageBucket: "golf-away-tours.appspot.com",
  // messagingSenderId: "9920488247",
  // appId: "1:9920488247:web:b57f48f683203bb92b70b2",
  // measurementId: "G-TZTZEX9F29"
};
firebase.initializeApp(config);
// export const alltrips = firebase.firestore().collection("Tripss");
// //
// export const databaseRef = firebase.firestore()
//

const functions = firebase.functions();

// functions.useFunctionsEmulator("http://localhost:5001");
export const fetchAlgoliaResult = async ({ collection, search }) => {
  return await functions.httpsCallable("fetchAlgoliaResult")({
    collection,
    search,
  });
};

export const storage = firebase.storage();

export default firebase;
