import React, { useCallback, useEffect, useState } from "react";
import "./css/TripDetails.css";
import "date-fns";
import { withRouter } from "react-router-dom";
import firebase from "../firebase-auth/Config";

import { copyTrip } from "./controllers/trips";
import { getCollectionNameFromId, getTrip } from "./utils";

const CopyTrip = ({
  history,
  match: {
    params: { id, archiveID },
  },
}) => {
  const collection = getCollectionNameFromId(archiveID);
  const [trip, setTrip] = useState();

  useEffect(() => {
    if (!id || !collection) return;
    console.log(id, collection);
    getTrip(id, collection).then(setTrip);
  }, [id, collection]);

  const onSubmit = useCallback(() => {
    if (!trip) return;

    const tripsRef = firebase.firestore().collection("Tripss");
    const refUser = firebase.auth();
    const user_id = refUser.currentUser.uid;
    const userName = refUser.currentUser.displayName;

    const copied = copyTrip(trip, user_id, userName, new Date());

    tripsRef
      .add(copied)
      .then(() => history.goBack())
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }, [history, trip]);

  return (
    <>
      <center>
        {" "}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <button onClick={onSubmit} className="btn btn-warning">
          <b>Are You Sure To Copy This Trip</b>
        </button>
        <br />
        <br />
        <br />
        <br />
      </center>
    </>
  );
};

export default withRouter(CopyTrip);
