import React, { useState } from "react";
import "date-fns";
import { withRouter } from "react-router-dom";
import AddTransferDetails from "./AddTransferDetails";
import TransferDescription from "./TransferDescription";
import TransferMapMarker from "./TransferMapMarker";
import Preview from "./Preview";

const AddTransfer = ({ trip }) => {
  const [TransferDetailsFill, setTransferDetailsFill] =
    useState("TransferDetails");

  const renderElementForTransfer = (TransferDetailsFill) => {
    if (TransferDetailsFill === "TransferDetails")
      return <AddTransferDetails trip={trip} />;
    if (TransferDetailsFill === "Description")
      return <TransferDescription trip={trip} />;
    if (TransferDetailsFill === "TransferLocation")
      return <TransferMapMarker trip={trip} />;
    if (TransferDetailsFill === "PreviewTrain") {
      return <Preview trip={trip} />;
    }
  };

  return (
    <>
      <button
        className={
          TransferDetailsFill === "TransferDetails"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTransferDetailsFill("TransferDetails")}
      >
        Details
      </button>
      <button
        className={
          TransferDetailsFill === "Description"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTransferDetailsFill("Description")}
      >
        Description
      </button>
      {/* <button
        className={
          TransferDetailsFill === "TransferPhoto"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTransferDetailsFill("TransferPhoto")}
      >
        Photo
      </button> */}
      <button
        className={
          TransferDetailsFill === "TransferLocation"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTransferDetailsFill("TransferLocation")}
      >
        Map Marker Location
      </button>
      <button
        className={
          TransferDetailsFill === "PreviewTrain"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTransferDetailsFill("PreviewTrain")}
      >
        Preview
      </button>
      <br />
      <br />
      {renderElementForTransfer(TransferDetailsFill)}
    </>
  );
};
export default withRouter(AddTransfer);
