import React, { useState, useEffect } from "react";
import { Nav, NavDropdown, Row } from "react-bootstrap";
import { Form, Col, Alert } from "react-bootstrap";
import firebase from "../firebase-auth/Config";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { withRouter } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const EmailNotification = (props) => {
  const [data, setData] = useState("");
  const [emails, setEmails] = useState([]);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isSave, setIsSave] = React.useState(false);

  useEffect(() => {
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(props.match.params.id);

    cityRef.get().then((doc) => {
      if (doc.exists) {
        const allData = doc.data();
        console.log(allData);
        if (allData) {
          const baseColl = allData;
          console.log(baseColl, "I am ActivityData");
          setEmails(baseColl.emails);
          setData(baseColl);
        }
      }
    });
  }, []);
  const addEmail = (e, index) => {
    setEmails([
      ...index,
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    ]);
  };
  const URL = () => {
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(props.match.params.id);
  };
  const removeEmail = (e, index) => {
    emails.slice(index, 1);
    setEmails(emails);
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(props.match.params.id);

    var setWithMerge = cityRef.set(
      {
        emails: emails,
      },
      { merge: true }
    );
    setIsSave(true);
  };
  const onSubmit = (e) => {
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(props.match.params.id);

    var setWithMerge = cityRef.set(
      {
        emails: emails,
      },
      { merge: true }
    );
    setIsSave(true);
  };
  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };
  return (
    <>
      <div className="container">
        <div className="col-md-6">
          <br />
          <h1>
            <b>{data.title}</b>
            <br />
          </h1>
          <h3>
            {data.startDate} - {data.endDate}
          </h3>
        </div>
        <div className="col-md-6"></div>
        <div className="container">
          <div className="emailtitle">
            Trip Email Alerts <br />
            Automatically send emails about the day's activities to your client
            every day of thier trip.
          </div>
          {rendorFlashMessage()}
          <div className="card" style={{ backgroundColor: "white" }}>
            <div style={{ margin: "35px" }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <b>* Traveler's Home Time Zone</b>
                      <>
                        <TimezonePicker
                          absolute={false}
                          defaultValue="Europe/Moscow"
                          placeholder="Select timezone..."
                          // onChange={}
                        />
                      </>
                    </div>
                    {/* <div className="col-md-2"></div> */}
                    <div className="col-md-6">
                      <b>* Destination Primary City Time Zone</b>
                      <>
                        <TimezonePicker
                          absolute={false}
                          defaultValue="Europe/Moscow"
                          placeholder="Select timezone..."
                          // onChange={}
                        />
                      </>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {/* <div className="col-md-4"></div> */}
                  <div className="col-md-10">
                    <div className="row">
                      * Daily updates activated
                      <Form.Group>
                        <Form.Check type="checkbox" label="Yes" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Check type="checkbox" label="No" />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>
              <br /> <br />
              <div className="row">
                <div className="col-md-8">
                  {/* <h7>Email & Text Recipients</h7> <br /> */}
                  <div className="col-md-12">
                    <div className="info_body_body">
                      {emails ? (
                        <>
                          {emails.map((data, index) => (
                            <div key={index}>
                              <hr />
                              <div className="row">
                                <div className="col-md-6">
                                  <h5>Email & Text Recipients</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    // className={classes.button}
                                    startIcon={<DeleteIcon />}
                                    onClick={(e) => removeEmail(e, index)}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                              <hr />
                              <Form>
                                <div className="row">
                                  <Form.Group as={Col}>
                                    <Form.Label>
                                      <b>First Name</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="First Name"
                                      name="firstName"
                                      value={data.firstName}
                                      onChange={(e) =>
                                        setfirstName(
                                          (emails[index].firstName =
                                            e.target.value)
                                        )
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col}>
                                    <Form.Label>
                                      <b>Last Name</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Last Name"
                                      name="lastName"
                                      value={data.lastName}
                                      onChange={(e) =>
                                        setlastName(
                                          (emails[index].lastName =
                                            e.target.value)
                                        )
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className="row">
                                  <Form.Group as={Col}>
                                    <Form.Label>
                                      <b>Email</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Email"
                                      name="email"
                                      value={data.email}
                                      onChange={(e) =>
                                        setEmail(
                                          (emails[index].email = e.target.value)
                                        )
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col}>
                                    <b>Phone</b>
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Phone"
                                      name="phone"
                                      value={data.phone}
                                      onChange={(e) =>
                                        setPhone(
                                          (emails[index].phone = e.target.value)
                                        )
                                      }
                                    />
                                  </Form.Group>
                                </div>
                              </Form>
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      )}

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => addEmail(e, emails)}
                        type="submit"
                        style={{ backgroundColor: "green !important" }}
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        Add Recipent
                      </Button>
                      {/* <button
                        className="marginButtonffor"
                        onClick={(e) => addEmail(e, emails)}
                      >
                        Add More
                      </button> */}
                      <br />
                      <br />
                      <Button
                        variant="contained"
                        color="green"
                        onClick={onSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                  {/* <Button variant="primary" type="submit">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Add Recipent
                  </Button> */}
                </div>
                <div className="col-md-4">
                  {/* <div className="row"> */}
                  {/* <div className="col-md-3"></div>
                    <div className="col-md-3"></div> */}
                  {/* <div className="col-md-6"> */}
                  <h5>
                    {" "}
                    <b>Edit Email Signature </b>
                  </h5>
                  <br />

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Email signature</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="8"
                      placeholder="Any time you click on this link you will see the most up-to-date version of you"
                    />
                  </Form.Group>
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col-md-8">
                  <h3>
                    <b>Preview Emails</b>
                  </h3>
                  <ul class="nav nav-tabs">
                    <li class="active">
                      <a data-toggle="tab" href="#welcome">
                        {" "}
                        Welcome
                      </a>
                    </li>
                  </ul>
                  <br />
                  <div class="panel-heading palen">
                    <div class="panel-body">
                      <h4>Your Itinerary</h4>
                      <p class="text-info">
                        This email will be automatically delivered when you
                        click "Send Itinerary Email" above
                      </p>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>to:</b>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                              {data.emails ? (
                                <>{data.emails[0].firstName}</>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <ul class="list-inline email-header-recipients"></ul>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>cc:</b>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>contact@golfawaytours.com</td>
                          </tr>
                          <tr>
                            <td>
                              <b>subject:</b>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>Your Itinerary</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <header>
                        <img
                          alt="Rzofhdkbtgkfe9kcxwl6"
                          height="30px"
                          src="https://www.filepicker.io/api/file/rZOfHdkbTgKfE9KcxwL6"
                        />
                      </header>
                      <p>
                        Hi , <br />
                      </p>
                      <p>
                        I have completed work on your itinerary and I would like
                        to share it with you at this link:
                      </p>
                      <p>
                        {/* <a href="#" target="_blank"> */}
                          Itinerary Overview
                        {/* </a> */}
                      </p>
                      <p>
                        Any time you click on this link you will see the most
                        up-to-date version of your itinerary. Please let me know
                        what you think or if you have any questions or concerns.
                      </p>
                      <p>Thank you for using Golf Away Tours!</p>
                      <p>Best Regards,</p>
                      <p>TJ</p>
                      <a href={`/${props.match.params.id}/pdf`}>Download PDF</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(EmailNotification);
