import React, { useState } from "react";
import ActivityDescription from "./ActivityDescription";
import AddActivityDetails from "./AddActivityDetails";
import ActivityMapMarker from "./ActivityMapMarker";
import Preview from "./Preview";

const AddActivity = ({ trip }) => {
  const [ActivityfillDetails, setActivityfillDetails] = useState("Details");

  const renderElementForRentalCar = (ActivityfillDetails) => {
    if (ActivityfillDetails === "Details")
      return <AddActivityDetails trip={trip} />;
    if (ActivityfillDetails === "ActivityDescription")
      return <ActivityDescription trip={trip} />;
    if (ActivityfillDetails === "Location")
      return <ActivityMapMarker trip={trip} />;
    if (ActivityfillDetails === "Preview") return <Preview trip={trip} />;
  };

  return (
    <>
      <button
        className={
          ActivityfillDetails === "Details"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setActivityfillDetails("Details")}
      >
        Details
      </button>
      <button
        className={
          ActivityfillDetails === "ActivityDescription"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setActivityfillDetails("ActivityDescription")}
      >
        Description
      </button>
      {/* <button
        className={
          ActivityfillDetails === "Photo"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setActivityfillDetails("Photo")}
      >
        Photo
      </button> */}
      <button
        className={
          ActivityfillDetails === "Location"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setActivityfillDetails("Location")}
      >
        Map Marker Location
      </button>
      <button
        className={
          ActivityfillDetails === "Preview"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setActivityfillDetails("Preview")}
      >
        Preview
      </button>
      <br />
      <br />
      {renderElementForRentalCar(ActivityfillDetails)}
    </>
  );
};

export default AddActivity;
