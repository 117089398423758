import React, { useCallback, useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import { createFlight } from "../controllers/flights";
import { updateTrip, useIndexedState, Field, DateField } from "../utils";
import { stringFromDate } from "../../../utils/date-utils";

const FlightsDetails = ({
  match: {
    params: { id, archiveID },
  },
  trip,
}) => {
  const [isSave, setIsSave] = useState(false);
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);
  const [flights, setFlights] = useState(trip.flights);
  const { modifyAt, push, removeAt } = useIndexedState(flights, setFlights);

  const setDate = modifyAt((flight, date) => ({
    ...flight,
    details: {
      ...flight.details,
      selectedDate: stringFromDate(date),
    },
    airports: {
      ...flight.airports,
      arrivalDate: stringFromDate(date),
      departureDate: stringFromDate(date),
    },
  }));

  const setAirlineCode = modifyAt((flight, e) => ({
    ...flight,
    details: {
      ...flight.details,
      airlineCode: e.target.value,
    },
  }));

  const setFlightNumber = modifyAt((flight, e) => ({
    ...flight,
    details: {
      ...flight.details,
      flightNumber: e.target.value,
    },
  }));

  const setAirlineName = modifyAt((flight, e) => ({
    ...flight,
    details: {
      ...flight.details,
      airLineName: e.target.value,
    },
  }));

  const onSubmit = useCallback(
    (e) => {
      trip.flights = flights;
      updateTrip(trip.id, archiveID, {
        flights,
        updatedAt: new Date(),
      }).then(() => setIsSave(true));
    },
    [setIsSave, flights, trip.id]
  );

  const addFlight = push(() => createFlight());

  const renderFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {renderFlashMessage()}
      {flights &&
        flights.map((data, index) => (
          <div key={index}>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <h5>Flight {index + 1}</h5>
              </div>
              <div className="col-md-6 text-right">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={removeAt(index)}
                >
                  Remove
                </Button>
              </div>
            </div>
            <hr />
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <DateField
                    label="Start Date"
                    value={
                      data.details.selectedDate
                        ? data.details.selectedDate
                        : minDate
                    }
                    onChange={setDate(index)}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </Form.Group>
                <Field
                  label="Airline Code"
                  placeholder="Code"
                  value={data.details.airlineCode}
                  onChange={setAirlineCode(index)}
                />
                <Field
                  label="Flight Number"
                  value={data.details.flightNumber}
                  onChange={setFlightNumber(index)}
                />
                <Field
                  label="Airline name"
                  placeholder="Airline"
                  value={data.details.airLineName}
                  onChange={setAirlineName(index)}
                />
              </Form.Row>
            </Form>
          </div>
        ))}
      <button className="marginButtonffor" onClick={addFlight}>
        Add More
      </button>
      <br />
      <br />
      <button onClick={onSubmit} className="marginButtonBig stickyButton">
        Save Details.
      </button>
    </>
  );
};

export default withRouter(FlightsDetails);
