import React from "react";
import "../tripshow.css";

import { TripImage } from "../../utils";

// TODO: clean this up
const Activities = ({ dataPreload }) => {
  return (
    <>
      {dataPreload ? (
        <div className="container">
          <>
            {dataPreload.activityDetails.activityPlace !== "" ? (
              <>
                {" "}
                <div style={{ padding: 10 }} />
                <br />
                <div>
                  <div className="titlebar">
                    {dataPreload.activityDetails.activityPlace
                      ? dataPreload.activityDetails.activityPlace
                      : " "}
                  </div>
                </div>
                <div className="card">
                  <div className="scrollablecard">
                    <div>
                      <div className="row">
                        <div className="col-md-5">
                          <p className="activity">
                            {dataPreload.activityDetails.startDate} &nbsp;
                            {dataPreload.activityDetails.startTime}
                            <br />
                            <br />
                            <i
                              class="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>{" "}
                            {dataPreload.activityDetails.activityAddress}
                          </p>
                          <br />
                          <p>
                            {dataPreload.activitiesDescription.Description ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    dataPreload.activitiesDescription
                                      .Description,
                                }}
                              />
                            ) : (
                              <span style={{ float: "right" }}>No</span>
                            )}{" "}
                          </p>
                        </div>
                        {dataPreload.url !== "" ? (
                          <div className="col-md-7">
                            <TripImage
                              src={dataPreload.url}
                              defaultURL={null}
                              alt="Activity"
                              width="400"
                              height="280"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Activities;
