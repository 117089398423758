import React, { useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import firebase from "../../firebase-auth/Config";
import "date-fns";
import { withRouter } from "react-router-dom";
import { updateTrip } from "../utils";

const ResturantMarkerMapLocation = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [latitude, setLatitude] = useState("");
  const [longtitute, setLongtitude] = useState("");
  const [mapMarker, setMapMarker] = useState([]);
  const [isSave, setIsSave] = useState(false);

  useEffect(() => {
    if (!trip.resturants) return;

    const baseCall =
      Object.prototype.toString.call(trip.resturants) === "[object Object]"
        ? [trip.resturants]
        : trip.resturants;
    setMapMarker(baseCall);
  }, []);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      resturants: mapMarker,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const addResturantMap = (e, index) => {
    setMapMarker([
      ...index,
      {
        mapMarkerLocation: {
          latitude: "",
          longtitute: "",
        },
        resturantImageURL: "",
        resturantsDescription: {
          description: "",
        },
        resturantsDetails: {
          resturantAddress: "",
          resturantName: "",
          selectedDate: new Date().toDateString(),
          selectedTime: new Date(),
        },
      },
    ]);
  };
  const removeResturantMap = (e, index) => {
    e.preventDefault();

    console.log(mapMarker);
    mapMarker.pop(index);
    setMapMarker(mapMarker);
    console.log(mapMarker);
  };
  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };
  return (
    <>
      {rendorFlashMessage()}
      {mapMarker.map((details, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>Restaurant {index + 1}</h5>
            </div>
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  placeholder="Latitude"
                  name="latitude"
                  value={details.mapMarkerLocation.latitude}
                  onChange={(e) =>
                    setLatitude(
                      (mapMarker[index].mapMarkerLocation.latitude =
                        e.target.value)
                    )
                  }
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  placeholder="Longitude"
                  name="longtitute"
                  value={details.mapMarkerLocation.longtitute}
                  onChange={(e) =>
                    setLongtitude(
                      (mapMarker[index].mapMarkerLocation.longtitute =
                        e.target.value)
                    )
                  }
                />
              </Form.Group>
            </Form.Row>
          </Form>
          {/* <button onClick={(e) => removeResturantMap(e, index)}>
            Remove
          </button> */}
        </div>
      ))}
      {/* <button onClick={(e) => addResturantMap(e, mapMarker)}>
        Add Details
      </button> */}
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save
      </button>
    </>
  );
};

export default withRouter(ResturantMarkerMapLocation);
