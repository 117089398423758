import { SET_PROPOSALS, SET_PROPOSAL_ID } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PROPOSALS:
      return {
        ...state,
        all_proposals: action.payload
      };
      case SET_PROPOSAL_ID:
        return {
          ...state,
          proposal_Id: action.payload
        };
    default:
      return state;
  }
}
