import React, { Component } from "react";
import { Row, Form, Col, Alert } from "react-bootstrap";
import "./css/TripDetails.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { withRouter } from "react-router-dom";
import firebase from "../firebase-auth/Config";
import { ckeditor_config } from "../../Constants/urls";
import { updateTrip } from "./utils";
import { stringFromDate } from "../../utils/date-utils";

class TripDetails extends Component {
  constructor(props) {
    super(props);
    this.user = firebase.auth();

    const { trip } = this.props;

    console.log("TRIP: ", trip);

    this.state = {
      id: trip.id,
      title: trip.title,
      subtitle: trip.subtitle,
      startDate: trip.startDate,
      endDate: trip.endDate,
      description: trip.tripDetails.description,
      includes: trip.tripDetails.includes,
      price: trip.tripDetails.price,
      max_price: trip.tripDetails.max_price,
      payment_link: trip.tripDetails.payment_link,
      payment_terms: trip.tripDetails.payment_terms,
      travel_link: trip.tripDetails.travel_link,
      price_type: trip.tripDetails.price_type,
      user_id: trip.user_id,
    };
  }

  onSubmit = (e) => {
    console.log("SUBMITTED");
    // this.state.overviewImage = "";
    const { isSave } = this.state;
    e.preventDefault();

    const data = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      title: this.state.title,
      subtitle: this.state.subtitle,
      tripDetails: this.state,
      updatedAt: new Date(),
    };

    console.log("NEW DATA: ", data);

    this.props.updateTripState({ ...this.props.trip, ...data });

    updateTrip(
      this.props.match.params.id,
      this.props.match.params.archiveID,
      data
    )
      .catch((error) => console.error("Error updating trip: ", error))
      .then(() => this.setState({ isSave: !isSave }));
  };

  onChangeCurrency = (e) => {
    const state = this.state;

    this.setState({ price_type: e.target.value });
  };

  rendorFlashMessage = (e) => {
    const { isSave } = this.state;
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={() => this.setState({ isSave: !isSave })}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    if (e.target.name === "price" && e.target.value.charAt(0) != "$") {
      state[e.target.name] = e.target.value;
    }
    this.setState(state);
  };

  handleDescription = (e, editor) => {
    this.setState({ description: editor.getData() });
  };

  handlePaymentTerms = (e, editor) => {
    this.setState({ payment_terms: editor.getData() });
  };

  handleIncludes = (e, editor) => {
    this.setState({ includes: editor.getData() });
  };

  handleChange = (date) => {
    this.setState({
      startDate: stringFromDate(date),
    });
  };

  handleChange1 = (date) => {
    this.setState({
      endDate: stringFromDate(date),
    });
  };

  handleCurrencyChange = (e) => {
    this.setState({
      price_type: this.state.price_type,
    });
  };

  render() {
    console.log(this.state.endDate, this.state.startDate);

    return (
      <>
        <br />
        {this.rendorFlashMessage()}
        <br />
        <div className="bg-light">
          <div className="container">
            <Row>
              <h2 className="title_h2">
                {this.state.title}
                {this.state.subtitle && <p>{this.state.subtitle}</p>}
              </h2>
            </Row>
            <div className="row">
              <div className="col-md-8">
                <Form onSubmit={this.onSubmit}>
                  <div className="container">
                    <div className="panel panel-default">
                      <div className="panel-body">
                        <div className="form-group">
                          <label for="title">Title:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            maxLength={50}
                            value={this.state.title}
                            onChange={this.onChange}
                            placeholder="Title"
                          />
                        </div>
                        <div className="form-group">
                          <label for="title">Subtitle:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="subtitle"
                            maxLength={50}
                            value={this.state.subtitle}
                            onChange={this.onChange}
                            placeholder="Subtitle"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="Start Date"
                          format="MM/dd/yyyy"
                          value={this.state.startDate}
                          minDate={this.state.startDate}
                          maxDate={this.state.endDate}
                          // selected={this.state.startDate}
                          onChange={this.handleChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="End Date"
                          format="MM/dd/yyyy"
                          value={this.state.endDate}
                          minDate={this.state.startDate}
                          // maxDate={this.state.endDate}
                          selected={this.state.endDate}
                          onChange={this.handleChange1}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                  </Form.Row>

                  <Form.Group style={{ width: "86%" }}>
                    <Form.Label>
                      <b>Description</b>
                    </Form.Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.description}
                      value={this.state.description}
                      config={ckeditor_config}
                      onChange={this.handleDescription}
                    />
                  </Form.Group>
                  <Form.Group style={{ width: "86%" }}>
                    <Form.Label>
                      <b>Includes</b>
                    </Form.Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.includes}
                      value={this.state.includes}
                      config={ckeditor_config}
                      onChange={this.handleIncludes}
                    />
                  </Form.Group>
                  {/* <h3>Pricing</h3> */}
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label className="currency">
                        <b>Currency</b>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue="＄"
                        value={this.state.price_type}
                        name="price_type"
                        onChange={this.onChangeCurrency}
                      >
                        <option value="＄">Canadian Dollar</option>
                        <option value="$">United States dollar</option>
                        {/* <option value="L">Albanian lek</option>
                        <option value="€">European euro</option>
                        <option value="EC$">East Caribbean dollar</option>
                        <option value="AU$">Australian dollar</option>
                        <option value="CFA">West African CFA franc</option>
                        <option value="R$">Brazilian real</option>
                        <option value="Kr.">Danish krone</option>
                        <option value="F$">Fijian dollar</option>
                        <option value="HK$">Hong Kong dollar</option>
                        <option value="₹">Indian rupee</option>
                        <option value="¥">Japanese yen</option>
                        <option value="ksh">Kenyan shilling</option>
                        <option value="NZ$">New Zealand dollar</option> */}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="price">
                        <b>Price</b>
                      </Form.Label>
                      <Form.Control
                        placeholder="Price"
                        name="price"
                        value={this.state.price}
                        onChange={this.onChange}
                      />
                    </Form.Group>
                    {/* <Form.Group as={Col}>
                      <Form.Label className="max-price">
                        <b>Max-Price</b>
                      </Form.Label>
                      <Form.Control
                        placeholder="Max-Price"
                        name="max_price"
                        value={this.state.max_price}
                        onChange={this.onChange}
                      />
                    </Form.Group> */}
                  </Form.Row>
                  <Form.Group>
                    <Form.Group style={{ width: "86%" }}>
                      <Form.Label>
                        <b>Payment Terms</b>
                      </Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={this.state.payment_terms}
                        value={this.state.payment_terms}
                        config={ckeditor_config}
                        onChange={this.handlePaymentTerms}
                      />
                    </Form.Group>
                    {/* <Form.Group>
                      <Form.Label>
                        <b>Payment Link</b>
                      </Form.Label>
                      <Form.Control
                        name='payment_link'
                        value={this.state.payment_link}
                        onChange={this.onChange}
                        as='input'
                        className='paymentterms'
                        placeholder='https://www.test.com'
                      />
                    </Form.Group> */}
                    {/* <Form.Group>
                      <Form.Label>
                        <b>Travel Link</b>
                      </Form.Label>
                      <Form.Control
                        as='input'
                        name='travel_link'
                        value={this.state.travel_link}
                        onChange={this.onChange}
                        className='paymentterms'
                        placeholder='https://www.testtravel.com/client/paris/jhon'
                      />
                    </Form.Group> */}
                  </Form.Group>
                  <button className="activemarginButton stickyButton">
                    Save
                  </button>
                </Form>
                <br />
              </div>
              <div className="col-md-4">
                <h5>Details Overview</h5>
                <p className="good">
                  A good title does not include information about dates or
                  hotels. This information is displayed in other places and will
                  appear redundant in the finished product.
                </p>
                <p className="bad">
                  Inordinately long titles will be truncated in order to preseve
                  the layout. 60 charaters is the limit on what will be
                  displayed. Writing in all-caps will make your title look
                  ridiculous.
                </p>
                <h5>Description Overview</h5>
                <p className="desc_over">
                  In this section, write a few sentences about the itinerary.
                  This will be the first paragraph your customers read so be
                  sure to get them excited about their trip with vivid imagery
                  and fun descriptions
                </p>
                <p className="yellow">
                  While this section is not mandatory, your trip will look
                  incomplete without it.
                </p>
                <p className="orange">
                  Writing in all-caps is considered extremely un-professional in
                  any context.
                </p>
                <h5>About the What's Included Section</h5>
                <p className="include_section">
                  Below, be sure to mention the items that are included in the
                  trip's cost. If you do not wish to show the included items or
                  the price, just leave those sections blank and they will not
                  appear.
                </p>
                <p className="include_section1">
                  This field is completely optional. Leave it blank If you do
                  not feel it is needed.
                </p>
                <p className="include_section2">
                  Writing in all-caps is considered extremely un-professional in
                  any context.
                </p>
                <h5>About Price</h5>
                <p className="about_price">
                  For payment instructions, feel free to add as much information
                  as you need. You can add a price ranges if you would like to
                  propose several price points, and you can also specify how the
                  price is broken down. If you wish to remove pricing
                  information or just delete all information in the pricing
                  fields.
                </p>
                <span style={{ color: "green", fontSize: "small" }}>
                  The price and payment fields are completely optional. Leave
                  them blank If you do not feel it is needed.
                </span>
                <h5>About the Payment Link</h5>
                <p
                  style={{
                    fontSize: "small",
                    color: "#000",
                    marginBottom: "0px",
                  }}
                >
                  Paste a URL in that will take your client to an online invoice
                  or payment gateway.
                </p>
                <p
                  style={{
                    fontSize: "small",
                    color: "green",
                    marginBottom: "0px",
                  }}
                >
                  This field is completely optional. Leave it blank If you do
                  not feel they are needed.
                </p>
                <h5>About the Travel 42 Link</h5>
                <p
                  style={{
                    fontSize: "small",
                    color: "#000",
                    marginBottom: "0px",
                  }}
                >
                  If you have made a Travel42 destination guide, paste that link
                  here so it will display in a special section of the
                  itinerary.If you have have a link to another destination guide
                  you may also place that here.
                </p>
                <p
                  style={{
                    fontSize: "small",
                    color: "green",
                    marginBottom: "0px",
                  }}
                >
                  This field is completely optional. Leave it blank If you do
                  not feel they are needed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(TripDetails);
