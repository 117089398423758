import { stringFromDate, timeFromDate } from "../../../utils/date-utils";

export const createRestaurant = () => ({
  mapMarkerLocation: {
    latitude: "",
    longtitute: "",
  },
  resturantImageURL: "",
  resturantsDescription: {
    description: "",
  },
  resturantsDetails: {
    resturantAddress: "",
    resturantName: "",
    selectedDate: stringFromDate(new Date()),
    selectedTime: timeFromDate(new Date()),
  },
});
