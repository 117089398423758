import React, { useState, useEffect } from "react";
import "../../App.css";
import { Form, Alert } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import firebase from "../firebase-auth/Config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditor_config } from "../../Constants/urls";

const Info_body = (props) => {
  const [isSave, setIsSave] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [attachements, setAttachements] = useState([]);
  const storageRef = firebase.storage().ref();

  useEffect(() => {
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(props.match.params.id);
    cityRef.get().then((doc) => {
      if (doc.exists) {
        const allData = doc.data();
        console.log(allData);

        const baseColl =
          Object.prototype.toString.call(allData.attachements) ===
          "[object Object]"
            ? [allData.attachements]
            : allData.attachements;

        console.log("CALL");

        baseColl.forEach((attachment) => {
          if (Array.isArray(attachment?.file)) {
            return;
          }

          const newFiles = [];

          if (attachment.fileName) {
            newFiles.push({
              file: attachment.file ?? "",
              fileName: attachment.fileName ?? "",
            });
          }

          attachment.file = newFiles;
        });

        setAttachements(baseColl);
      }
    });
  }, []);

  const uploadPdf = (file, index) => {
    setIsLoading(true);
    console.log(index, attachements);
    const temp = file[0];
    console.log("TEMP: ", temp);
    if (temp.type !== "application/pdf") {
      alert("Only PDF files are allowed");
      return;
    } else {
      const fileRef = storageRef.child(`/files/${temp.name}`);
      fileRef
        .put(temp)
        .then((res) => {
          fileRef.getDownloadURL().then((url) => {
            console.log(url);
            attachements[index].file.push({
              file: url,
              fileName: temp.name,
            });

            setIsLoading(false);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const onSubmit = (e) => {
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(props.match.params.id);

    cityRef.set(
      {
        attachements: attachements,
        updatedAt: new Date(),
      },
      { merge: true }
    );
    setIsSave(true);
  };

  const addAttachements = (e, index) => {
    setAttachements([
      ...index,
      {
        title: "",
        content: "",
        file: [],
      },
    ]);
  };

  const removeAttachements = (e, index) => {
    setIsSave(false);
    attachements.splice(index, 1);
    setAttachements(attachements);
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(props.match.params.id);

    cityRef
      .set(
        {
          attachements: attachements,
        },
        { merge: true }
      )
      .then((ret) => {
        setIsSave(true);
      });
  };

  const swapAttachments = (firstIndex, secondIndex) => {
    setAttachements((data) => {
      let tempData = [...data];

      let tempItem = tempData[firstIndex];
      tempData[firstIndex] = tempData[secondIndex];
      tempData[secondIndex] = tempItem;

      return tempData;
    });
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {rendorFlashMessage()}
      <div className="info_body_part">
        <div className="info_body_head">
          <div className="info_body_btn"></div>
          <div className="text"></div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="info_body_body">
              {attachements.map((data, index) => (
                <div key={index}>
                  <hr />
                  <div className="row">
                    <div className="col-md-6">
                      <h5> {index + 1}</h5>
                    </div>
                    <div className="col-md-6 text-right">
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowUpward />}
                        disabled={index == 0}
                        onClick={() => swapAttachments(index, index - 1)}
                        style={{ margin: "0px 5px" }}
                      >
                        MOVE UP
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowDownward />}
                        disabled={index == attachements.length - 1}
                        onClick={() => swapAttachments(index, index + 1)}
                        style={{ margin: "0px 5px" }}
                      >
                        MOVE DOWN
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={(e) => removeAttachements(e, index)}
                        style={{ margin: "0px 5px" }}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                  <hr />
                  <Form>
                    <Form.Group style={{ width: "60%" }}>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Title"
                        name="title"
                        value={attachements[index].title}
                        onChange={(e) => {
                          const attachementsCopy = [...attachements];
                          const attachementCopy = {
                            ...attachementsCopy[index],
                            title: e.target.value,
                          };

                          attachementsCopy[index] = attachementCopy;
                          setAttachements(attachementsCopy);
                        }}
                      />
                    </Form.Group>
                    <Form.Group style={{ width: "97%" }}>
                      <Form.Label>Content</Form.Label>
                      <CKEditor
                        key={data.title + index}
                        id={data.title + index}
                        editor={ClassicEditor}
                        onChange={(e, editor) => {
                          console.log(e);
                          console.log(editor);
                          attachements[index].content = editor.getData();
                        }}
                        name="content"
                        data={data.content}
                        value={data.content}
                        config={ckeditor_config}
                      />
                      {/* <textarea
                        id="w3mission"
                        rows="5"
                        cols="50"
                        placeholder="Write here"
                        value={data.content}
                        onChange={(e) =>
                          setContent(
                            (attachements[index].content = e.target.value)
                          )
                        }
                        className="form-control"
                      /> */}
                      {/* <Form.Control
                  type="textarea"
                  rows="13"
                  cols="20"
                  placeholder="Search Trip With Title"
                  name="content"
                  value={content}
                  onChange={e => setContent(e.target.value)}
                /> */}
                    </Form.Group>
                    <Form.Group>
                      <input
                        type="file"
                        className="mb-4"
                        accept="application/pdf"
                        onChange={(e) => uploadPdf(e.target.files, index)}
                      />
                      <br />

                      <span>Uploaded Files:</span>

                      {data.file?.length ? (
                        <ul>
                          {data.file.map((file, fileIndex) => (
                            <li key={fileIndex}>
                              <a href={file.file} target="_blank">
                                {file.fileName}
                              </a>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span> None</span>
                      )}
                    </Form.Group>
                  </Form>
                </div>
              ))}
              <button
                className=""
                onClick={(e) => addAttachements(e, attachements)}
              >
                Add More
              </button>
              <br />
              <br />
              <button
                disabled={isLoading}
                variant="contained"
                color="primary"
                onClick={onSubmit}
                className="marginButtonBig stickyButton"
              >
                Save
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="info_body_body">
              <h10>
                <b>About Attachments</b>
              </h10>
              <p
                style={{
                  fontFamily: "'Open Sans' sans-serif",
                  fontSize: "13px",
                }}
              >
                In this section, feel free to add any documents or vouchers that
                your customers will need. Additionally, you can add any
                information you deem relevant. Climate, your terms and
                conditions, travel insurance information, etc.
              </p>
              <p
                className="text-danger"
                style={{
                  fontFamily: "'Open Sans' sans-serif",
                  fontSize: "13px",
                }}
              >
                Hovever, do not add information about Flights or Hotels in this
                section. That information is included in the itinerary section
                and will be redundant and hard to find if placed in this section
                rather than in the itinerary where it is designed to go. in this
                section,feel free etc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Info_body);
