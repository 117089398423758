const routes = {
  root: '/',
  home: '/home',
  trip: '/Trips',
  newtrip: '/NewTrip',
  trip_builder: '/trip_builder',
  dashboard: '/Dashboard',
  tripshow: '/TripShow',
  createtrip: '/CreateTrip',
  edittrip: '/EditTrip',
  proposal_builder: '/proposal_builder',
  proposals: '/proposals',
  CopyTrip: '/CopyTrip',
  signup: '/signup',
  pdf: '/pdf',
  company_info: '/company',
};

export default routes;
