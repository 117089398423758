import React, { useState, useCallback } from "react";
import firebase from "../firebase-auth/Config";
import { Link } from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import withFirebaseAuth from "react-auth-firebase";
import "date-fns";

import { createTrip } from "./controllers/trips";
import { DateField } from "./utils";
import { dateFromString, stringFromDate } from "../../utils/date-utils";

const CreateTrip = ({ history }) => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [startDate, setStartDate] = useState(new Date().toDateString());
  const [endDate, setEndDate] = useState(new Date().toDateString());

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const ref = firebase.firestore().collection("Tripss");
      console.log(ref);
      const refUser = firebase.auth();
      const user_id = refUser.currentUser.uid;
      const userName = refUser.currentUser.displayName;
      const trip = createTrip(
        { user_id, userName, title, subtitle, startDate, endDate },
        new Date()
      );

      ref
        .add(trip)
        .then((docRef) => {
          history.push("/trip_builder/" + docRef.id);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });

      return false;
    },
    [history, title, subtitle, startDate, endDate]
  );

  const onTitleChange = useCallback(
    (e) => setTitle(e.target.value),
    [setTitle]
  );

  const onSubtitleChange = useCallback(
    (e) => setSubtitle(e.target.value),
    [setSubtitle]
  );

  const onStartDateChange = useCallback(
    (date) => {
      setStartDate(stringFromDate(date));

      if (date > dateFromString(endDate)) {
        setEndDate(stringFromDate(date));
      }
    },
    [setStartDate, setEndDate]
  );

  const onEndDateChange = useCallback(
    (date) => {
      setEndDate(stringFromDate(date));
    },
    [setEndDate]
  );

  return (
    <div className="container">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">ADD TRIP</h3>
        </div>
        <div className="panel-body">
          <h4>
            <Link to="/Trips" className="btn btn-primary">
              Trips
            </Link>
          </h4>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                className="form-control"
                name="title"
                required
                maxLength={50}
                value={title}
                onChange={onTitleChange}
                placeholder="Title"
              />
            </div>
            <div className="form-group">
              <label htmlFor="title">Subtitle:</label>
              <input
                type="text"
                className="form-control"
                name="subtitle"
                required
                maxLength={50}
                value={subtitle}
                onChange={onSubtitleChange}
                placeholder="Subtitle"
              />
            </div>
            <Form.Row>
              <Form.Group as={Col}>
                <DateField
                  label="Start Date"
                  required
                  value={startDate}
                  onChange={onStartDateChange}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <DateField
                  label="End Date"
                  required
                  value={endDate}
                  defaultValue={startDate}
                  minDate={startDate}
                  onChange={onEndDateChange}
                />
              </Form.Group>
            </Form.Row>
            <button type="submit" className="btn btn-success">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const authConfig = {
  email: {
    verifyOnSignup: false, // Sends verification email to user upon sign up
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
  google: {
    returnUser: true,
    returnAccessToken: true, // Returns an access token as googleAccessToken prop
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
};

export default withFirebaseAuth(CreateTrip, firebase, authConfig);
