import React, { useEffect, useMemo, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Form, Col } from "react-bootstrap";
import firebase from "../firebase-auth/Config";
import { storage } from "../firebase-auth/Config";

export async function imageUpload(basePath, image) {
  try {
    const snapshot = await storage.ref(basePath).child(image.name).put(image);
    return snapshot.ref.fullPath;
  } catch (error) {
    console.error(error);
    alert("An error occurred while uploading this image. Please try again.");
  }
}

export function useIndexedState(xs, set) {
  const state = useMemo(() => {
    const withModifyAt =
      (f) =>
      (index) =>
      (...args) => {
        f(
          xs[index],
          (x) => set([...xs.slice(0, index), x, ...xs.slice(index + 1)]),
          ...args
        );
      };

    const modifyAt = (f) =>
      withModifyAt((x, modify, ...args) => {
        modify(f(x, ...args));
      });

    const removeAt = (index) => () => set(xs.filter((_x, i) => i !== index));

    const push = (f) => () => set([...xs, f()]);

    return { withModifyAt, modifyAt, removeAt, push };
  }, [xs, set]);

  return state;
}

function getTripRef(id, collection = "Tripss") {
  console.log(collection);
  return firebase.firestore().collection(collection).doc(id);
}

export async function getTrip(id, collection) {
  try {
    const doc = await getTripRef(id, collection).get();
    console.log(doc);
    if (!doc.exists) throw new Error("Document does not exist.");

    return doc.data();
  } catch (error) {
    console.error(error);
    alert(
      "An error occurred while fetching the trip details. Please try again."
    );
  }
}

export async function updateTrip(id, collection, data) {
  if (parseInt(collection)) {
    const collectionID = parseInt(collection);
    collection = getCollectionNameFromId(collectionID);
  }

  try {
    await getTripRef(id, collection).set(data, { merge: true });
  } catch (error) {
    console.error(error);
    alert("An error occurred while saving the trip details. Please try again.");
  }
}

export const FormImage = (props) => (
  <Form.Control type="file" accept="image/*" {...props} />
);

export const Field = ({ label, value, onChange, placeholder, ...rest }) => (
  <Form.Group as={Col}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      {...rest}
      placeholder={placeholder ?? label}
      value={value}
      onChange={onChange}
    />
  </Form.Group>
);

export const CheckedField = ({ label, value, onChange, style }) => (
  <Form.Group as={"div"} style={style}>
    <FormControlLabel
      control={<Checkbox checked={value} onChange={onChange} color="" />}
      label={label}
    />
  </Form.Group>
);

export const DateTimeField = ({
  dateLabel,
  date,
  setDate,
  dateAriaLabel = "Change date",
  timeLabel,
  time,
  setTime,
  timeAriaLabel = "Change time",
  minDate,
  maxDate,
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      margin="normal"
      id="date-picker-dialog"
      label={dateLabel}
      format="MM/dd/yyyy"
      value={date}
      onChange={setDate}
      minDate={minDate}
      maxDate={maxDate}
      KeyboardButtonProps={{ "aria-label": dateAriaLabel }}
    />
    <KeyboardTimePicker
      margin="normal"
      id="time-picker"
      label={timeLabel}
      value={time}
      onChange={setTime}
      KeyboardButtonProps={{ "aria-label": timeAriaLabel }}
    />
  </MuiPickersUtilsProvider>
);

export const DateField = ({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  ariaLabel = "Change date",
  ...props
}) => {
  console.log("DATE PICKER:", value, minDate, maxDate);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        margin="normal"
        id="date-picker-dialog"
        label={label}
        format="MM/dd/yyyy"
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        KeyboardButtonProps={{ "aria-label": ariaLabel }}
      />
    </MuiPickersUtilsProvider>
  );
};

const PLACEHOLDER_URL = "https://via.placeholder.com/150x160";

export const getStorageURL = (refs, defaultURL = PLACEHOLDER_URL) => {
  const ref = refs.filter(Boolean)[0] ?? defaultURL;

  if (!ref) return Promise.resolve(null);

  if (ref.startsWith("http")) return Promise.resolve(ref);

  return storage.ref(ref).getDownloadURL();
};

const useStorageURL = (refs, defaultURL = PLACEHOLDER_URL) => {
  const [url, setURL] = useState(defaultURL);

  useEffect(() => {
    getStorageURL(refs, defaultURL)
      .then((url) => setURL(url))
      .catch((error) => console.error(error));
  }, [defaultURL, refs, setURL]);

  return url;
};

export const TripImage = ({
  src,
  alt,
  defaultURL = PLACEHOLDER_URL,
  ...props
}) => {
  const url = useStorageURL([src], defaultURL);

  if (!url) return <></>;

  return <img {...props} src={url} alt={alt} />;
};

export const sortStringsDesc = (stringA, stringB) => {
  if (stringA > stringB) return -1;
  else if (stringB > stringA) return 1;
  else return 0;
};

export const sortStringsAsc = (stringA, stringB) => {
  if (stringA > stringB) return 1;
  else if (stringB > stringA) return -1;
  else return 0;
};

export const getCollectionNameFromId = (id) => {
  if (!id) return "Tripss";
  else if (id == 1) return "Archived Trips - Went";
  else if (id == 2) return "Archived Trips - Didn't Go";
};
