import React, { useState } from "react";
import "date-fns";
import { withRouter } from "react-router-dom";
import AddTrainsDetails from "./AddTrainDetails";
import TrainDescription from "./TrainDescription";
import TrainMapMarker from "./TrainMapMarker";
import Preview from "./Preview";

const AddTrain = ({ trip }) => {
  const [TrainDetailsFill, setTrainDetailsFill] = useState("TrainDetails");

  const renderElementForTrain = (TrainDetailsFill) => {
    if (TrainDetailsFill === "TrainDetails")
      return <AddTrainsDetails trip={trip} />;
    if (TrainDetailsFill === "Description")
      return <TrainDescription trip={trip} />;
    if (TrainDetailsFill === "TrainLocation")
      return <TrainMapMarker trip={trip} />;
    if (TrainDetailsFill === "PreviewTrain") return <Preview trip={trip} />;
  };

  return (
    <>
      <button
        className={
          TrainDetailsFill === "TrainDetails"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTrainDetailsFill("TrainDetails")}
      >
        Details
      </button>
      <button
        className={
          TrainDetailsFill === "Description"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTrainDetailsFill("Description")}
      >
        Description
      </button>
      {/* <button
        className={
          TrainDetailsFill === "TrainPhoto"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTrainDetailsFill("TrainPhoto")}
      >
        Photo
      </button> */}
      <button
        className={
          TrainDetailsFill === "TrainLocation"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTrainDetailsFill("TrainLocation")}
      >
        Map Marker Location
      </button>
      <button
        className={
          TrainDetailsFill === "PreviewTrain"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setTrainDetailsFill("PreviewTrain")}
      >
        Preview
      </button>
      <br />
      <br />
      {renderElementForTrain(TrainDetailsFill)}
    </>
  );
};

export default withRouter(AddTrain);
