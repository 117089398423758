import React, { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditor_config } from "../../../Constants/urls";
import { updateTrip } from "../utils";

const RentalCarDescription = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [description, setDescription] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [allCar, setAllCar] = useState([]);
  const handleDescription = (e, index, editor) => {
    setDescription(
      (allCar[index].carRentalsDescription.description = editor.getData())
    );
  };

  useEffect(() => {
    if (!trip?.carRentals) return;

    const baseCall =
      Object.prototype.toString.call(trip.carRentals) === "[object Object]"
        ? [trip.carRentals]
        : trip.carRentals;
    setAllCar(baseCall);
  }, [trip.id]);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      carRentals: allCar,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const addCarDetails = (e, index) => {
    setAllCar([
      ...index,
      {
        carRentalsDescription: {
          description: "",
        },
        carRentalsDetails: {
          companyName: "",
          companyPhone: "",
          dropLocation: "",
          endDate: new Date(),
          endTime: new Date(),
          pickUpLocation: "",
          startDate: new Date(),
          startTime: new Date(),
        },
        mapMarkerLocation: {
          latitude: "",
          longtitute: "",
        },
        rentalCarURL: "",
      },
    ]);
  };
  const removeCarDetails = (e, index) => {
    e.preventDefault();

    console.log(allCar);
    allCar.pop(index);
    setAllCar(allCar);
    console.log(allCar);
  };
  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };
  console.log(description, "I am Description");
  return (
    <>
      {rendorFlashMessage()}
      {allCar.map((data, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <h5>Rental Car {index + 1}</h5>
            </div>
            {/* <div className="col-md-6 text-right">
            </div> */}
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group>
                <Form.Label>
                  <b>Description</b>
                </Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  config={ckeditor_config}
                  onChange={(e, editor) => handleDescription(e, index, editor)}
                  data={data.carRentalsDescription.description}
                  value={data.carRentalsDescription.description}
                  name="description"
                />
              </Form.Group>
            </Form.Row>
          </Form>
          {/* <button onClick={(e) => removeCarDetails(e, index)}>
            Remove
          </button> */}
        </div>
      ))}
      {/* <button onClick={(e) => addCarDetails(e, allCar)}>
        Add Details
      </button> */}
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Car Rental Description
      </button>
    </>
  );
};

export default withRouter(RentalCarDescription);
