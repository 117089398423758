import React from "react";
import "../tripshow.css";

// TODO: clean this up
const Trains = ({ trains, dataPreload }) => {
  return (
    <>
      {dataPreload ? (
        <div className="container">
          {trains.trainDetails && trains.trainDetails.companyName !== "" ? (
            <>
              <div style={{ padding: 10 }} />
              <br />
              <div>
                <div className="titlebar">
                  {dataPreload.trainDetails.companyName
                    ? dataPreload.trainDetails.companyName
                    : " "}
                </div>
              </div>
              <div className="card">
                <div className="scrollablecard">
                  <div>
                    <div className="row">
                      <div className="col-md-5">
                        <p>
                          <i
                            class="fa fa-calendar"
                            aria-hidden="true"
                            style={{ marginRight: "8px" }}
                          ></i>
                          Departure Date:
                          {dataPreload.trainDetails.departureDate}
                        </p>
                        <p>
                          <i
                            class="fa fa-calendar"
                            aria-hidden="true"
                            style={{ marginRight: "8px" }}
                          ></i>
                          Arrival Date:
                          {dataPreload.trainDetails.arrivalDate}
                        </p>
                        <br />
                        <p>Class: {dataPreload.trainsComment.trainClass}</p>
                        <p>
                          <i
                            class="fa fa-commenting"
                            aria-hidden="true"
                            style={{ marginRight: "8px" }}
                          ></i>
                          {
                            <p
                              dangerouslySetInnerHTML={{
                                __html: dataPreload.trainsComment.trainComment,
                              }}
                            />
                          }
                        </p>
                        <h6>
                          <b>Details</b>
                        </h6>
                        <p>
                          Destination Station:
                          {dataPreload.trainDetails.destinationStation}
                        </p>
                        <p>
                          <i
                            class="fa fa-train"
                            aria-hidden="true"
                            style={{ marginRight: "8px" }}
                          ></i>
                          Train Number:
                          {dataPreload.trainDetails.trainNumber}
                        </p>
                      </div>
                      <div className="col-md-7"></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Trains;
