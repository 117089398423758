import React, { useEffect, useState } from "react";
import "date-fns";
import { withRouter } from "react-router-dom";

const Preview = ({ trip }) => {
  const [trains, setTrains] = useState("");

  useEffect(() => {
    if (!trip.trains) return;

    setTrains(trip.trains);
  }, [trip.id]);

  var flightdetails;
  if (trains == "") {
    flightdetails = "No trains";
  } else {
    flightdetails = "Trains Preview";
  }

  return (
    <>
      <p>
        {flightdetails}
        <h5>Station</h5>
        <>
          {" "}
          Trains
          <br />
          {trains ? (
            <div className="row">
              <div className="col-md-4">
                <h6>Train Details</h6>
                Arrival Date: {trains.trainDetails.arrivalDate}
                <br />
                Arrival Time: {trains.trainDetails.arrivalTime}
                <br />
                Company Name: {trains.trainDetails.companyName}
                <br />
                Departure Date: {trains.trainDetails.departureDate}
                <br />
                Departure Time: {trains.trainDetails.departureTime}
                <br />
                Destination Station: {trains.trainDetails.destinationStation}
                <br />
                Origin Station: {trains.trainDetails.originStation}
                <br />
                Train Name: {trains.trainDetails.trainName}
                <br />
                Train Number: {trains.trainDetails.trainNumber}
                <br />
              </div>
              <div className="col-md-4">
                <h6>Trains Comment</h6>
                Train Number: {trains.trainsComment?.trainClass}
                <br />
                Comment:{" "}
                <p
                  dangerouslySetInnerHTML={{
                    __html: trains.trainsComment.trainComment,
                  }}
                />
                <br />
              </div>
              <div className="col-md-4">
                <h6>Map Location</h6>
                Current Latitude: {trains.MapLocation.currentLatitude}
                <br />
                Current Longitude: {trains.MapLocation.currentLongitude}
                <br />
                Destination Latitude: {trains.MapLocation.destinationLatitude}
                <br />
                Destination Longitude: {trains.MapLocation.destinationLongitude}
                <br />
              </div>
            </div>
          ) : (
            "no"
          )}
        </>
      </p>
    </>
  );
};

export default withRouter(Preview);
