import moment from "moment";

export const dateTimeFromStrings = (date, time) => {
  return moment(date + time, "ddd MMM D YYYY h:mm A").toDate();
};

export const dateFromString = (string) => {
  return moment(string, "ddd MMM D YYYY").toDate();
};

export const stringFromDate = (date) => {
  return moment(date).format("ddd MMM D YYYY");
};

export const timeFromDate = (date) => {
  return moment(date).format("h:mm A");
};

export const dateFromTime = (time) => {
  return moment(time, "h:mm A").toDate();
};
