import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { useDebounce } from "use-debounce";
import { ReactComponent as AlgoliaLogo } from "../../algolia-logo.svg";

import firebase from "../firebase-auth/Config";
import "./css/Trips.css";
import {
  direction,
  flipDirection,
  sortableFields,
  getTrips as _getTrips,
  deleteTrip as _deleteTrip,
  archiveTrip as _archiveTrip,
  unArchiveTrip as _unArchiveTrip,
} from "./controllers/trips";
import { InputAdornment, TextField } from "@material-ui/core";
import { sortStringsAsc, sortStringsDesc } from "./utils";

const parseFirestoreTimestamp = (timestamp) => {
  if (!timestamp) return "-";
  const seconds = timestamp.hasOwnProperty("seconds")
    ? timestamp.seconds
    : timestamp._seconds;
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toDateString();
};

const tripViewModel = ({
  key,
  title,
  startDate,
  subtitle,
  createdAt,
  updatedAt,
}) => ({
  key,
  title,
  subtitle,
  startDate: new Date(startDate),
  createdAt: parseFirestoreTimestamp(createdAt),
  updatedAt: parseFirestoreTimestamp(updatedAt),
});

const TripRow = ({
  trip: { key, title, subtitle, startDate, createdAt, updatedAt },
  onDelete,
  collection,
  archiveID,
}) => {
  console.log(startDate);
  const archiveTrip = useMemo(() => _archiveTrip(firebase.firestore()), []);
  const unArchiveTrip = useMemo(() => _unArchiveTrip(firebase.firestore()), []);

  const tripPath = () => {
    var path = `/TripShow/${key}`;

    if (archiveID) path += `/${archiveID}`;

    return path;
  };

  return (
    <tr key={key}>
      <td>
        <b>
          <Link to={tripPath()} target="_blank">
            {title}
          </Link>
        </b>
      </td>
      <td>{subtitle}</td>
      <td>{startDate.toDateString()}</td>
      <td>
        <Link
          to={`/trip_builder/${key}${archiveID ? `/${archiveID}` : ""}`}
          target="_blank"
        >
          <Button className="trips_button" variant="secondary">
            Edit Trip
          </Button>
        </Link>
      </td>
      <td>
        <Link to={`/pdf/${key}${archiveID && `/${archiveID}`}`}>
          <Button className="trips_button" variant="secondary">
            Generate PDF
          </Button>
        </Link>
      </td>
      <td>
        <Link to={`/CopyTrip/${key}${archiveID ? `/${archiveID}` : ""}`}>
          <Button className="trips_button" variant="warning">
            Copy Trip
          </Button>
        </Link>
      </td>
      <td>
        <Button
          onClick={() => onDelete(key, false, title, subtitle)}
          className="trips_button"
          variant="danger"
        >
          Delete Trip
        </Button>
      </td>
      <td>
        {collection == "Tripss" ? (
          <Dropdown>
            <Dropdown.Toggle
              className="trips_button"
              variant="success"
              id="dropdown-basic"
            >
              Archive Trip
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  archiveTrip(
                    "Archived Trips - Went",
                    key,
                    onDelete,
                    title,
                    subtitle
                  )
                }
              >
                Went
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  archiveTrip(
                    "Archived Trips - Didn't Go",
                    key,
                    onDelete,
                    title,
                    subtitle
                  )
                }
              >
                Didn't Go
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Button
            onClick={() =>
              unArchiveTrip(collection, key, onDelete, title, subtitle)
            }
            className="trips_button"
            variant="success"
          >
            Un-archive
          </Button>
        )}
      </td>
      <td>{createdAt}</td>
      <td>{updatedAt}</td>
    </tr>
  );
};

const TripList = ({ history, collection = "Tripss" }) => {
  const [sortField, setSortField] = useState(sortableFields.updatedAt);
  const [sortDirection, setSortDirection] = useState(direction.descending);
  const [_search, setSearch] = useState("");
  const [search] = useDebounce(_search, 500);
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("TRIP LIST");
  const [archiveID, setArchiveID] = useState(0);

  const getTrips = useMemo(() => _getTrips(firebase.firestore()), []);

  const deleteTrip = useCallback(
    async (key, isToArchive, tripTitle, tripSubtitle) => {
      var action = "delete";

      if (isToArchive) {
        if (collection != "Tripss") action = "un-archive";
        else action = "archive";
      }

      if (
        !window.confirm(
          `Are you sure you want to ${action} this trip?\n\n${tripTitle} - ${tripSubtitle}`
        )
      )
        return;

      try {
        await _deleteTrip(firebase.firestore())(key, collection);
        setTrips(trips.filter((trip) => trip.key !== key));
      } catch (error) {
        alert(
          "An error occurred while trying to delete this trip. Please try again later."
        );
        console.error("Error removing document: ", error);
      }
    },
    [trips, setTrips]
  );

  const sortTrips = () => {
    const sortedTrips = [...trips];

    if (sortField == "title") {
      if (sortDirection == "asc") {
        sortedTrips.sort((tripA, tripB) =>
          sortStringsDesc(tripA.title, tripB.title)
        );
      } else {
        sortedTrips.sort((tripA, tripB) =>
          sortStringsAsc(tripA.title, tripB.title)
        );
      }
    } else if (sortField == "subtitle") {
      if (sortDirection == "asc") {
        sortedTrips.sort((tripA, tripB) =>
          sortStringsAsc(tripA.subtitle, tripB.subtitle)
        );
      } else {
        sortedTrips.sort((tripA, tripB) =>
          sortStringsDesc(tripA.subtitle, tripB.subtitle)
        );
      }
    } else if (sortField == "startDate") {
      if (sortDirection == "asc") {
        sortedTrips.sort((tripA, tripB) =>
          sortStringsAsc(tripA.startDate, tripB.startDate)
        );
      } else {
        sortedTrips.sort((tripA, tripB) =>
          sortStringsDesc(tripA.startDate, tripB.startDate)
        );
      }
    } else if (sortField == "createdAt") {
      if (sortDirection == "asc") {
        sortedTrips.sort((tripA, tripB) => {
          const dateA = new Date(tripA.createdAt);
          const dateB = new Date(tripB.createdAt);
          return dateA - dateB;
        });
      } else {
        sortedTrips.sort((tripA, tripB) => {
          const dateA = new Date(tripA.createdAt);
          const dateB = new Date(tripB.createdAt);
          return dateB - dateA;
        });
      }
    } else if (sortField == "updatedAt") {
      if (sortDirection == "asc") {
        sortedTrips.sort((tripA, tripB) => {
          const dateA = new Date(tripA.updatedAt);
          const dateB = new Date(tripB.updatedAt);
          return dateA - dateB;
        });
      } else {
        sortedTrips.sort((tripA, tripB) => {
          const dateA = new Date(tripA.updatedAt);
          const dateB = new Date(tripB.updatedAt);
          return dateB - dateA;
        });
      }
    }
    setTrips(sortedTrips);
  };

  useEffect(() => {
    if (collection == "Tripss") {
      setTitle("TRIP LIST");
    } else {
      setTitle(collection.toUpperCase());
      setArchiveID(collection == "Archived Trips - Went" ? 1 : 2);
    }
  }, [collection]);

  useEffect(() => {
    if (search.length < 2 && !trips) return;
    setLoading(true);
    getTrips(search, collection)
      .then((result) => setTrips(result.map(tripViewModel)))
      .catch((e) => {
        console.error(e);
        alert(
          "An error occurred while fetching the list of trips. Please try again later."
        );
      })
      .finally(() => setLoading(false));
  }, [search, setLoading, setTrips, getTrips]);

  useEffect(() => {
    if (!trips.length || loading) return;
    sortTrips();
  }, [loading, sortField, sortDirection]);

  const onSearchChange = useCallback(
    (e) => {
      setSearch(e.target.value.toLowerCase());
    },
    [setSearch]
  );

  const toggleSort = useCallback(
    (field) => {
      if (field === sortField) {
        setSortDirection(flipDirection(sortDirection));
      } else {
        setSortField(field);
        setSortDirection(direction.ascending);
      }
    },
    [sortField, setSortField, sortDirection, setSortDirection]
  );

  return (
    <>
      <div
        className="pdfDiv"
        style={{
          display: "none",
          margin: "2px solid black",
          backgroundColor: "blue",
        }}
      >
        <div className="title">
          <div className="row">
            <div className="col-md-4 fa fa-facebook"> hwllo</div>
            <div className="col-md-4">
              <h1 style={{ color: "green", backgroundColor: "orange" }}>
                {" "}
                TRIPS PDF
              </h1>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
        <div className="flights" style={{ margin: "20px 0px 30px 0px" }}>
          <h1 className="flighTitle">FLIGHTS</h1>
          <div className="row">
            <div className="col-md-4">
              <span className="airLineName">
                <h3>airlinename -:</h3>
              </span>
              <br />
              <span className="airLineCode">
                <h3>airLineCode -:</h3>
              </span>
              <br />
              <span className="airLineClass">
                <h3>airLineClass -:</h3>
              </span>
              <br />
              <span className="seat">
                <h3>seat -:</h3>
              </span>
              <br />
            </div>
            <div className="col-md-4">
              <h3> valar morghulis</h3>
            </div>
            <br />
          </div>
        </div>
      </div>
      <br />
      <div className="container-fluid">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">{title}</h3>
          </div>
          <div>
            <TextField
              id="standard-textarea"
              label="Search"
              placeholder="Search Your Trip"
              multiline
              value={_search}
              onChange={onSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlgoliaLogo width="24" height="24" viewBox="0 0 60 55" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <br />
          <div className="panel-body">
            <h4>
              <Link to="/CreateTrip">Add Trip</Link>
            </h4>
            <div className="table-responsive">
              <table className="table table-stripe">
                <thead>
                  <tr style={{ fontSize: "11px" }}>
                    <th onClick={() => toggleSort(sortableFields.title)}>
                      <i className="fa fa-filter"></i>&nbsp;Title
                    </th>
                    <th onClick={() => toggleSort(sortableFields.subtitle)}>
                      <i className="fa fa-filter"></i>&nbsp;Subtitle
                    </th>
                    <th onClick={() => toggleSort(sortableFields.startDate)}>
                      <i className="fa fa-filter"></i>&nbsp;Trip Start Date
                    </th>
                    <th>Edit Trip</th>
                    <th>Generate PDF</th>
                    <th>Copy Trip</th>
                    <th>Delete Trip</th>
                    <th>
                      {collection == "Tripss"
                        ? "Archive Trip"
                        : "Un-archive Trip"}
                    </th>
                    <th onClick={() => toggleSort(sortableFields.createdAt)}>
                      <i className="fa fa-filter"></i>&nbsp;Created At
                    </th>
                    <th onClick={() => toggleSort(sortableFields.updatedAt)}>
                      <i className="fa fa-filter"></i>&nbsp;Updated At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    trips.map((trip) => (
                      <TripRow
                        key={trip.key}
                        trip={trip}
                        collection={collection}
                        onDelete={deleteTrip}
                        archiveID={archiveID}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TripList;
