import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import firebase from "../firebase-auth/Config";
import { Button } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Overview from "./tripshow/Overview";
import { getCollectionNameFromId } from "./utils";

// TODO: clean this up
const Pdf = ({
  match: {
    params: { id, archiveID },
  },
}) => {
  const [val, setVal] = useState("");

  useEffect(() => {
    const collection = getCollectionNameFromId(archiveID);
    const db = firebase.firestore();
    const cityRef = db.collection(collection).doc(id);

    cityRef.get().then((doc) => {
      if (doc.exists) {
        const allData = doc.data();
        setVal(allData);
      }
    });
  }, []);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpg");
      const pdf = new jsPDF("p", "mm", "a3");
      pdf.addImage(imgData, "jpg", 0, 0);
      pdf.save(`${val.title}`);
    });
  };

  return (
    <div>
      <br />
      <div className="mb5">
        <center>
          <Button className="generate-pdf" onClick={printDocument}>
            <i className="fa fa-file-pdf-o fa-2x"></i>&nbsp;Download Pdf
          </Button>
        </center>
      </div>
      <br />
      <div
        id="divToPrint"
        className="mt4"
        style={{
          width: "290mm",
          marginRight: "auto",
          marginLeft: "auto",
          minHeight: "297mm",
        }}
      >
        <br />
        <div className="title-pdf">
          <br />
          <h1>{val.title}</h1>
          <h3>
            {val.startDate} - {val.endDate}
          </h3>
          <h5>
            Created For You By: TJ Rule <br />
            <br />
            At Golf Away Tours
          </h5>
        </div>
        <Overview match={id} />
      </div>
    </div>
  );
};
export default withRouter(Pdf);
