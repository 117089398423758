import React, { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditor_config } from "../../../Constants/urls";
import { updateTrip } from "../utils";

const ResturantDescription = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [description, setDescription] = useState("");
  const [resturantsDescription, setresturantsDescription] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const handleDescription = (e, index, editor) => {
    setDescription(
      (resturantsDescription[index].resturantsDescription.description =
        editor.getData())
    );
  };
  useEffect(() => {
    if (trip.resturants) {
      const baseCall =
        Object.prototype.toString.call(trip.resturants) === "[object Object]"
          ? [trip.resturants]
          : trip.resturants;
      setresturantsDescription(baseCall);
    }
  }, []);

  const addResturantDetails = (e, index) => {
    setresturantsDescription([
      ...index,
      {
        mapMarkerLocation: {
          latitude: "",
          longtitute: "",
        },
        resturantImageURL: "",
        resturantsDescription: {
          description: "",
        },
        resturantsDetails: {
          resturantAddress: "",
          resturantName: "",
          selectedDate: new Date().toDateString(),
          selectedTime: new Date(),
        },
      },
    ]);
  };
  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      resturants: resturantsDescription,
      updatedAt: new Date(),
    });

    setIsSave(true);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };
  return (
    <>
      {rendorFlashMessage()}

      {resturantsDescription.map((details, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>Restaurant {index + 1}</h5>
            </div>
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group>
                <Form.Label>
                  <b>Comments</b>
                </Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  onChange={(e, editor) => handleDescription(e, index, editor)}
                  name="description"
                  config={ckeditor_config}
                  data={details.resturantsDescription.description}
                  value={details.resturantsDescription.description}
                />
              </Form.Group>
            </Form.Row>
          </Form>
          {/* <button onClick={(e) => removeResturantDetails(e, index)}>
            Remove
          </button> */}
        </div>
      ))}
      {/* <button onClick={(e) => addResturantDetails(e, resturantsDescription)}>
        Add Description
      </button> */}
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save
      </button>
    </>
  );
};
export default withRouter(ResturantDescription);
