import React from "react";
import "../tripshow.css";

import { TripImage } from "../../utils";

// TODO: clean this up
const Resturants = ({ dataPreload }) => {
  return (
    <>
      {dataPreload ? (
        <div className="container">
          {/* {resturants.map((resturant) => ( */}
          <>
            {dataPreload.resturantsDetails.resturantName !== "" ? (
              <>
                {" "}
                <div style={{ padding: 10 }} />
                <br />
                <div>
                  <div className="titlebar">
                    {dataPreload.resturantsDetails.resturantName
                      ? dataPreload.resturantsDetails.resturantName
                      : " "}
                  </div>
                </div>
                <div className="card">
                  <div className="scrollablecard">
                    <div>
                      <div className="row">
                        <div className="col-md-5">
                          <p>
                            <i
                              class="fa fa-calendar"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            ></i>
                            {dataPreload.resturantsDetails.selectedDate}
                            &nbsp;
                            {dataPreload.resturantsDetails.selectedTime}
                          </p>
                          <br />

                          <p>
                            <i
                              class="fa fa-map-marker"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            ></i>{" "}
                            {dataPreload.resturantsDetails.resturantAddress}
                          </p>
                          <p>
                            {/* Description:&nbsp;{" "} */}
                            {dataPreload.resturantsDescription.description ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    dataPreload.resturantsDescription
                                      .description,
                                }}
                              />
                            ) : (
                              <span style={{ float: "right" }}>{""}</span>
                            )}{" "}
                          </p>
                        </div>
                        {dataPreload.resturantImageURL !== "" ? (
                          <div className="col-md-7">
                            <TripImage
                              src={dataPreload.resturantImageURL}
                              defaultURL={null}
                              alt="Restaurant"
                              width="400"
                              height="280"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
          {/* // ))} */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Resturants;
