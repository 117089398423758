import React, { useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import { withRouter } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { updateTrip } from "../utils";
import {
  dateFromTime,
  stringFromDate,
  timeFromDate,
} from "../../../utils/date-utils";

const AirportsDetails = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [allFlights, setAllFlights] = useState([]);

  const [airportOrigin, setAirportOriginPoint] = useState("");
  const [airportName, setAirportName] = useState("");
  const [originTerminal, setOriginTerminal] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [airportDestination, setAirportDestinationPoint] = useState("");
  const [airportDestinationName, setAirportDestinationName] = useState("");
  const [destinationTerminal, setDestinationTerminal] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);

  const handleDateChange = (date, index) => {
    let flights = [...allFlights];
    let flight = { ...flights[index] };
    flight.airports.arrivalDate = stringFromDate(date);
    flights[index] = flight;
    setAllFlights(flights);
  };

  const arrivalChangeChange = (date, index) => {
    let flights = [...allFlights];
    let flight = { ...flights[index] };
    flight.airports.arrivalTime = timeFromDate(date);
    flights[index] = flight;
    setAllFlights(flights);
  };

  const handleDepartureDateChange = (date, index) => {
    let flights = [...allFlights];
    let flight = { ...flights[index] };
    flight.airports.departureDate = stringFromDate(date);
    flights[index] = flight;
    setAllFlights(flights);
  };

  const handleDepartureTimeChange = (date, index) => {
    let flights = [...allFlights];
    let flight = { ...flights[index] };
    flight.airports.departureTime = timeFromDate(date);
    flights[index] = flight;
    setAllFlights(flights);
  };

  useEffect(() => {
    if (trip?.flights) {
      const baseCall =
        Object.prototype.toString.call(trip.flights) === "[object Object]"
          ? [trip.flights]
          : trip.flights;

      const flights = [...baseCall];

      setAllFlights(flights);
    }
  }, [trip?.flights]);

  const onSubmit = (e) => {
    console.log("SUMBIT: ", allFlights);
    updateTrip(trip.id, archiveID, {
      flights: allFlights,
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };
  return (
    <>
      {rendorFlashMessage()}
      <>
        {allFlights.map((data, index) => (
          <div key={index}>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <h5>Flight {index + 1}</h5>
              </div>
            </div>
            <hr />
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Departure Date"
                      format="MM/dd/yyyy"
                      value={data.airports.departureDate}
                      minDate={minDate}
                      maxDate={maxDate}
                      onChange={(e) => handleDepartureDateChange(e, index)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Departure Time"
                      value={
                        data.airports.departureTime
                          ? dateFromTime(data.airports.departureTime)
                          : new Date()
                      }
                      onChange={(e) => handleDepartureTimeChange(e, index)}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Origin</Form.Label>
                  <Form.Control
                    placeholder="Origin"
                    name="airportOrigin"
                    value={data.airports.airportOrigin}
                    onChange={(e) =>
                      setAirportOriginPoint(
                        (allFlights[index].airports.airportOrigin =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Airport</Form.Label>
                  <Form.Control
                    placeholder="Airport"
                    name="airportName"
                    value={data.airports.airportName}
                    onChange={(e) =>
                      setAirportName(
                        (allFlights[index].airports.airportName =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Terminal</Form.Label>
                  <Form.Control
                    placeholder="Terminal"
                    name="originTerminal"
                    value={data.airports.originTerminal}
                    onChange={(e) =>
                      setOriginTerminal(
                        (allFlights[index].airports.originTerminal =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    placeholder="City"
                    name="originCity"
                    value={data.airports.originCity}
                    onChange={(e) =>
                      setOriginCity(
                        (allFlights[index].airports.originCity = e.target.value)
                      )
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Arrival Date"
                      format="MM/dd/yyyy"
                      value={data.airports.arrivalDate}
                      minDate={data.airports.departureDate}
                      maxDate={maxDate}
                      onChange={(e) => handleDateChange(e, index)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Arrival Time"
                      value={
                        data.airports.arrivalTime
                          ? dateFromTime(data.airports.arrivalTime)
                          : new Date()
                      }
                      onChange={(e) => arrivalChangeChange(e, index)}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Destination</Form.Label>
                  <Form.Control
                    placeholder="Destination"
                    name=" airportDestination"
                    value={data.airports.airportDestination}
                    onChange={(e) =>
                      setAirportDestinationPoint(
                        (allFlights[index].airports.airportDestination =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Airport</Form.Label>
                  <Form.Control
                    placeholder="Airport"
                    name="airportDestinationName"
                    value={data.airports.airportDestinationName}
                    onChange={(e) =>
                      setAirportDestinationName(
                        (allFlights[index].airports.airportDestinationName =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Terminal</Form.Label>
                  <Form.Control
                    placeholder="Terminal"
                    name="destinationTerminal"
                    value={data.airports.destinationTerminal}
                    onChange={(e) =>
                      setDestinationTerminal(
                        (allFlights[index].airports.destinationTerminal =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    placeholder="City"
                    name="destinationCity"
                    value={data.airports.destinationCity}
                    onChange={(e) =>
                      setDestinationCity(
                        (allFlights[index].airports.destinationCity =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </div>
        ))}
      </>
      <button onClick={onSubmit} className="marginButtonBig stickyButton">
        Save Details.
      </button>
    </>
  );
};

export default withRouter(AirportsDetails);
