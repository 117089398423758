import { SET_USERS, SET_USER_ID } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        all_users: action.payload
      };
      case SET_USER_ID:
        return {
          ...state,
          userID: action.payload
        };
    default:
      return state;
  }
}
