import React, { useCallback, useState, useEffect } from "react";
import { Tabs, Tab, Popover, MenuItem } from "@material-ui/core";
import Overview from "./tripshow/Overview";
import TripInformation from "./tripshow/TripInformation";
import Itinerary from "./tripshow/Itinerary";
import firebase from "../firebase-auth/Config";
import "./specialCase.css";
import "./tripshow/tripshow.css";
import withFirebaseAuth from "react-auth-firebase";

import { getTrip, getStorageURL, getCollectionNameFromId } from "./utils";

const styles = {
  Tab: {
    flexDirection: "row-reverse",
  },
};

const sectionStyle = (backgroundImage) => ({
  backgroundColor: "transparent !important",
  width: "100%",
  marginTop: "-65px",
  marginBottom: "100px",
  height: "800px",
  position: "fixed",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${backgroundImage})`,
});

const print = () => window.print();

const TripShow = ({
  match: {
    params: { id, archiveID },
  },
}) => {
  const [attachments, setAttachments] = useState([]);
  const [background, setBackground] = useState();
  const [creatorName, setCreatorName] = useState();
  const [endDate, setEndDate] = useState();
  const [popoverAnchor, setPopoverAnchor] = useState();
  const [selectedAttachment, setSelectedAttachment] = useState();
  const [startDate, setStartDate] = useState();
  const [tab, setTab] = useState("One");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [trip, setTrip] = useState();

  useEffect(() => {
    const collection = getCollectionNameFromId(archiveID);

    getTrip(id, collection).then((trip) => {
      console.log(trip);
      setTrip(trip);
      setAttachments(trip.attachements);
      setCreatorName(trip.userName);
      setEndDate(trip.endDate);
      setStartDate(trip.startDate);
      setTitle(trip.title);
      setSubtitle(trip.subtitle);
      getStorageURL(
        [trip.tripImages.tripImageURL, trip.multipleTripImages[0]],
        null
      ).then((url) => setBackground(url));
    });
  }, [id, setBackground]);

  const onAdditionalInfoClick = useCallback(
    (e) => {
      e.stopPropagation();
      setPopoverAnchor(e.currentTarget);
    },
    [setPopoverAnchor]
  );

  const onCloseAttachmentPopover = useCallback(() => {
    setPopoverAnchor(null);
  }, [setPopoverAnchor]);

  const onSelectAttachment = useCallback(
    (index) => () => {
      setPopoverAnchor(null);
      setSelectedAttachment(attachments[index]);
      setTab("Five");
    },
    [setPopoverAnchor, setTab, setSelectedAttachment, attachments]
  );

  return (
    <>
      <div class="icon-bar">
        <a href="https://facebook.com/golfawaytours" class="facebook">
          <i class="fa fa-facebook"></i>
        </a>
        <a href="https://twitter.com/golfawaytj" class="twitter">
          <i class="fa fa-twitter"></i>
        </a>
        <a href="https://instagram.com/golfawaytours" class="instagram">
          <i class="fa fa-instagram"></i>
        </a>
        <a href="#" onClick={print} class="google">
          <i class="fa fa-print"></i>
        </a>
      </div>

      <div style={sectionStyle(background)}></div>
      <br />

      <div class="card trip-card text-center">
        <h1 className="mainTitle">{title}</h1>
        {subtitle && <h1 className="mainTitle">{subtitle}</h1>}
        <div className="">
          <div className="" style={{ marginLeft: "19px !important" }}>
            <span className="mainDate">
              {startDate} - {endDate}
            </span>
            <br />
            <span className="author">
              Created For You By:&nbsp;&nbsp;
              {creatorName ?? "TJ Rule"}
              <br />
              At Golf Away Tours
            </span>
          </div>
        </div>
      </div>
      <br />
      <br />

      <Tabs value={tab} indicatorColor="primary">
        <Tab value="One" label="Overview" onClick={() => setTab("One")} />
        <Tab value="Two" label="Itinerary" onClick={() => setTab("Two")} />
        <Tab
          value="Five"
          label={
            <>
              Additional Info &nbsp;<i className="fa fa-caret-down"></i>{" "}
            </>
          }
          onClick={onAdditionalInfoClick}
        />
      </Tabs>

      <TabContent
        tab={tab}
        trip={trip}
        selectedAttachment={selectedAttachment}
      />

      <AdditionalInfoPopover
        anchor={popoverAnchor}
        onClose={onCloseAttachmentPopover}
        attachments={attachments}
        onSelect={onSelectAttachment}
      />
    </>
  );
};

const AdditionalInfoPopover = ({ anchor, onClose, attachments, onSelect }) => {
  return (
    <Popover
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {attachments.map((attachment, index) => (
        <MenuItem key={index} onClick={onSelect(index)}>
          <>{attachment.title ? attachment.title : "No Information"} </>
        </MenuItem>
      ))}
    </Popover>
  );
};

const TabContent = ({ selectedAttachment, tab, trip }) => {
  switch (tab) {
    case "One":
      return <Overview trip={trip} />;
    case "Two":
      return <Itinerary trip={trip} />;
    case "Five":
      return <TripInformation trip={trip} index={selectedAttachment} />;
    default:
      throw new Error("Invalid tab value");
  }
};

const authConfig = {
  email: {
    verifyOnSignup: false, // Sends verification email to user upon sign up
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
  google: {
    returnUser: true,
    returnAccessToken: true, // Returns an access token as googleAccessToken prop
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
};

export default withFirebaseAuth(TripShow, firebase, styles, authConfig);
