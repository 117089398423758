import React from "react";
import "../tripshow.css";

import { TripImage } from "../../utils";

// TODO: clean this up
const CarTransfers = ({ dataPreload }) => {
  return (
    <>
      {dataPreload ? (
        <div className="container">
          <>
            {dataPreload.carRentalsDetails.companyName !== "" ? (
              <>
                <>
                  <div style={{ padding: 10 }} />
                  <br />
                  <div>
                    <div className="titlebar">
                      {/* <b>Car Rentals</b>
                       */}
                      {dataPreload.carRentalsDetails.companyName
                        ? dataPreload.carRentalsDetails.companyName
                        : " "}
                    </div>
                  </div>
                  <div className="card">
                    <div className="scrollablecard">
                      <div>
                        <div className="row">
                          <div className="col-md-5">
                            <p>
                              <i
                                class="fa fa-map-marker"
                                aria-hidden="true"
                                style={{ marginRight: "13px" }}
                              ></i>
                              Pickup Location:{" "}
                              {dataPreload.carRentalsDetails?.pickUpLocation}
                            </p>
                            <p>
                              <i
                                class="fa fa-calendar"
                                aria-hidden="true"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Pickup Date:{" "}
                              {dataPreload.carRentalsDetails.startDate}
                            </p>
                            <p>
                              <i
                                class="fa fa-clock-o"
                                aria-hidden="true"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Pickup Time:{" "}
                              {dataPreload.carRentalsDetails.startTime}
                            </p>
                            <p>
                              <i
                                class="fa fa-map-marker"
                                aria-hidden="true"
                                style={{ marginRight: "13px" }}
                              ></i>
                              Pickup Location:{" "}
                              {dataPreload.carRentalsDetails?.dropLocation}
                            </p>
                            <p>
                              <i
                                class="fa fa-calendar"
                                aria-hidden="true"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Drop Date: {dataPreload.carRentalsDetails.endDate}
                            </p>
                            <p>
                              <i
                                class="fa fa-clock-o"
                                aria-hidden="true"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Drop Time: {dataPreload.carRentalsDetails.endTime}
                            </p>
                            <br />
                            <br />
                            <p>
                              {
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      dataPreload.carRentalsDescription
                                        .description,
                                  }}
                                />
                              }
                            </p>

                            <br />
                            <h6></h6>
                          </div>
                          {dataPreload.url !== "" ? (
                            <div className="col-md-7">
                              <TripImage
                                src={dataPreload.url}
                                defaultURL={null}
                                alt="Car transfer"
                                width="400"
                                height="280"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </>
            ) : (
              <div></div>
            )}
          </>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default CarTransfers;
