import React from "react";
import Overview from "./Overview_Day_by_day";
import firebase from "./components/firebase-auth/Config";
import TripDetails from "./components/trips/TripDetails";
import { Link, withRouter } from "react-router-dom";
import Trip_images from "./components/trips/trip_images";
import Attachments from "./components/trips/Info_Pages";
import { Tabs, Tab } from "@material-ui/core";
import "./components/trips/css/tabs.css";
import { getCollectionNameFromId } from "./components/trips/utils";

const styles = {
  Tab: {
    flexDirection: "row-reverse",
  },
};

class TabDemo extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeTabs = this.handleChangeTabs.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      value: "One",
      label: "Three",
      content: "One",
      anchorEl: null,
      id: "",
      title: "",
      trip: undefined,
    };
  }

  componentDidMount() {
    var db = firebase.firestore();
    const collection = getCollectionNameFromId(
      this.props.match.params?.archiveID
    );

    var cityRef = db.collection(collection).doc(this.props.match.params.id);

    cityRef.get().then((doc) => {
      console.log(doc);
      if (doc.exists) {
        const allData = doc.data();

        this.setState({
          title: allData.title,
          id: this.props.match.params.id,
          trip: { ...allData, id: doc.id },
        });
      }
    });
  }

  handleChangeTabs(value) {
    this.setState({ value });
  }

  handleClick(event) {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }

  handleMenuItemClick(menuItem) {
    this.handleClose();
    this.setState({
      label: menuItem,
      content: menuItem,
      value: "More",
    });
  }

  handleContent = (v) => {
    this.state.content = v;
    this.state.value = v;
  };

  renderElement() {
    if (!this.state.trip) return <p>Loading...</p>;

    const { content } = this.state;
    if (content === "One") {
      return (
        <TripDetails
          triggerContent={this.handleContent}
          trip={this.state.trip}
          updateTripState={(newTripData) => {
            this.state.trip = newTripData;
          }}
        />
      );
    }
    if (content === "Two") {
      return <Overview trip={this.state.trip} />;
    }
    if (content === "Three") {
      return <Trip_images trip={this.state.trip} />;
    }
    if (content === "Four") {
      return <Attachments />;
    }
  }

  render() {
    const { value } = this.state;
    return (
      <>
        <div className="responsive">
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={this.handleChangeTabs}
          >
            <Tab
              value="One"
              label="Trips Details"
              onClick={() => this.setState({ content: "One" })}
            />
            <Tab
              value="Two"
              label="Day By Day Itinerary"
              onClick={() => this.setState({ content: "Two" })}
            />
            <Tab
              value="Three"
              label="Trip Photos"
              onClick={() => this.setState({ content: "Three" })}
            />
            <Tab
              value="Four"
              label="Additional Info"
              onClick={() => this.setState({ content: "Four" })}
            />
            {/* <div className="col-md-3"> */}
            <h1
              className="mainTitle text-right mt-2 mr-2"
              style={{ fontSize: "26px" }}
            >
              {this.state.title}
            </h1>
            {/* </div> */}
          </Tabs>
          <center>
            <Link
              to={`/TripShow/${this.props.match.params.id}${
                this.props.match.params?.archiveID
                  ? `/${this.props.match.params.archiveID}`
                  : ""
              }`}
              target={"_blank"}
            >
              <button class="btn-view-all-show">
                <i class="fa fa-eye"></i>&nbsp;Preview Trip
              </button>
            </Link>
          </center>
          {this.renderElement(this.state.content)}
        </div>
      </>
    );
  }
}

export default withRouter(TabDemo, styles);
