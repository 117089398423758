import React from "react";
import "../tripshow.css";

import { TripImage } from "../../utils";

// TODO: clean this up
const DayOverviews = ({ dataPreload }) => {
  return (
    <>
      {dataPreload ? (
        <div className="container">
          <>
            {dataPreload.OverviewDescription !== "" ? (
              <>
                <div style={{ padding: 10 }} />
                <br />

                <div>
                  <div className="titlebar">
                    {dataPreload.OverviewDescription
                      ? dataPreload.OverviewDescription
                      : " "}
                  </div>
                </div>
                <div className="card">
                  <div className="scrollablecard">
                    <div>
                      <div className="row">
                        <div className="col-md-5">
                          <p>
                            <i
                              class="fa fa-calendar"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            ></i>
                            {dataPreload.startDate}
                          </p>
                          <br />
                          <p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: dataPreload.editorDescription,
                              }}
                            />
                          </p>
                          {dataPreload.attachments?.length > 0 && (
                            <div>
                              <h6>
                                <b>Attachments</b>
                              </h6>
                              <ul>
                                {dataPreload.attachments.map((attachment) => (
                                  <li>
                                    <a href={attachment.file} target="_blank">
                                      {attachment.fileName}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        {dataPreload.url !== "" ? (
                          <div className="col-md-5">
                            <TripImage
                              src={dataPreload.url}
                              defaultURL={null}
                              alt="Day overview"
                              width="400"
                              height="280"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default DayOverviews;
