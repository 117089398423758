import React, { useState, useEffect } from "react";
import firebase from "../firebase-auth/Config";
import { withRouter } from "react-router-dom";
const SaveUsers = (props) => {
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState("");
  const [roles, setRoles] = useState({});
  const [active, setActive] = useState(true);
  const [users, setUsers] = useState("");

  const userRef = firebase.auth();
  const clickUsers = (e, index) => {
    if (userRef.currentUser && userRef.currentUser) {
      debugger;
      var db = firebase.firestore();
      var cityRef = db.collection("VitrualUsers");
      if (props.user.uid === userRef.currentUser.uid){
      var setWithMerge = cityRef
        .add({
          email: userRef.currentUser.email,
          uid: userRef.currentUser.uid,
          active: true,
          roles: {
            admin: false,
            user: true,
          },
        })
        .then((docRef) => {
          setUsers({
            id: docRef.id,
            email: "",
            uid: "",
            active: true,
          });
        });
      }
      else{
        
      }
    }
  };

  return (
    <div>
      <button onClick={(e) => clickUsers()}>Copy User</button>{" "}
    </div>
  );
};
export default withRouter(SaveUsers);
