import { stringFromDate, timeFromDate } from "../../../utils/date-utils";

export const hotelAmenities = [
  "Pool",
  "Bar or lounge",
  "Gym",
  "Laundry",
  "Room service",
  "Mini bar",
  "Spa",
  "Wi-Fi",
  "Iron and Ironing board",
  "Club Storage",
  "Rain clothes drying room",
  "Hot Tub",
  "Sauna",
  "Coffee maker",
  "Bathrobes and slippers",
  "Free breakfast",
];

const defaultCheckinTime = new Date();
defaultCheckinTime.setHours(15);
defaultCheckinTime.setMinutes(0);

const defaultCheckoutTime = new Date();
defaultCheckoutTime.setHours(11);
defaultCheckoutTime.setMinutes(0);

const defaultCheckinDate = new Date();

const defaultCheckoutDate = new Date();
defaultCheckoutDate.setDate(defaultCheckoutDate.getDate() + 1);

export const createHotel = () => {
  return {
    hotelName: "",
    hotelCheckin: stringFromDate(defaultCheckinDate),
    hotelCheckout: stringFromDate(defaultCheckoutDate),
    checkinTime: timeFromDate(defaultCheckinTime),
    checkoutTime: timeFromDate(defaultCheckoutTime),
    hotelPhone: "",
    hotelAddress: "",
    hotelConfirmation: "",
    hotelComment: "",
    hotelBedtype: "",
    hotelRoomtype: "",
    hotelLat: "",
    hotelLong: "",
    url: "",
    hotelImage: null,
    progress: 0,
    amenities: [],
  };
};
