import React, { useState, useHistory } from "react";
import firebase from "../firebase-auth/Config";
import FirebaseSignin from "./FirebaseSignin";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link, withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { Alert } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const Signup = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //   const [displayName, setdisplayName] = useState("");
  const [error, setError] = useState("");
  const handleSignup = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((u) => {
        // console.log(u, "I am A");

        props.history.push("/");
      })
      .catch((err) => {
        setError(err.message);
        // console.log(err, "I am error");
      });
  };
  return (
    <>
      <Container component="main" maxWidth="xs" className="login_frm text-center">
        <CssBaseline />
        <div className={classes.paper}>
          <img src="https://www.filepicker.io/api/file/rZOfHdkbTgKfE9KcxwL6" />
          <Avatar className={classes.avatar}></Avatar>

          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <form className={classes.form} noValidate>
            {error !== "" ? (
              <Alert key={"danger"} variant={"danger"}>
                {error}
              </Alert>
            ) : (
              ""
            )}
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="displayName"
              label="Name"
              name="displayName"
              onChange={(e) => setdisplayName(e.target.value)}
              autoComplete="displayName"
              autoFocus
              type="text"
            /> */}
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              onChange={(e) => setlastName(e.target.value)}
              autoComplete="lastName"
              autoFocus
              type="text"
            /> */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              autoComplete="password"
            />
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="confirmPassword"
              id="confirmPassword"
              autoComplete="confirmPassword"
            /> */}
            {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
            <Grid container>
              <Grid item>
                <Link to="/" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <br />
        <Button
          onClick={handleSignup}
          variant="contained"
          style={{width:'100%'}}
          size="large"
          color="primary"
          className="text-left"
        >
          Signup
        </Button>
        <center>
          <br />
          <br />
          <FirebaseSignin />
          <br />
          <br />
        </center>
      </Container>
    </>
  );
};
export default withRouter(Signup);
