import React from "react";
import "../tripshow.css";

import { TripImage } from "../../utils";

// TODO: clean this up
const Transfers = ({ dataPreload }) => {
  console.log("TRANSFERS", dataPreload);
  return (
    <>
      {dataPreload ? (
        <div className="container">
          <>
            <>
              <div style={{ padding: 10 }} />
              <br />
              <div>
                <div className="titlebar">
                  {dataPreload.transferDetails.companyName || "Transfer"}
                </div>
              </div>
              <div className="card">
                <div className="scrollablecard">
                  <div>
                    <div className="row">
                      <div className="col-md-5">
                        <p>
                          <i
                            class="fa fa-calendar"
                            aria-hidden="true"
                            style={{ marginRight: "8px" }}
                          ></i>
                          Date & Time:{" "}
                          {dataPreload.transferDetails.selectedDate}{" "}
                          {dataPreload.transferDetails.selectedTime}
                        </p>
                        {dataPreload.transferDetails?.returnTime && (
                          <p>
                            <i
                              class="fa fa-clock-o"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            />
                            Return Time:{" "}
                            {dataPreload.transferDetails.returnTime}
                          </p>
                        )}

                        <br />

                        <p>
                          Pickup: &nbsp;{" "}
                          {dataPreload.transferDetails.pickupLocation}
                        </p>
                        <p>
                          Drop: &nbsp;{" "}
                          {dataPreload.transferDetails.dropLocation}
                        </p>

                        <br />

                        {dataPreload.transferDetails?.companyName && (
                          <p>
                            Company Name:{" "}
                            {dataPreload.transferDetails.companyName}
                          </p>
                        )}

                        {dataPreload.transferDetails?.contactName && (
                          <p>
                            Contact Name:&nbsp;
                            {dataPreload.transferDetails.contactName}
                          </p>
                        )}

                        {dataPreload.transferDetails?.contactNumber && (
                          <p>
                            <i
                              class="fa fa-phone-square"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            ></i>
                            Contact Number:&nbsp;
                            {dataPreload.transferDetails.contactNumber}
                          </p>
                        )}

                        <br />

                        {dataPreload.transferComments?.comment && (
                          <>
                            <h6>
                              <b>Transfer Comments</b>
                            </h6>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: dataPreload.transferComments.comment,
                              }}
                            />
                          </>
                        )}
                      </div>
                      {dataPreload.url ? (
                        <div className="col-md-7">
                          <TripImage
                            src={dataPreload.url}
                            defaultURL={null}
                            alt="Transfer"
                            width="400"
                            height="280"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Transfers;
