import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import AddFlight from "./components/trips/AddFlight/addFlight";
import AddHotels from "./components/trips/AddHotel/addHotels";
import AddCarRental from "./components/trips/AddCarRental/AddCarRental";
import Addresturant from "./components/trips/AddResturant/Addresturant";
import AddTransfer from "./components/trips/AddTransfer/AddTransfer";
import AddTrain from "./components/trips/AddTrain/AddTrain";
import AddOverview from "./components/trips/AddOverview/AddOverview";
import Dashboard from "./components/trips/Dashboard";
import AddActivity from "./components/trips/AddActivity/AddActivity";
import { stringFromDate } from "./utils/date-utils";

const Newtrip = ({ trip }) => {
  const [TabComp, setTabComp] = useState("Dashboard");
  const handler = (val) => {
    // e.preventDefault();
    setTabComp(val);
  };

  const renderElement = (count) => {
    if (TabComp === "Dashboard")
      return <Dashboard trip={trip} handler={handler} />;
    if (TabComp === "over") return <AddOverview trip={trip} />;
    if (TabComp === "flight") return <AddFlight trip={trip} />;
    if (TabComp === "Hotel") return <AddHotels trip={trip} />;
    if (TabComp === "Resturant") return <Addresturant trip={trip} />;
    if (TabComp === "Activity") return <AddActivity trip={trip} />;
    if (TabComp === "Rentalcar") return <AddCarRental trip={trip} />;
    if (TabComp === "Transfer") return <AddTransfer trip={trip} />;
    if (TabComp === "Train") return <AddTrain trip={trip} />;
  };

  console.log("TAB: ", TabComp);
  return (
    <>
      <Container>
        <Row>
          <div class="col-md-3">
            <br />
            <button
              className={
                TabComp === "Dashboard" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("Dashboard")}
            >
              Dashboard
            </button>
            <br />
            <button
              className={
                TabComp === "over" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("over")}
            >
              Day Overview
            </button>
            <br />
            <button
              className={
                TabComp === "flight" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("flight")}
            >
              Flight
            </button>
            <br />
            <button
              className={
                TabComp === "Hotel" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("Hotel")}
            >
              Hotel
            </button>
            <br />
            <button
              className={
                TabComp === "Resturant" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("Resturant")}
            >
              Restaurant
            </button>
            <br />
            <button
              className={
                TabComp === "Activity" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("Activity")}
            >
              Activity
            </button>
            <br />
            <button
              className={
                TabComp === "Rentalcar" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("Rentalcar")}
            >
              Car Rental
            </button>
            <br />
            <button
              className={
                TabComp === "Transfer" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("Transfer")}
            >
              Transfer
            </button>
            <br />
            <button
              className={
                TabComp === "Train" ? "activemarginButton" : "marginButton"
              }
              onClick={() => setTabComp("Train")}
            >
              Train
            </button>
            <br />
          </div>
          <div className="col-md-9">
            <br />
            {renderElement(TabComp)}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(Newtrip);
