import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import firebase from '../firebase-auth/Config';
import { storage } from '../firebase-auth/Config';
import { ckeditor_config } from '../../Constants/urls';

const editorConfiguration = {
  className: 'col-12',
};
class TabProfile extends Component {
  constructor(props) {
    super(props);
    this.userRef = firebase.auth();
    this.state = {
      company_profile: {
        branch_name: '',
        homepage_url: '',
        facebook_url: '',
        description: '',
        image: null,
      },
    };
    this.initialState = this.state;

    this.handleChange = this.handleChange.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.fetchCompanyProfile = this.fetchCompanyProfile.bind(this);
    this.updateCompanyProfile = this.updateCompanyProfile.bind(this);
    this.fileInput = React.createRef();
    this.updateState = this.setState.bind(this);
  }
  componentDidMount() {
    this.setState(this.initialState);
    this.fetchCompanyProfile(this.state, this.updateState);
    this.fetchCompanyData(this.state, this.updateState)
  }
  handleDescription(e, editor) {
    const state = this.state;
    state.company_profile['description'] = editor.getData();
    this.setState(state);
  }

  handleChange(e) {
    const state = this.state;
    state.company_profile[e.target.name] = e.target.value;
    this.setState(state);
  }

  handleImage(e) {
    const state = this.state;
    state.company_profile['image'] = this.fileInput.current.files[0].name;
    state.company_image_blob = URL.createObjectURL(e.target.files[0]);
    state.company_image = this.fileInput.current.files[0];
    this.setState(state);
  }

  async fetchCompanyData(state, updateState) {
   var db = firebase.firestore();
   await db.collection('company_profile')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if (doc.data().company_profile) {
            const tempState = state;
            tempState.company_profile = doc.data().company_profile;
            storage
              .ref()
              .child('misc/' + doc.data().company_profile.image)
              .getDownloadURL()
              .then(function (url) {
                tempState.company_image_blob = url;
                updateState(tempState);
              })
              .catch(function (error) {
                console.log('error getting image', error);
              });
          }
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
    }
  


  async fetchCompanyProfile(state, updateState) {
    var db = firebase.firestore();
    var adminArr = [];
   await db.collection('VirtualUsers')
      .where('email', '==', this.userRef.currentUser.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          localStorage.setItem('current_user_uid', doc.id);
          // if (doc.data().company_profile) {
          //   const tempState = state;
          //   tempState.company_profile = doc.data().company_profile;
          //   storage
          //     .ref()
          //     .child('misc/' + doc.data().company_profile.image)
          //     .getDownloadURL()
          //     .then(function (url) {
          //       tempState.company_image_blob = url;
          //       updateState(tempState);
          //     })
          //     .catch(function (error) {
          //       console.log('error getting image', error);
          //     });
          // }
          adminArr.push(doc.data().roles.admin);
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
    }

  updateCompanyProfile() {
      var db = firebase.firestore();
      delete(this.state.company_profile.disabledFields)
      var company_admins = db
        .collection('company_profile').doc("1hhUSqz7V268WJvCnSgw");
        company_admins.set(
          {
            company_profile: this.state.company_profile,
          },
          { merge: true }
        )
        .then((docRef) => {})
        .catch((error) => {
          console.log('ERROR UPDATING USER', error);
        });
  
      if (this.state.company_image_blob != this.initialState.company_image_blob) {
        const uploadImage = storage
          .ref()
          .child(`misc/${this.state.company_image.name}`)
          .put(this.state.company_image, { contentType: 'image/png' });
        uploadImage.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            this.setState({ progress });
          },
          (error) => {
            console.log(error, 'This Is error');
          },
          () => {
            storage
              .ref('misc')
              .child(this.state.company_profile.image.name)
              .getDownloadURL()
              .then((url) => {
                // const state = this.state.company_profile;
                // state['url'] = url;
                // this.state.progress = 0;
              });
          }
        );
      }
  }

  render() {
    return (
      <div className='contents-container row'>
        <h2 className='col-12'>Company profile</h2>
        {/* Section Form Content */}
        <section className='content col-8 d-flex flex-column align-self-start'>
          <div className='col-12 d-flex align-self-start'>
            <div className='row col-8'>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Branch Name</b>
                </label>
                <input
                  name='branch_name'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.company_profile.branch_name}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Homepage url</b>
                </label>
                <input
                  name='homepage_url'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.company_profile.homepage_url}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Company Profile Facebook</b>
                </label>
                <input
                  name='facebook_url'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.company_profile.facebook_url}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='d-flex flex-column justify-content-center'>
              <img
                src={
                  this.state.company_image_blob ||
                  'https://via.placeholder.com/150x160'
                }
                height='150px'
                width='150px'
                style={
                  {
                    // backgroundColor: '#000000',
                  }
                }
                className='mr-2'
              />
              <input
                type='file'
                onChange={this.handleImage}
                ref={this.fileInput}
                className=''
              />
            </div>
          </div>
          <div className='row col-12'>
            <label className='ml-4'>
              <b className='ml-1'>Description</b>
            </label>
            <div className='ml-4'>
              <CKEditor
                editor={ClassicEditor}
                data={this.state.company_profile.description}
                value={this.state.company_profile.description}
                onChange={this.handleDescription}
                config={ckeditor_config}
                className='ml-1'
                // style={{ width: '80%' }}
              />
            </div>
          </div>
        </section>
        {/* Section Instructions */}
        <section className='instructions col-4'>
          <div>
            <b>About Your Company Profile</b>
            <div>
              This information is visible to your customers in various places on
              the Portal. Writing in all-caps reflects poorly on you. Please
              refrain from doing so.
            </div>
          </div>
        </section>

          <div className='ml-4 mt-3'>
          <div
            className='ml-3 btn btn-primary'
            onClick={this.updateCompanyProfile}
          >
            Save
          </div>
        </div>
        
      </div>
    );
  }
}

export default TabProfile;
