import React, { useEffect, useState } from "react";
import firebase from "../firebase-auth/Config";
import { Form, Alert } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./css/proposal.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CreateProposal from "./CreateProposal";
import { ckeditor_config } from "../../Constants/urls";

const EditProposal = props => {
  const [proposalTitle, setProposalTitle] = useState("");
  const [proposalDescription, setProposalDescription] = useState("");
  const [isSave, setIsSave] = useState(false);
  const handleDescription = (e, editor) => {
    setProposalDescription(editor.getData());
  };

  useEffect(() => {
    var db = firebase.firestore();
    var cityRef = db.collection("proposals").doc(props.match.params.id);

    cityRef.get().then(doc => {
      if (doc.exists) {
        const allProposal = doc.data();

        console.log(allProposal);
        if (allProposal && allProposal) {
          const baseColl = allProposal;
          setProposalTitle(baseColl.proposalTitle);
          setProposalDescription(baseColl.proposalDescription);
        }
      }
    });
  }, []);

  const onSubmit = e => {
    var db = firebase.firestore();
    var cityRef = db.collection("proposals").doc(props.match.params.id);

    var setWithMerge = cityRef.set(
      {
        proposalTitle: proposalTitle,
        proposalDescription: proposalDescription
      },
      { merge: true }
    );
    setIsSave(true);
  };
  const rendorFlashMessage = e => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={e => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };
  return (
    <>
      {rendorFlashMessage()}
      <br />
      <div className="container">
        <div className="row">
          {/* <div className="col-md-12"> */}
            <div className="edit_proposal">
              <h2>Edit Proposal</h2>
              <b>Add anything you need to this proposal. Proposals are desgined to be free-form and flexible.</b>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="container bg-light-aman">
        <div className="row">
          <div className="col-md-7">
            <div className="form-group">
              <label for="title">
                <b>Title:</b>
              </label>
              <input
                type="text"
                className="form-control input_edit"
                name="proposal_title"
                value={proposalTitle}
                onChange={e => setProposalTitle(e.target.value)}
                placeholder="Title"
              />
            </div>
            <Form>
              <Form.Row>
                <Form.Group>
                  <Form.Label>
                    <b>Description</b>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={handleDescription}
                    name="proposalDescription"
                    data={proposalDescription}
                    config={ckeditor_config}
                    value={proposalDescription}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
            <button className="marginButtonBig_proposal" onClick={onSubmit}>
              Add Proposal
            </button>
          </div>
          <div className="col-md-5">
            <br />
            <h3>About Proposals</h3>
            <p>
              Proposals are desigined to be built from a set of templates.
              Depending on which company or association you work with you may
              have access to hundereds of templates. For example if you are
              piching European Honeymoon to a client you can include your
              preexsiting templates for Paris, Rome, and Madrid.
            </p>
            <button class="btn">
              <Link to={"/proposals"}><i class="fa fa-eye"></i></Link>&nbsp;Preview and Generate PDF
            </button>
            <h5>Description</h5>
            <p>
              This description field is designed to summerize the entire trip.
              Some companies put a letter from the president of the company here
              as well.
            </p>
            <p class="text-danger">
              You do not need to put your contact information in this section as
              it will appear on the footer of each page. Remember that writing
              in all-caps is considered extremely un-professional in any
              context.
            </p>
            <p class="text-success">
              This interface is desigined to be quick and simple. If you
              addtional features use the Trip Builder.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(EditProposal);
