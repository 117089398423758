// import { databaseRef } from "../firebase-auth/Config";
import { SET_TRIPS, SET_TRIP_ID } from "../actions/types";
import firebase from "../firebase-auth/Config";

export const fetchTrips = () => async dispatch => {
  const trips = [];
  firebase
    .firestore().collection("Tripss")
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        console.log('action',doc.updateTime);
        // if(doc.data().updatedAt){
        //   console.log('wow',doc.data().updatedAt.toDate())
        // }
        const { title, user_id, endDate } = doc.data();
        trips.push({
          key: doc.id,
          doc, 
          title,
          user_id,
          endDate,
          createdAt:doc.data().createdAt.toDate(),
          updatedAt:doc.data().updatedAt?doc.data().updatedAt.toDate():false
        });
      });
      dispatch({
        type: SET_TRIPS,
        payload: trips
      });
    });
};

export const setTripId = setTripId => async dispatch => {
  dispatch({
    type: SET_TRIP_ID,
    payload: setTripId
  });
};

