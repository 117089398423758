import React, { useEffect, useState } from "react";
import "date-fns";
import { withRouter } from "react-router-dom";

const Preview = ({ trip }) => {
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    if (!trip.activities) return;

    const baseColl =
      Object.prototype.toString.call(trip.activities) === "[object Object]"
        ? [trip.activities]
        : trip.activities;
    setActivity(baseColl);
  }, []);

  var flightdetails;

  if (activity == "") {
    flightdetails = " No Activities";
  } else {
    flightdetails = "Activities Preview";
  }
  return (
    <>
      <p>
        <h4>{flightdetails}</h4>
        <>
          {activity.map((data, index) => (
            <>
              {" "}
              <hr />
              <h5>Activities&nbsp;&nbsp;{[index + 1]}</h5>
              <hr />
              {data ? (
                <div className="row">
                  <div className="col-md-4">
                    <h6> Description</h6>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data.activitiesDescription.Description,
                      }}
                    />{" "}
                    <br />
                  </div>
                  <div className="col-md-4">
                    Start Date: {data.activityDetails.startDate}
                    <br />
                    Start Time: {data.activityDetails.startTime} <br />
                    <h6>Details</h6>
                    Activity Address: {
                      data.activityDetails.activityAddress
                    }{" "}
                    <br />
                    Activity Place: {data.activityDetails.activityPlace} <br />
                    {/* endDate: {data.activityDetails.endDate} <br /> */}
                    {/* endTime: {data.activityDetails.endTime.split("T")[1].split('.')[0]} <br /> */}
                  </div>
                  <div className="col-md-4">
                    <h6>Location</h6>
                    Latitude :{data.mapMarkerLocation.latitude} <br />
                    Longitude: {data.mapMarkerLocation.longitude} <br />
                  </div>
                </div>
              ) : (
                " "
              )}
            </>
          ))}
        </>
      </p>
    </>
  );
};

export default withRouter(Preview);
