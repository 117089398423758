import React, { useEffect, useState, useCallback } from "react";
import { Form, Col, Alert, Row } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";

import { createTransfer } from "../controllers/transfers";
import {
  updateTrip,
  imageUpload,
  useIndexedState,
  FormImage,
  DateTimeField,
  TripImage,
  Field,
} from "../utils";
import {
  dateFromTime,
  stringFromDate,
  timeFromDate,
} from "../../../utils/date-utils";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const AddTransferDetails = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [transfers, setTransfers] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);

  const { withModifyAt, modifyAt, removeAt, push } = useIndexedState(
    transfers,
    setTransfers
  );

  const setPickupLocation = modifyAt((transfer, e) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      pickupLocation: e.target.value,
    },
  }));

  const setDropLocation = modifyAt((transfer, e) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      dropLocation: e.target.value,
    },
  }));

  const setCompanyName = modifyAt((transfer, e) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      companyName: e.target.value,
    },
  }));

  const setContactName = modifyAt((transfer, e) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      contactName: e.target.value,
    },
  }));

  const setContactNumber = modifyAt((transfer, e) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      contactNumber: e.target.value,
    },
  }));

  const setConfirmationNumber = modifyAt((transfer, e) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      confirmationNumber: e.target.value,
    },
  }));

  const setDate = modifyAt((transfer, date) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      selectedDate: stringFromDate(date),
    },
  }));

  const setTime = modifyAt((transfer, time) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      selectedTime: timeFromDate(time),
    },
  }));

  const setReturnTime = modifyAt((transfer, time) => ({
    ...transfer,
    transferDetails: {
      ...transfer.transferDetails,
      returnTime: timeFromDate(time),
    },
  }));

  const setImageFile = modifyAt((transfer, e) => ({
    ...transfer,
    imageFile: e.target.files[0],
  }));

  const removeImage = modifyAt((transfer) => ({
    ...transfer,
    url: null,
  }));

  const upload = withModifyAt((transfer, modify) =>
    imageUpload("transfers", transfer.imageFile).then((url) =>
      modify({ ...transfer, url })
    )
  );

  const addTransfers = push(() => createTransfer(new Date(), minDate));

  useEffect(() => {
    if (!trip.transfers) return;

    setTransfers(trip.transfers);
  }, [setTransfers, setMinDate, setMaxDate, trip.id]);

  const onSubmit = useCallback(() => {
    const newTransfers = transfers.map((x) => ({
      ...x,
      imageFile: null,
    }));

    trip.transfers = newTransfers;
    updateTrip(trip.id, archiveID, {
      transfers: newTransfers,
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  }, [transfers, trip.id, setIsSave]);

  const renderFlashMessage = () => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {renderFlashMessage()}
      {transfers.map((data, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>Transfer Car {index + 1}</h5>
            </div>
            <div className="col-md-6 text-right">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={removeAt(index)}
              >
                Delete
              </Button>
            </div>
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="ml-2">Photo</Form.Label>
                <FormImage
                  name="transferImage"
                  onChange={setImageFile(index)}
                />
                <br />
                <Button
                  onClick={upload(index)}
                  className="btn btn-success btn-sm"
                >
                  Upload
                </Button>
                <Button
                  onClick={removeImage(index)}
                  className="btn btn-danger btn-sm remove"
                >
                  Remove
                </Button>
              </Form.Group>
              <Form.Group as={Col}>
                <TripImage
                  style={{ marginLeft: "-10%" }}
                  src={data.url}
                  alt="Transfer preview"
                  height="130px"
                  width="160px"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Row} className="ml-1">
                <Form.Label>Pickup Location</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ maxWidth: "90%" }}
                  placeholder="Pickup Location"
                  name="pickupLocation"
                  value={data.transferDetails.pickupLocation}
                  onChange={setPickupLocation(index)}
                />
              </Form.Group>
              <Form.Group as={Row} className="ml-1">
                <Form.Label>Drop Location</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ maxWidth: "90%" }}
                  placeholder="Drop Location"
                  name="dropLocation"
                  value={data.transferDetails.dropLocation}
                  onChange={setDropLocation(index)}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <DateTimeField
                dateLabel="Pickup Date"
                date={
                  data.transferDetails.selectedDate
                    ? data.transferDetails.selectedDate
                    : minDate
                }
                setDate={setDate(index)}
                minDate={minDate}
                maxDate={maxDate}
                timeLabel="Pickup Time"
                time={
                  data.transferDetails.selectedTime
                    ? dateFromTime(data.transferDetails.selectedTime)
                    : new Date()
                }
                setTime={setTime(index)}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Return Time"
                  value={
                    data.transferDetails?.returnTime
                      ? dateFromTime(data.transferDetails.returnTime)
                      : new Date()
                  }
                  onChange={setReturnTime(index)}
                />
              </MuiPickersUtilsProvider>
            </Form.Row>

            <Form.Row>
              <Field
                label="Company Name"
                value={data.transferDetails?.companyName}
                onChange={setCompanyName(index)}
              />
              <Field
                label="Confirmation Number"
                value={data.transferDetails?.confirmationNumber}
                onChange={setConfirmationNumber(index)}
              />
            </Form.Row>

            <Form.Row>
              <Field
                label="Contact Name"
                value={data.transferDetails?.contactName}
                onChange={setContactName(index)}
              />
              <Field
                label="Contact Number"
                value={data.transferDetails?.contactNumber}
                onChange={setContactNumber(index)}
              />
            </Form.Row>
          </Form>
        </div>
      ))}
      <button className="marginButtonffor" onClick={addTransfers}>
        Add More
      </button>
      <br />
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Transfer Details
      </button>
    </>
  );
};

export default withRouter(AddTransferDetails);
