import React, { Component } from "react";
import "../../App.css";
import "../header/css/Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Config from "../firebase-auth/Config";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import withFirebaseAuth from "react-auth-firebase";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { TripImage } from "../trips/utils";

class Header extends Component {
  render() {
    const { user, signOut, signInWithGoogle } = this.props;

    return (
      <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand
            style={{
              color: "#fff !important",
              borderRight: " 5px solid red",
              paddingRight: "10px",
            }}
            href="/"
          >
            <TripImage
              src="golfAwayToursLogos/GolfAwayLogoOval.png"
              className="logo"
              alt="logo_image"
            />
            {/* GOLF TJ's App */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown title="Trips" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/Trips">My Trips</NavDropdown.Item>
                <NavDropdown.Item href="/CreateTrip">
                  Add New Trip
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/TripShow">Trip Show</NavDropdown.Item> */}
                <NavDropdown.Divider />
                <NavDropdown.Item href="/Archived-Trips-Went">
                  Archived Trips: Went
                </NavDropdown.Item>
                <NavDropdown.Item href="/Archived-Trips-Didnt-Go">
                  Archived Trips: Didn't Go
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Proposals" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/proposals">
                  My Proposals
                </NavDropdown.Item>
                <NavDropdown.Item href="/proposal_builder">
                  New Proposals
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              {user ? (
                <>
                  {/* <Button>Hello {user.displayName}</Button> */}
                  {user.photoURL !== null ? (
                    <img
                      src={user.photoURL}
                      className="profile_pic"
                      title={user.displayName}
                      alt={user.displayName}
                    />
                  ) : (
                    <img
                      src="https://img.icons8.com/clouds/2x/user.png"
                      alt={user.displayName}
                      title={user.displayName}
                      className="profile_pic"
                    />
                  )}
                </>
              ) : (
                // <img src={}/>
                <Button onClick={signInWithGoogle}>Sign in with Google</Button>
              )}
              {user ? (
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  <Button className="signoutButton" onClick={signOut}>
                    Sign Out
                  </Button>
                </Link>
              ) : (
                <p></p>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
const authConfig = {
  email: {
    verifyOnSignup: false, // Sends verification email to user upon sign up
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
  google: {
    returnUser: true,
    returnAccessToken: true, // Returns an access token as googleAccessToken prop
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
};
export default withFirebaseAuth(Header, Config, authConfig);
