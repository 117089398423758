import React, { useEffect, useState } from "react";
import "date-fns";
import { withRouter } from "react-router-dom";

const Preview = ({ trip }) => {
  const [transfer, setTransfer] = useState([]);

  useEffect(() => {
    if (!trip?.transfers) return;

    setTransfer(trip.transfers);
  }, []);

  var flightdetails;
  if (transfer == "") {
    flightdetails = " no transfer";
  } else {
    flightdetails = "Transfer Preview";
  }

  return (
    <>
      <p>
        {flightdetails}
        {transfer.map((data, index) => (
          <>
            {" "}
            <hr />
            <div className="row">
              <div className="col-md-6">
                <h5>Transfer Car {index + 1}</h5>
              </div>
            </div>
            <hr />
            <br />
            {data ? (
              <div className="row">
                <div className="col-md-4">
                  <h5>Transfer Details</h5>
                  Drop Location:&nbsp;{data.transferDetails.dropLocation}
                  <br />
                  Pickup Location:&nbsp;{data.transferDetails.pickupLocation}
                  <br />
                  Pickup:&nbsp;
                  {data.transferDetails.selectedDate}
                  <br />
                  Pick Time:&nbsp;
                  {data.transferDetails.selectedTime}
                  <br />
                  Return Time:&nbsp;
                  {data.transferDetails?.returnTime}
                  <br />
                  <br />
                  Company Name:&nbsp;
                  {data.transferDetails?.companyName}
                  <br />
                  Contact Name:&nbsp;
                  {data.transferDetails?.contactName}
                  <br />
                  Contact Number:&nbsp;
                  {data.transferDetails?.contactNumber}
                  <br />
                  Confirmation Number:&nbsp;
                  {data.transferDetails?.confirmationNumber}
                  <br />
                </div>
                <div className="col-md-4">
                  <h6>Map Location</h6>
                  Current Latitude:&nbsp;{data.MapLocation.currentLatitude}
                  <br />
                  Current Longitude:&nbsp;{data.MapLocation.currentLongitude}
                  <br />
                  Destination Latitude:&nbsp;
                  {data.MapLocation.destinationLatitude}
                  <br />
                  Destination Longitude:&nbsp;
                  {data.MapLocation.destinationLongitude}
                  <br />
                </div>
                <div className="col-md-4">
                  <h5>Transfer Comments</h5>
                  <p
                    style={{ overflow: "scroll" }}
                    dangerouslySetInnerHTML={{
                      __html: data.transferComments?.comment,
                    }}
                  />
                </div>
              </div>
            ) : (
              " "
            )}
          </>
        ))}
      </p>
    </>
  );
};

export default withRouter(Preview);
