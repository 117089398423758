import { withRouter } from "react-router-dom";
import firebase from "../../firebase-auth/Config";
import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

class MapExp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
    };
  }
  componentDidMount() {
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(this.props.match.params.id);

    cityRef.get().then((doc) => {
      if (doc.exists) {
        const allData = doc.data();
        const baseCall = allData;
        if (allData && allData.dayOverViews) {
          const baseCall = allData;
          baseCall.dayOverViews.map((dayOverView, index) => {
            dayOverView.latitude = parseFloat(dayOverView.latitude);
            dayOverView.longitude = parseFloat(dayOverView.longitude);
            this.setState({
              stores: [
                ...this.state.stores,
                {
                  Id: index + 1,
                  title: dayOverView.OverviewDescription,
                  lat: dayOverView.latitude,
                  lng: dayOverView.longitude,
                },
              ],
            });
          });
        }
        if (allData && allData.hotels) {
          baseCall.hotels.map((hotel, index) => {
            hotel.hotelLat = parseFloat(hotel.hotelLat);
            hotel.hotelLong = parseFloat(hotel.hotelLong);
            this.setState({
              stores: [
                ...this.state.stores,
                {
                  Id: this.state.stores[this.state.stores.length - 1] + 1,
                  title: hotel.hotelName,
                  lat: hotel.hotelLat,
                  lng: hotel.hotelLong,
                },
              ],
            });
          });
        }
        const activities = Object.prototype.toString.call(baseCall.activities) === '[object Object]' ? [baseCall.activities] : baseCall.activities
          activities.map((activity, index) => {
            activity.mapMarkerLocation.latitude = parseFloat(
              activity.mapMarkerLocation.latitude
            );
            activity.mapMarkerLocation.longtitute = parseFloat(
              activity.mapMarkerLocation.longtitute
            );
            this.setState({
              stores: [
                ...this.state.stores,
                {
                  Id: this.state.stores[this.state.stores.length - 1] + 1,
                  title: activity.activityDetails.activityPlace,
                  lat: activity.mapMarkerLocation.latitude,
                  lng: activity.mapMarkerLocation.longtitute,
                },
              ],
            });
          });
          const carRentals = Object.prototype.toString.call(baseCall.carRentals) === '[object Object]' ? [baseCall.carRentals] : baseCall.carRentals
          carRentals.map((carRental, index) => {
            carRental.mapMarkerLocation.latitude = parseFloat(
              carRental.mapMarkerLocation.latitude
            );
            carRental.mapMarkerLocation.longitude = parseFloat(
              carRental.mapMarkerLocation.longitude
            );
            this.setState({
              stores: [
                ...this.state.stores,
                {
                  Id: this.state.stores[this.state.stores.length - 1] + 1,
                  title: carRental.carRentalsDetails.companyName,
                  lat: carRental.mapMarkerLocation.latitude,
                  lng: carRental.mapMarkerLocation.longitude,
                },
              ],
            });
          });
        const flights = Object.prototype.toString.call(baseCall.flights) === '[object Object]' ? [baseCall.flights] : baseCall.flights
        {flights.map((flight, index) => {
        if (flight.details.airLineName !== "") {
          flight.mapMarkerLocation.destLatitude = parseFloat(
            flight.mapMarkerLocation.destLatitude
          );
          flight.mapMarkerLocation.destLongitude = parseFloat(
            flight.mapMarkerLocation.destLongitude
          );
          this.setState({
            stores: [
              ...this.state.stores,
              {
                Id: this.state.stores[this.state.stores.length - 1] + 1,
                title: flight.details.airLineName,
                lat: flight.mapMarkerLocation.destLatitude,
                lng: flight.mapMarkerLocation.destLongitude,
              },
            ],
          });
        }
      })}
        const resturants = Object.prototype.toString.call(baseCall.resturants) === '[object Object]' ? [baseCall.resturants] : baseCall.resturants
          resturants.map((resturant, index) => {
            resturant.mapMarkerLocation.latitude = parseFloat(
              resturant.mapMarkerLocation.latitude
            );
            resturant.mapMarkerLocation.longtitude = parseFloat(
              resturant.mapMarkerLocation.longtitude
            );
            this.setState({
              stores: [
                ...this.state.stores,
                {
                  Id: this.state.stores[this.state.stores.length - 1] + 1,
                  title: resturant.resturantsDetails.resturantName,
                  lat: resturant.mapMarkerLocation.latitude,
                  lng: resturant.mapMarkerLocation.longtitude,
                },
              ],
            });
          });
        const trains = Object.prototype.toString.call(baseCall.trains) === '[object Object]' ? baseCall.trains : [baseCall.trains]
        
          baseCall.trains.MapLocation.currentLatitude = parseFloat(
            baseCall.trains.MapLocation.currentLatitude
          );
          baseCall.trains.MapLocation.currentLongitude = parseFloat(
            baseCall.trains.MapLocation.currentLongitude
          );
          this.setState({
            stores: [
              ...this.state.stores,
              {
                Id: this.state.stores[this.state.stores.length - 1] + 1,
                title: baseCall.trains.trainDetails.companyName,
                lat: baseCall.trains.MapLocation.currentLatitude,
                lng: baseCall.trains.MapLocation.currentLongitude,
              },
            ],
          });
        
          const transfers = Object.prototype.toString.call(baseCall.transfers ||baseCall.transfer) === '[object Object]' ? [baseCall.transfers ||baseCall.transfer] : (baseCall.transfers ||baseCall.transfer)
          transfers.map((transfer, index) => {
            transfer.MapLocation.currentLatitude = parseFloat(
              transfer.MapLocation.currentLatitude
            );
            transfer.MapLocation.currentLongitude = parseFloat(
              transfer.MapLocation.currentLongitude
            );
            this.setState({
              stores: [
                ...this.state.stores,
                {
                  Id: this.state.stores[this.state.stores.length - 1] + 1,
                  title: transfer.transferComments.companyName,
                  lat: transfer.MapLocation.currentLatitude,
                  lng: transfer.MapLocation.currentLongitude,
                },
              ],
            });
          });
      }
    });
  }

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return (
        <Marker
          key={index}
          id={index}
          position={{
            lat: store.lat,
            lng: store.lng,
          }}
          onClick={() => alert(store.title)}
        />
      );
    });
  };
  render() {
    return (
      <div style={{ height: "75vh", width: "100%" }}>
        <Map
          google={this.props.google}
          zoom={2}
          initialCenter={{
            lat:
              this.state.stores[0] === undefined
                ? ""
                : this.state.stores[0].lat,
            lng:
              this.state.stores[0] === undefined
                ? ""
                : this.state.stores[0].lng,
          }}
        >
          {this.displayMarkers()}
        </Map>
      </div>
    );
  }
}

export default withRouter(
  GoogleApiWrapper({
    apiKey: "AIzaSyB5r9bNY12TcAE68GYlvF5Wtw034KzPmDc",
  })(MapExp)
);
