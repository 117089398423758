// import { databaseRef } from "../firebase-auth/Config";
import { SET_PROPOSALS, SET_PROPOSAL_ID } from "../actions/types";
import firebase from "../firebase-auth/Config";
export const fetchProposals = () => async dispatch => {
  const proposals = [];
  firebase
    .firestore()
    .collection("proposals")
    .get()
    .then(querySnapshot => {
      // ;
      querySnapshot.forEach(doc => {
        
        // const { title, startDate, endDate } = doc.data();
        proposals.push({
          key: doc.id,
          proposalDescribtion:doc.data().proposalDescription, // DocumentSnapshot
          title:doc.data().proposalTitle         
        });
      });
      dispatch({
        type: SET_PROPOSALS,
        payload: proposals
      });
    });
};

export const setProposalId = setTripId => async dispatch => {
  dispatch({
    type: SET_PROPOSAL_ID,
    payload: setTripId
  });
};

