//-------------------------------------- OLD CODE ARRANGE BY CATEGORIES -----------------------------------------------------------------------
// import React, { useEffect, useState } from "react";
// import "../../App.css";
// import Calendar from "../Calender";
// import Map from "./map/Map";
// import NewTrip from "../../Newtrip";
// // import { withRouter, Link } from "react-router-dom";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import { Container, Row, Form, Col, Button } from "react-bootstrap";
// import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";
// import firebase from "../firebase-auth/Config";
// import { withRouter, Link } from "react-router-dom";
// const Dashboard = (props) => {
//   const [startDate, setStartDate] = useState(new Date().toDateString());
//   const [endDate, setEndDate] = useState(new Date().toDateString());
//   const [trip, setAllData] = useState("");
//   const [cal, setCal] = useState("");
//   const [activities, setActivities] = useState("");
//   const [carRentals, setCarRentals] = useState("");
//   const [dayOverViews, setDayOverViews] = useState("");
//   const [flights, setFlights] = useState("");
//   const [resturants, setResturants] = useState("");
//   const [trains, setTrains] = useState("");
//   const [transfers, setTransfers] = useState("");
//   const [hotels, setHotels] = useState("");
//   const handleDateChange = (date) => {
//     setStartDate(date.toDateString());
//   };
//   const handleendDateChange = (date) => {
//     setEndDate(date.toDateString());
//   };
//   const handlerCal = (val, e) => {
//     e.preventDefault();
//     setCal(val);
//   };
//   useEffect(() => {
//     var db = firebase.firestore();
//     var cityRef = db.collection("Tripss").doc(match.params.id);

//     cityRef.get().then((doc) => {
//       if (doc.exists) {
//         const trip = doc.data();
//         const activity =
//           Object.prototype.toString.call(trip.activities) ===
//           "[object Object]"
//             ? [trip.activities]
//             : trip.activities;
//         setActivities(activity);

//         const carRental =
//           Object.prototype.toString.call(trip.carRentals) ===
//           "[object Object]"
//             ? [trip.carRentals]
//             : trip.carRentals;
//         setCarRentals(carRental);
//         const dayOverView =
//           Object.prototype.toString.call(trip.dayOverViews) ===
//           "[object Object]"
//             ? [trip.dayOverViews]
//             : trip.dayOverViews;
//         setDayOverViews(dayOverView);
//         const flight =
//           Object.prototype.toString.call(trip.flights) === "[object Object]"
//             ? [trip.flights]
//             : trip.flights;
//         setFlights(flight);
//         const resturant =
//           Object.prototype.toString.call(trip.resturants) ===
//           "[object Object]"
//             ? [trip.resturants]
//             : trip.resturants;
//         setResturants(resturant);
//         const train =
//           Object.prototype.toString.call(trip.trains) === "[object Object]"
//             ? trip.trains
//             : [trip.trains];
//         setTrains(train);
//         const transfer =
//           Object.prototype.toString.call(trip.transfers) ===
//           "[object Object]"
//             ? [trip.transfers]
//             : trip.transfers;
//         setTransfers(transfer);
//         const hotel =
//           Object.prototype.toString.call(trip.hotels || trip.hotelInfomation) === "[object Object]"
//             ? [trip.hotels || trip.hotelInfomation]
//             : (trip.hotels || trip.hotelInfomation);
//         setHotels(hotel);
//         if (trip && trip) {
//           setAllData(trip);
//           const baseColl = trip;
//           setStartDate(baseColl.startDate);
//           setEndDate(baseColl.endDate);
//         }
//       }
//     });
//   }, []);

//   console.log(activities, "Check This Data");
//   return (
//     <>
//       <div className="row">
//         <div className="col-md-8">
//           <Calendar props={props} />
//         </div>
//         <div className="col-md-4">
//           <Form.Row>
//             <Form.Group>
//               <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                 <KeyboardDatePicker
//                   margin="normal"
//                   id="date-picker-dialog"
//                   label="Start Date"
//                   format="MM/dd/yyyy"
//                   value={startDate}
//                   minDate={startDate}
//                   maxDate={endDate}
//                   selected={startDate}
//                   onChange={handleDateChange}
//                   KeyboardButtonProps={{
//                     "aria-label": "change date",
//                   }}
//                 />
//               </MuiPickersUtilsProvider>
//             </Form.Group>
//             <Form.Group>
//               <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                 <KeyboardDatePicker
//                   margin="normal"
//                   id="date-picker-dialog"
//                   label="End Date"
//                   format="MM/dd/yyyy"
//                   value={endDate}
//                   selected={endDate}
//                   minDate={startDate}
//                   maxDate={endDate}
//                   onChange={handleendDateChange}
//                   KeyboardButtonProps={{
//                     "aria-label": "change date",
//                   }}
//                 />
//               </MuiPickersUtilsProvider>
//             </Form.Group>
//           </Form.Row>
//           <div
//             className="card bg-white"
//             style={{ overflow: "scroll", height: "300px" }}
//           >
//             <div className="card title">
//               <span>Itinerary by Date & Time</span>
//             </div>
//             <div className="container">
//               <div className="" style={{ textAlign: "center" }}>
//                 {dayOverViews ? (
//                   <>
//                     {dayOverViews.map((dayOverView, index) => (
//                       <>
//                         {dayOverView.OverviewDescription !== "" ? (
//                           <>
//                             <div
//                               className="row"
//                               style={{ textAlign: "center" }}
//                             >
//                               <div className="col-md-3 edit_button">
//                                 <button
//                                   className="edit_button"
//                                   onClick={(e) => handler("over", e)}
//                                 >
//                                   <span>
//                                     <i className="fa fa-pencil"></i>&nbsp;Edit
//                                   </span>
//                                 </button>
//                               </div>
//                               <div className="col-md-9 edit_content">
//                                 <span>
//                                   <i className="fa fa-sun-o"></i>
//                                   {dayOverView.OverviewDescription}
//                                   <br />
//                                   {dayOverView.startDate}
//                                   <br />
//                                   {dayOverView.endDate}
//                                 </span>
//                             <hr />
//                               </div>
//                             </div>
//                           </>
//                         ) : (
//                           ""
//                         )}
//                       </>
//                     ))}
//                   </>
//                 ) : (
//                   ""
//                 )}
//                 {flights ? (
//                   <>
//                   {flights.map((flight, index) =>(
//                     <>
//                     {flight.details.airLineName !== "" ? (
//                       <div className="row" style={{ textAlign: "center" }}>
//                         <div className="col-md-3 edit_button">
//                           <button
//                             className="edit_button"
//                             onClick={(e) => handler("flight", e)}
//                           >
//                             <span>
//                               <i className="fa fa-pencil"></i>&nbsp;Edit
//                             </span>
//                           </button>
//                         </div>
//                         <div className="col-md-9 edit_content">
//                           <span>
//                             <i className="fa fa-sun-o"></i>
//                             {flight.details.airLineName}
//                             <br />
//                             {flight.airports.departureDate}
//                             <br />
//                             {flight.airports.arrivalDate}
//                           </span>
//                         <hr />
//                         </div>
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                     </>
//                     ))}{" "}
//                   </>
//                 ) : (
//                   ""
//                 )}
//                 {hotels ? (
//                   hotels.map((hotel, index) =>(
//                   <>
//                     {hotel.hotelName !== "" ? (
//                       <div className="row" style={{ textAlign: "center" }}>
//                         <div className="col-md-3 edit_button">
//                           <button
//                             className="edit_button"
//                             onClick={(e) => handler("Hotel", e)}
//                           >
//                             <span>
//                               <i className="fa fa-pencil"></i>&nbsp;Edit
//                             </span>
//                           </button>
//                         </div>
//                         <div className="col-md-9 edit_content">
//                           <span>
//                             <i className="fa fa-sun-o"></i>
//                             {hotel.hotelName}
//                             <br />
//                             {hotel.hotelCheckin}
//                             <br />
//                             {hotel.hotelCheckout}
//                           </span>
//                         <hr />
//                         </div>
//                       </div>
//                     ) : (
//                       ""
//                     )}{" "}
//                   </>
//                   ))
//                 ) : (
//                   ""
//                 )}
//                 {activities ? (
//                   <>
//                     {activities.map((activity, index) => (
//                       <>
//                         {activity.activityDetails.activityPlace !== "" ? (
//                           <div className="row" style={{ textAlign: "center" }}>
//                             <div className="col-md-3 edit_button">
//                               <button
//                                 className="edit_button"
//                                 onClick={(e) => handler("Activity", e)}
//                               >
//                                 <span>
//                                   <i className="fa fa-pencil"></i>&nbsp;Edit
//                                 </span>
//                               </button>
//                             </div>
//                             <div className="col-md-9 edit_content">
//                               <span>
//                                 <i className="fa fa-sun-o"></i>
//                                 {activity.activityDetails.activityPlace}
//                                 <br />
//                                 {activity.activityDetails.startDate}
//                                 <br />
//                                 {activity.activityDetails.endDate}
//                               </span>
//                             <hr />
//                             </div>
//                           </div>
//                         ) : (
//                           ""
//                         )}
//                       </>
//                     ))}{" "}
//                   </>
//                 ) : (
//                   ""
//                 )}
//                 {transfers ? (
//                   <>
//                   {transfers.map((transfer, index) => (<>
//                     {transfer.transferComments.companyName !== "" ? (
//                       <div className="row" style={{ textAlign: "center" }}>
//                         <div className="col-md-3 edit_button">
//                           <button className="edit_button" onClick={(e) => handler("Transfer",e)} >
//                             <span>
//                               <i className="fa fa-pencil"></i>&nbsp;Edit
//                             </span>
//                           </button>
//                         </div>
//                         <div className="col-md-9 edit_content">
//                           <span>
//                             <i className="fa fa-sun-o"></i>
//                             {transfer.transferComments.companyName}
//                             <br />
//                             {transfer.transferDetails.selectedDate}
//                             <br />
//                           </span>
//                         <hr />
//                         </div>
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </>))}
//                     {" "}
//                   </>
//                 ) : (
//                   ""
//                 )}
//                 {/* {} */}
//                 {carRentals ? (
//                   <>
//                   {carRentals.map((carRental, index) => (<>
//                     {carRental.carRentalsDetails.companyName !== "" ? (
//                       <div className="row" style={{ textAlign: "center" }}>
//                         <div className="col-md-3 edit_button">
//                           <button className="edit_button" onClick={(e) => handler("Rentalcar",e)} >
//                             <span>
//                               <i className="fa fa-pencil"></i>&nbsp;Edit
//                             </span>
//                           </button>
//                         </div>
//                         <div className="col-md-9 edit_content">
//                           <span>
//                             <i className="fa fa-sun-o"></i>
//                             {carRental.carRentalsDetails.companyName}
//                             <br />
//                             {carRental.carRentalsDetails.startDate}
//                             <br />
//                             {carRental.carRentalsDetails.endDate}
//                           </span>
//                         <hr />
//                         </div>
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                     </>
//                   ))}
//                   {" "}
//                   </>
//                 ) : (
//                   ""
//                 )}
//                 {trains ? (
//                   <>
//                     {trains.trainDetails.companyName !== "" ? (
//                       <div className="row" style={{ textAlign: "center" }}>
//                         <div className="col-md-3 edit_button">
//                           <button
//                             className="edit_button"
//                             onClick={(e) => handler("Train", e)}
//                           >
//                             <span>
//                               <i className="fa fa-pencil"></i>&nbsp;Edit
//                             </span>
//                           </button>
//                         </div>
//                         <div className="col-md-9 edit_content">
//                           <span>
//                             <i className="fa fa-sun-o"></i>
//                             {trains.trainDetails.companyName}
//                             <br />
//                             {trains.trainDetails.departureDate}
//                             <br />
//                             {trains.trainDetails.arrivalDate}
//                           </span>
//                         <hr />
//                         </div>
//                       </div>
//                     ) : (
//                       ""
//                     )}{" "}
//                   </>
//                 ) : (
//                   ""
//                 )}
//               </div>
//             </div>
//             {/* </div> */}
//           </div>
//         </div>
//       </div>
//       <br />
//       <div className="row">
//         <div className="col-md-12">
//           <Map />
//         </div>
//       </div>
//       <br />
//     </>
//   );
// };

// export default withRouter(Dashboard);

//------------------------------------------------ NEW CODE ARRANGE BY DATE ------------------------------------------------

import React, { useEffect, useState } from "react";
import "../../App.css";
import Calendar from "../Calender";
import Map from "./map/Map";
import NewTrip from "../../Newtrip";
// import { withRouter, Link } from "react-router-dom";
import { stringToNumberMonth, sortSchedules } from "../../Helpers";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import firebase from "../firebase-auth/Config";
import { withRouter, Link } from "react-router-dom";
import { stringFromDate } from "../../utils/date-utils";

const Dashboard = ({ handler, trip }) => {
  const [startDate, setStartDate] = useState(new Date().toDateString());
  const [endDate, setEndDate] = useState(new Date().toDateString());
  const [cal, setCal] = useState("");
  const [activities, setActivities] = useState("");
  const [carRentals, setCarRentals] = useState("");
  const [dayOverViews, setDayOverViews] = useState("");
  const [flights, setFlights] = useState("");
  const [resturants, setResturants] = useState("");
  const [trains, setTrains] = useState("");
  const [transfers, setTransfers] = useState("");
  const [hotels, setHotels] = useState("");
  const sortedItenary = [];

  const handleDateChange = (date) => {
    setStartDate(stringFromDate(date));
  };

  const handleendDateChange = (date) => {
    setEndDate(stringFromDate(date));
  };

  const handlerCal = (val, e) => {
    e.preventDefault();
    setCal(val);
  };

  const sortAscending = (itnerary) => {
    let temp = 0;
    for (let k = 0; k < itnerary.length; k++) {
      for (let l = k + 1; l < itnerary.length; l++) {
        if (itnerary[k].date > itnerary[l].date) {
          temp = itnerary[k];
          itnerary[k] = itnerary[l];
          itnerary[l] = temp;
        }
      }
    }
    return itnerary;
  };

  useEffect(() => {
    console.log("CALENDULA----" + JSON.stringify(trip));

    const activity =
      Object.prototype.toString.call(trip.activities) === "[object Object]"
        ? [trip.activities]
        : trip.activities;
    setActivities(activity);

    const carRental =
      Object.prototype.toString.call(trip.carRentals) === "[object Object]"
        ? [trip.carRentals]
        : trip.carRentals;
    setCarRentals(carRental);
    const dayOverView =
      Object.prototype.toString.call(trip.dayOverViews) === "[object Object]"
        ? [trip.dayOverViews]
        : trip.dayOverViews;
    setDayOverViews(dayOverView);
    const flight =
      Object.prototype.toString.call(trip.flights) === "[object Object]"
        ? [trip.flights]
        : trip.flights;
    setFlights(flight);
    const resturant =
      Object.prototype.toString.call(trip.resturants) === "[object Object]"
        ? [trip.resturants]
        : trip.resturants;
    setResturants(resturant);
    const train =
      Object.prototype.toString.call(trip.trains) === "[object Object]"
        ? trip.trains
        : [trip.trains];
    setTrains(train);
    const transfer =
      Object.prototype.toString.call(trip.transfers) === "[object Object]"
        ? [trip.transfers]
        : trip.transfers;
    setTransfers(transfer);
    const hotel =
      Object.prototype.toString.call(trip.hotels || trip.hotelInfomation) ===
      "[object Object]"
        ? [trip.hotels || trip.hotelInfomation]
        : trip.hotels || trip.hotelInfomation;
    setHotels(hotel);

    setStartDate(trip.startDate);
    setEndDate(trip.endDate);
  }, [trip]);

  console.log(activities, "Check This Data");
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <Calendar trip={trip} />
        </div>
        <div className="col-md-4">
          <Form.Row>
            <Form.Group>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Start Date"
                  format="MM/dd/yyyy"
                  value={startDate}
                  minDate={startDate}
                  maxDate={endDate}
                  selected={startDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
            <Form.Group>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="End Date"
                  format="MM/dd/yyyy"
                  value={endDate}
                  selected={endDate}
                  minDate={startDate}
                  maxDate={endDate}
                  onChange={handleendDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Form.Group>
          </Form.Row>
          <div
            className="card bg-white"
            style={{ overflow: "scroll", height: "300px" }}
          >
            <div className="card title">
              <span>Itinerary by Date & Time</span>
            </div>
            <div className="container">
              <div className="" style={{ textAlign: "center" }}>
                {dayOverViews ? (
                  <>
                    {dayOverViews.map((dayOverView, index) => (
                      <>
                        {dayOverView.OverviewDescription !== "" ? (
                          <>
                            <div
                              className="row"
                              style={{ textAlign: "center" }}
                            >
                              <div className="col-md-3 edit_button">
                                <button
                                  className="edit_button"
                                  onClick={(e) => handler("over", e)}
                                >
                                  <span>
                                    <i className="fa fa-pencil"></i>&nbsp;Edit
                                  </span>
                                </button>
                              </div>
                              <div className="col-md-9 edit_content">
                                <span>
                                  <i className="fa fa-sun-o"></i>
                                  {dayOverView.OverviewDescription}
                                  <br />
                                  {dayOverView.startDate}
                                  <br />
                                  {dayOverView.endDate}
                                </span>
                                <hr />
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
                {flights ? (
                  <>
                    {flights.map((flight, index) => (
                      <>
                        {flight.details.airLineName !== "" ? (
                          <div className="row" style={{ textAlign: "center" }}>
                            <div className="col-md-3 edit_button">
                              <button
                                className="edit_button"
                                onClick={(e) => handler("flight", e)}
                              >
                                <span>
                                  <i className="fa fa-pencil"></i>&nbsp;Edit
                                </span>
                              </button>
                            </div>
                            <div className="col-md-9 edit_content">
                              <span>
                                <i className="fa fa-sun-o"></i>
                                {flight.details.airLineName}
                                <br />
                                {flight.airports.departureDate}
                                <br />
                                {flight.airports.arrivalDate}
                              </span>
                              <hr />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}{" "}
                  </>
                ) : (
                  ""
                )}
                {hotels
                  ? hotels.map((hotel, index) => (
                      <>
                        {hotel.hotelName !== "" ? (
                          <div className="row" style={{ textAlign: "center" }}>
                            <div className="col-md-3 edit_button">
                              <button
                                className="edit_button"
                                onClick={(e) => handler("Hotel", e)}
                              >
                                <span>
                                  <i className="fa fa-pencil"></i>&nbsp;Edit
                                </span>
                              </button>
                            </div>
                            <div className="col-md-9 edit_content">
                              <span>
                                <i className="fa fa-sun-o"></i>
                                {hotel.hotelName}
                                <br />
                                {hotel.hotelCheckin}
                                <br />
                                {hotel.hotelCheckout}
                              </span>
                              <hr />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}{" "}
                      </>
                    ))
                  : ""}
                {activities ? (
                  <>
                    {activities.map((activity, index) => (
                      <>
                        {activity.activityDetails.activityPlace !== "" ? (
                          <div className="row" style={{ textAlign: "center" }}>
                            <div className="col-md-3 edit_button">
                              <button
                                className="edit_button"
                                onClick={(e) => handler("Activity", e)}
                              >
                                <span>
                                  <i className="fa fa-pencil"></i>&nbsp;Edit
                                </span>
                              </button>
                            </div>
                            <div className="col-md-9 edit_content">
                              <span>
                                <i className="fa fa-sun-o"></i>
                                {activity.activityDetails.activityPlace}
                                <br />
                                {activity.activityDetails.startDate}
                                <br />
                                {activity.activityDetails.endDate}
                              </span>
                              <hr />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}{" "}
                  </>
                ) : (
                  ""
                )}
                {transfers ? (
                  <>
                    {transfers.map((transfer, index) => (
                      <>
                        {transfer.transferComments.companyName !== "" ? (
                          <div className="row" style={{ textAlign: "center" }}>
                            <div className="col-md-3 edit_button">
                              <button
                                className="edit_button"
                                onClick={(e) => handler("Transfer", e)}
                              >
                                <span>
                                  <i className="fa fa-pencil"></i>&nbsp;Edit
                                </span>
                              </button>
                            </div>
                            <div className="col-md-9 edit_content">
                              <span>
                                <i className="fa fa-sun-o"></i>
                                {transfer.transferComments.companyName}
                                <br />
                                {transfer.transferDetails.selectedDate}
                                <br />
                              </span>
                              <hr />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}{" "}
                  </>
                ) : (
                  ""
                )}
                {/* {} */}
                {carRentals ? (
                  <>
                    {carRentals.map((carRental, index) => (
                      <>
                        {carRental.carRentalsDetails.companyName !== "" ? (
                          <div className="row" style={{ textAlign: "center" }}>
                            <div className="col-md-3 edit_button">
                              <button
                                className="edit_button"
                                onClick={(e) => handler("Rentalcar", e)}
                              >
                                <span>
                                  <i className="fa fa-pencil"></i>&nbsp;Edit
                                </span>
                              </button>
                            </div>
                            <div className="col-md-9 edit_content">
                              <span>
                                <i className="fa fa-sun-o"></i>
                                {carRental.carRentalsDetails.companyName}
                                <br />
                                {carRental.carRentalsDetails.startDate}
                                <br />
                                {carRental.carRentalsDetails.endDate}
                              </span>
                              <hr />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}{" "}
                  </>
                ) : (
                  ""
                )}
                {trains ? (
                  <>
                    {trains.trainDetails.companyName !== "" ? (
                      <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-md-3 edit_button">
                          <button
                            className="edit_button"
                            onClick={(e) => handler("Train", e)}
                          >
                            <span>
                              <i className="fa fa-pencil"></i>&nbsp;Edit
                            </span>
                          </button>
                        </div>
                        <div className="col-md-9 edit_content">
                          <span>
                            <i className="fa fa-sun-o"></i>
                            {trains.trainDetails.companyName}
                            <br />
                            {trains.trainDetails.departureDate}
                            <br />
                            {trains.trainDetails.arrivalDate}
                          </span>
                          <hr />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <Map />
        </div>
      </div>
      <br />
    </>
  );
};

export default withRouter(Dashboard);
