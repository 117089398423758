import React, { useRef, useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import firebase from "../firebase-auth/Config";
import "./css/proposal.css";
const ProposalShow = props => {
  const [proposalTitle, setProposalTitle] = useState("");
  const [proposalDescription, setProposalDescription] = useState("");

  useEffect(() => {
    var db = firebase.firestore();
    var cityRef = db.collection("proposals").doc(props.match.params.id);

    cityRef.get().then(doc => {
      if (doc.exists) {
        const allData = doc.data();
        // console.log(allData.tripImages.tripImageURL);
        if (allData && allData) {
          const baseColl = allData;
          console.log(baseColl, "sdsdsdsdsdsdds");
          setProposalTitle(baseColl.proposalTitle);
          setProposalDescription(baseColl.proposalDescription);
        }
      }
    });
  }, []);
console.log(props, "I am Props")
  return (
    <>
      <br />
      <div className="container">
        <div className="row">
          <div className="edit_proposal">
            <h2>Proposal</h2>
            <b>
              Make an awesome proposal in seconds with our proposal building
              tool.
            </b>
          </div>
        </div>
      </div>
      <div className="container bg-light-aman">
        <div className="row">
          <div className="col-md-7">
            <div class="btn-group">
              <Link to={"/proposals"}>
                <button class="btn-view-all">
                  <i class="fa fa-eye"></i>&nbsp;All Proposals
                </button>
              </Link>
              <Link to={"#"}>
                <button class="btn-Generate">
                  <i class="fa fa-print"></i>&nbsp;Generate PDF
                </button>
              </Link>
              <Link to={`/proposal_builder/${props.match.params.id}`}>
                <button class="btn-edit">
                  <i class="fa fa-pencil-square-o"></i>&nbsp;Edit Proposal
                </button>
              </Link>
            </div>
            <br />
            <br />
            <div className="card bg-white-card">
              <h2>{proposalTitle}</h2>
              <br />
              <h4>
                Created Exclusively For You By TJ Rule <br />
                At Golf Away Tours
              </h4>
            </div>
            <br />
            <div className="card bg-white-card">
              <p
                dangerouslySetInnerHTML={{
                  __html: proposalDescription
                }}
              />
            </div>
          </div>
          <div className="col-md-5">
            <br />
            <h3>About Building Proposals</h3>
            <p>
              If everything looks fine click the button below to &nbsp;&nbsp;
              <button class="btn">
                <i class="fa fa-print"></i>&nbsp;Generate PDF
              </button>
              <br />
              If you need to make a few changes fine click the
              button.&nbsp;&nbsp;{" "}
              <button class="btn1">
                <i class="fa fa-pencil-square-o"></i>&nbsp;Edit Proposal
              </button>
            </p>
            <p>
              The preview to the rights contains all the information that your
              proposal with have. The only exceptions is the footer of each page
              which will contain your contact information as well as your
              company logo which will appear in the header of each page.
            </p>
            <h5>Page Header</h5>
            <p>
              The page headers will contain this logo.
              <img
                className="img-background"
                style={{
                  position: "relative",
                  height: "100px",
                  marginRight: "20px"
                }}
                src="https://www.filepicker.io/api/file/rZOfHdkbTgKfE9KcxwL6"
                alt="logo_image"
              />
            </p>
            <h6>Page Footer</h6>
            <p>
              Page Footer The page footer will contain the following
              information. <br />
              Your Company Name: Golf Away Tours <a href="#">Edit</a>
              <br />
              Your Name: TJ Rule <a href="">Edit</a>
              <br />
              Your Phone Number: 1-647-406-6016 <a href="#">Edit</a>
              <br />
              Your Email: contact@golfawaytours.com <a href="">Edit</a>
            </p>
            <p class="text-warning">
              If you were one of the Tripwing users who were initially confused
              by the term first name, and thought that meant put in your email
              or company name you can change that by clicking here and editing
              your personal profile.
            </p>
            <p className="text-danger">
              Writing in all-caps is considered extremely un-professional in any
              context.
            </p>
            <p class="text-success">
              This interface is desigined to be quick and simple. If you
              addtional features feel free to use the Trip Builder.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(ProposalShow);
