import { combineReducers } from 'redux'
import tripsReducer from './tripsReducer'
import proposalReducer from './proposalsReducer';
import userReducer from './usersReducer';


export default combineReducers({
      trips: tripsReducer,
      proposals: proposalReducer,
    users:userReducer
  })