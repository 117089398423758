import React, { useState } from "react";
import "date-fns";
import { Link, withRouter } from "react-router-dom";
import FlightDetails from "./FlightDetails";
import Plane from "./Plane";
import AirportsDetails from "./AirportsDetails";
import MarkermapLocation from "./MarkermapLocation";
import Preview from "./Preview";

const Flights = ({ trip }) => {
  const [fightDetailsFill, setfightDetailsFill] = useState("Details");

  const renderElement = (fightDetailsFill) => {
    if (fightDetailsFill === "Details") return <FlightDetails trip={trip} />;
    if (fightDetailsFill === "Airports") return <AirportsDetails trip={trip} />;
    if (fightDetailsFill === "Plane") return <Plane trip={trip} />;
    if (fightDetailsFill === "Location")
      return <MarkermapLocation trip={trip} />;
    if (fightDetailsFill === "Preview") return <Preview trip={trip} />;
  };

  return (
    <>
      <button
        className={
          fightDetailsFill === "Details"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setfightDetailsFill("Details")}
      >
        Details
      </button>
      <button
        className={
          fightDetailsFill === "Airports"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setfightDetailsFill("Airports")}
      >
        Airports
      </button>
      <button
        className={
          fightDetailsFill === "Plane"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setfightDetailsFill("Plane")}
      >
        Plane
      </button>
      <button
        className={
          fightDetailsFill === "Location"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setfightDetailsFill("Location")}
      >
        Map Marker Location
      </button>
      <button
        className={
          fightDetailsFill === "Preview"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setfightDetailsFill("Preview")}
      >
        Preview
      </button>

      <br />
      <br />
      {renderElement(fightDetailsFill)}
    </>
  );
};

export default withRouter(Flights);
