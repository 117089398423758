import React, { useEffect, useState } from "react";
import "date-fns";
import { withRouter } from "react-router-dom";

const Preview = ({ trip }) => {
  const [restaurent, setRestaurent] = useState([]);

  useEffect(() => {
    if (!trip.resturants) return;
    const baseCall =
      Object.prototype.toString.call(trip.resturants) === "[object Object]"
        ? [trip.resturants]
        : trip.resturants;
    setRestaurent(baseCall);
  }, []);

  var flightdetails;
  if (restaurent == "") {
    flightdetails = " No Flights";
  } else {
    flightdetails = "Restaurents Preview";
  }
  return (
    <>
      <p>
        {flightdetails}
        <>
          {restaurent.map((data, index) => (
            <>
              <br />
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <h5>Restaurant {index + 1}</h5>
                </div>
              </div>
              <hr />
              <br />
              {data ? (
                <div className="row">
                  <div className="col-md-4">
                    <h6>Restaurants Details</h6>
                    Address: {data.resturantsDetails.resturantAddress}
                    <br />
                    Name :{data.resturantsDetails.resturantName}
                    <br />
                    selectedDate: {data.resturantsDetails.selectedDate}
                    <br />
                    selectedTime: {data.resturantsDetails.selectedTime}
                    <br />
                  </div>
                  <div className="col-md-4">
                    <h6>mapMarkerLocation</h6>
                    lat: {data.mapMarkerLocation.latitude}
                    <br />
                    long: {data.mapMarkerLocation.longtitude}
                    <br />
                  </div>
                  <div className="col-md-4">
                    <h6>Restaurants Description</h6>
                    {/*  {data.resturantsDescription.description} */}
                    description:{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data.resturantsDescription.description,
                      }}
                    />
                  </div>
                </div>
              ) : (
                "no"
              )}
            </>
          ))}
        </>
      </p>
    </>
  );
};

export default withRouter(Preview);
