import React, { Component } from "react";
import "./css/proposal.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchProposals, setProposalId } from "../actions/proposalAction";
import routes from "../../Constants/routes";
import firebase from "../firebase-auth/Config";

class ProposalsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposals: [],
      loading: false,
      messages: []
    };
  }

  componentDidMount() {
    this.props.actions.fetchProposals();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.proposals) {
      this.setState({ proposals: nextProps.proposals });
    }
  }

  setIdToRedux = id => {
    this.props.actions.setProposalId({ proposal_id: id });
  };

  delete(key) {
    firebase
      .firestore()
      .collection("proposals")
      .doc(key)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        this.props.history.push("/proposals");
      })
      .catch(error => {
        console.error("Error removing document: ", error);
      });
    this.props.actions.fetchProposals();
  }

  conso = x => {
    // console.log("dtattaaatatatata", x);
  };
  render() {
    const { proposals } = this.state;
    console.log(proposals, "Find Me?");

    return (
      <>
        <div className="container">
          <div className="card" style={{ marginTop: "3%" }}>
            <div
              className="container"
              style={{
                color: "white",
                backgroundColor: "black",
                backgroundImage:
                  "url('https://www.tripwing.com/assets/retina_wood-690ee18b4c1d164d14e8f5c9e51281290b1c3b46ad87900f7e46ab9502f91e98.png')"
              }}
            >
              <div
                className="container"
                style={{
                  padding: "10px 15px",
                  fontSize: "16px",
                  fontWeight: "100"
                }}
              >
                <p>Proposals</p>
                <p>
                  {" "}
                  Make an awesome proposal in seconds with our proposal building
                  tool.
                </p>
                <Link to={routes.proposal_builder}>
                  <Button className="trips_button" variant="success">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    Make New Proposals
                  </Button>
                </Link>
              </div>
            </div>
            <div className="container-fluid">
              <div className="panel panel-default">
                <div className="col-md-12 input-group" style={{ margin: "1%" }}>
                  <div className="row">
                    <input type="text" placeholder="Search Proposal" />
                    <button>Search</button>
                  </div>
                </div>
                <br />
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {proposals.map(p => (
                          <>
                            <tr key={p.id}>
                              <td>{p.title}</td>
                              <td>
                                <Button
                                  className="trips_button"
                                  variant="success"
                                >
                                  <i
                                    className="fa fa-print"
                                    aria-hidden="true"
                                  ></i>
                                  Generate PDF
                                </Button>

                                <Link
                                  to={`/proposals/${p.key}`}
                                  // onClick={this.setIdToRedux(trip.key)}
                                >
                                  <Button
                                    className="trips_button"
                                    variant="primary"
                                  >
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                    View Proposal
                                  </Button>
                                </Link>

                                <Link to={`/proposal_builder/${p.key}`}>
                                  <Button
                                    className="trips_button"
                                    variant="info"
                                  >
                                    <i
                                      class="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                    Edit Proposal
                                  </Button>
                                </Link>

                                <Button
                                  className="trips_button"
                                  variant="danger"
                                  onClick={this.delete.bind(this, p.key)}
                                >
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                  Delete Proposal
                                </Button>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  myTrips: state.trips.all_trips,
  proposals: state.proposals.all_proposals
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        fetchProposals,
        setProposalId
      },
      dispatch
    )
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProposalsList);
