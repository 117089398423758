import React, { Component, Fragment } from 'react';
import firebase from '../firebase-auth/Config';

let outerThis = null;
class NewUser extends Component {
  constructor(props) {
    super(props);
    this.shouldUpdate = false;
    this.state = {
      new_user: {
        name: '',
        email: '',
        phone: '',
        active: false,
        roles: {
          admin: false,
          user: false,
        },
      },
      userEmailList:[],
      btnTitle:'Add',
      toEditEmail:''
    };
    this.emailArr = [];
    this.initialState = this.state;
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.addNewuser = this.addNewuser.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.updateState = this.setState.bind(this);
    this.resetFields = this.resetFields.bind(this);
  }

  componentDidMount() {
    this.setState(this.initialState);
    outerThis = this;
  }

  getEmailList = (data) => data.map((item,index) => this.emailArr[index]=item.email) 
  
  componentDidUpdate() {
    if(this.props.preUsers){
      this.getEmailList(this.props.preUsers)
    }
    if (
      this.props.opp == 'update' &&
      this.shouldUpdate == false &&
      this.props.edit_user_id != ''
    ) {
      this.shouldUpdate = true;
      this.fetchUser(this.state, this.updateState);
    }
  }

  handleChange(e) {
    const state = this.state;
    state.new_user[e.target.name] = e.target.value;
    this.setState(state);
  }

  handleCheckbox(e) {
    const state = this.state;
    if (state.new_user[e.target.name] !== undefined) {
      state.new_user[e.target.name] = this.refs[e.target.name].checked;
    } else {
      state.new_user.roles[e.target.name] = this.refs[e.target.name].checked;
    }
    this.setState(state);
  }

  resetFields() {
    const tempState = this.state;
    this.shouldUpdate = false;
    outerThis.props.resetEditUserId();
    // outerThis.edit_user_id = '';
    tempState.new_user = {
      active: false,
      roles: {
        admin: false,
        user: false,
      },
      email: '',
      name: '',
      phone: '',
    };
    this.setState(tempState);
  }

 async addNewuser() {
    console.error("FUNC_FIRE>>>>")
    var db = firebase.firestore();
    if (
      this.state.new_user.name == '' ||
      this.state.new_user.email == '' ||
      this.state.new_user.phone == ''
    ) {
      alert('Please fill all fields');
      return;
    }
    if (this.props.opp == 'update' && this.props.edit_user_id != '') {
      console.error("UPDATING>>>>")
      const updateArr = this.emailArr.filter(item => item !== this.state.toEditEmail)
      if(this.emailArr.length>0 && updateArr.includes(this.state.new_user.email)){
        alert("The Email entered already exists. Please enter a different one");
        const state = this.state;
        this.setState(state);
      }else{
        var theUser = db.collection('VirtualUsers').doc(this.props.edit_user_id);
        console.log('SHOULD UPDATE', this.props.edit_user_id);
        console.log('UPDATED_FEILDS', this.state.new_user);
        if (this.state.new_user.name && this.state.new_user.email && this.state.new_user.phone) {
          theUser
          .set(
            {
              ...this.state.new_user,
            },
            { merge: true }
          )
          .then((docRef) => {
            // console.log('updated', this.state);
            this.setState({btnTitle:"Add"})
            this.resetFields();
            this.props.fetchUsers();
          })
          .catch((error) => {
            console.log('ERROR UPDATING USER', error);
          });
        }else{
          alert("Please fill in all fields")
        }

      }
    } else {
      console.error("ADDING>>>>")
      var self =  this;
        if(this.emailArr.length>0 && this.emailArr.includes(this.state.new_user.email)){
          alert("The Email entered already exists. Please enter a different one");
        }else{
            this.props.fetchUsers();
            
          await firebase.auth().createUserWithEmailAndPassword(this.state.new_user.email,this.state.new_user.phone)
            .then(user => {
              console.log("NEW_USER>>>"+JSON.stringify(user));

              let {new_user} =  this.state;
              new_user['uid'] = user.user.uid
              self.setState(new_user);
                // insert any document you want here
            })
            .catch(function(error) {
              console.log("NEW_USER_ERR>>>>",error);
                // handle error here
            });
            
            // firebase
            //   .auth()
            //   .createUserWithEmailAndPassword(
            //     this.state.new_user.email,
            //     'default-password'
            //   )
            //   .catch(function (error) {
            //     // Handle Errors here.
            //     var errorCode = error.code;
            //     var errorMessage = error.message;
            //     // ...
            //   });
            db.collection('VirtualUsers')
              .add({
                ...this.state.new_user,
              })
              .then((docRef) => {
                this.resetFields();
                this.props.fetchUsers();
              })
              .catch((error) => {
                console.log('ERROR UPDATING USER', error);
              });
          }

  }}

  fetchUser(state, updateState) {
    var db = firebase.firestore();
    this.edit_user_id = this.props.edit_user_id;
    var theUser = db.collection('VirtualUsers').doc(this.props.edit_user_id);
    theUser
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const tempState = state;
          tempState.btnTitle = "Save" 
          tempState.new_user['active'] = doc.data().active;
          tempState.new_user['roles']['admin'] = doc.data().roles.admin;
          tempState.new_user['email'] = doc.data().email;
          tempState.new_user['name'] = doc.data().name;
          tempState.new_user['phone'] = doc.data().phone;
          updateState(tempState);
          updateState({toEditEmail:doc.data().email})
          console.log('Document updated', state);
        } else {
          console.log('No such document!');
        }
      })
      .catch(function (error) {
        console.log('Error getting document:', error);
      });
  }

  render() {
    return (
      <div className='contents-container row'>
        <div className='new-user-form col-12'>
          <div className='col-12 form-group'>
            <label className=''>
              <b>Name</b>
            </label>
            <input
              name='name'
              className='col-12 form-control'
              placeholder=''
              value={this.state.new_user.name}
              onChange={this.handleChange}
            />
          </div>
          <div className='col-12 form-group'>
            <label className=''>
              <b>Email</b>
            </label>
            <input
              type='email'
              name='email'
              // disabled={this.state.btnTitle==="Save"}
              className='col-12 form-control'
              placeholder=''
              value={this.state.new_user.email}
              onChange={this.handleChange}
            />
          </div>
          <div className='col-12 form-group'>
            <label className=''>
              <b>Phone</b>
            </label>
            <input
              type='phone'
              name='phone'
              className='col-12 form-control'
              placeholder=''
              value={this.state.new_user.phone}
              onChange={this.handleChange}
            />
          </div>

          <div className='col-12 form-check ml-3'>
            <input
              type='checkbox'
              name='active'
              ref='active'
              className='form-check-input'
              placeholder=''
              value={this.state.new_user.active}
              checked={this.state.new_user.active}
              onChange={this.handleCheckbox}
            />
            <label className='form-check-label'>Active</label>
          </div>
          <div className='col-12 form-check ml-3'>
            <input
              type='checkbox'
              name='admin'
              ref='admin'
              className='form-check-input'
              placeholder=''
              value={this.state.new_user.roles.admin}
              checked={this.state.new_user.roles.admin}
              onChange={this.handleCheckbox}
            />
            <label className='form-check-label'>Admin</label>
          </div>
    <div className='btn btn-primary ml-3' onClick={this.addNewuser}>{this.state.btnTitle}</div>
        </div>
      </div>
    );
  }
}

export default NewUser;
