import React, { useCallback, useState, useEffect } from "react";
import { Modal, Toast, Button, Form, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "../../App.css";

import { updateTrip, imageUpload, FormImage, TripImage } from "./utils";

const TripImages = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [show, setShow] = useState(false);
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [tripWallpaper, setTripWallpaper] = useState();
  const [tripWallpaperFile, setTripWallpaperFile] = useState();
  const [isSave, setIsSave] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!trip.multipleTripImages) return;
    setTripWallpaper(trip.tripImages.tripImageURL);
    setImages(trip.multipleTripImages);
  }, [trip.multipleTripImages]);

  const removeImage = (index) => async () => {
    const updated = images.filter((_x, i) => i !== index);

    try {
      await updateTrip(trip.id, archiveID, {
        multipleTripImages: updated,
        updatedAt: new Date(),
      });
      setImages(updated);
      setIsSave(true);
    } catch (error) {
      alert(
        "An error occurred while removing this image. Please refresh and try again"
      );
      console.error(error);
    }
  };

  const uploadWallpaper = useCallback(async () => {
    if (!tripWallpaperFile) return;

    try {
      const url = await imageUpload("tripImages", tripWallpaperFile);
      await updateTrip(trip.id, archiveID, {
        tripImages: {
          tripImageURL: url,
        },
        updatedAt: new Date(),
      });
      setTripWallpaper(url);
      setIsSave(true);
    } catch (error) {
      alert(
        "An error occurred while updating the trip wallpaper. Please try again"
      );
      console.error(error);
    }
  }, [trip.id, setTripWallpaper, tripWallpaperFile]);

  const onTripWallpaperFileChange = useCallback(
    (e) => setTripWallpaperFile(e.target.files[0]),
    [setTripWallpaperFile]
  );

  const onImageFilesChange = useCallback(
    (e) => setImageFiles(Array.from(e.target.files)),
    [setImageFiles]
  );

  const uploadImageFiles = useCallback(async () => {
    if (imageFiles.length === 0) return;

    try {
      const urls = await Promise.all(
        imageFiles.map((file) => imageUpload("multipleTripImages", file))
      );
      await updateTrip(trip.id, archiveID, {
        multipleTripImages: urls,
        updatedAt: new Date(),
      });
      setImages(urls);
      setIsSave(true);
    } catch (error) {
      alert("An error occurred while uploading the images. Please try again");
      console.error(error);
    }
  }, [trip.id, setImages, imageFiles]);

  const renderFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {renderFlashMessage()}
      <div className="info_main">
        <div className="info_head">
          <div className="info_head_text"></div>
        </div>
        <div className="row" style={{ margin: "6px" }}>
          <div className="col-md-7">
            Trip Wallpaper
            <br />
            <div className="row">
              <FormImage
                className="col-md-6"
                onChange={onTripWallpaperFileChange}
              />
              <Button
                variant="primary"
                className="col-md-6"
                onClick={uploadWallpaper}
              >
                Save Image
              </Button>
            </div>
            <hr />
            <TripImage
              style={{ width: "100%", height: "450px" }}
              src={tripWallpaper}
              defaultURL="https://image.freepik.com/free-vector/add-files-concept-illustration_114360-471.jpg"
              alt="Trip image"
            />
            <hr />
            <div className="row">
              <div className="col-md-6">
                <b>Additional Photos</b>
                <br />
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={onImageFilesChange}
                />
                <Button variant="success" onClick={uploadImageFiles}>
                  Add Multiple Photos
                </Button>
              </div>
              <div className="col-md-6">
                <b>Destination Albums</b> <br />
                <Button onClick={handleShow} variant="danger">
                  Add Destination
                </Button>
              </div>
            </div>
            <hr />
          </div>
          <div className="col-md-4">
            About the Trip Wallpaper Think of the Trip Wallpaper as the
            background of your trip. A good Trip Wallpaper should flawlessly
            capture the ennui of your client's trip. Generally the same things
            that make a photo a good desktop background for your computer make a
            good Trip Wallpaper as well General Guidelines for the Perfect Trip
            Wallpaper Be at least 1200px wide. Be at least 700px tall. Oddly
            shaped photos are bad. Photos with borders or watermarks are bad.
            Cityscapes and beaches are great. Interior shots are hit or miss.
            Tall and narrow photos are bad. No pictures of the Eiffel Tower or
            the Satue of Liberty from base to tip, it will look bad. Panoramas
            are good, unless the would have to be stretched to fit a screen. So
            a 90 dgree panorama is good, but a 360 degree panorama won't work.
            <br />
            Adding Destination Albums You can add multiple destination albums
            from Tripwing's exclusive collection of commercial use allowed
            photos. We have albums from the world's top 300 destinations
            availible for your use. Adding Multiple Photos If you cannot find
            where you are sending your client in our destinations albums, you
            can also add as many other photos as yopu please. Do not to add
            photos with a width of less 700 pixels because they may look blury
            when displayed.
          </div>
        </div>
      </div>
      <br />
      <div className="row" style={{ paddingRight: "42px", paddingLeft: "5px" }}>
        {images.map((img, index) => (
          <div className="col-md-4" key={index}>
            <i
              onClick={removeImage(index)}
              className="fa fa-trash fa-2x"
              style={{ color: "red" }}
            ></i>
            <TripImage src={img} className="multiple-img" alt="Trip" />
          </div>
        ))}
      </div>
      <br />
      <DestinationAlbumsModal show={show} onClose={handleClose} />
    </>
  );
};

// TODO: clean this up (and make it work? it doesnt do anything)
const DestinationAlbumsModal = ({ show, onClose }) => {
  const [showA, setShowA] = useState(true);
  const [showB, setShowB] = useState(false);
  const [showC, setShowC] = useState(true);
  const [showD, setShowD] = useState(false);

  const toggleShowA = () => setShowA(false);
  const toggleShowB = () => setShowB(true);
  const toggleShowC = () => setShowC(false);
  const toggleShowD = () => setShowD(true);

  const show_message = () => {
    toggleShowA();
    toggleShowB();
  };
  const show_message1 = () => {
    toggleShowC();
    toggleShowD();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Destination Album</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Search Albums
        <br />
        <Form>
          <Form.Group controlId="">
            <Form.Control type="text" />
            <Button variant="primary" type="submit">
              filter
            </Button>
          </Form.Group>
        </Form>
        <br />
        <div className="row">
          <div className="col-md-6">
            <Toast show={showA} onClose={toggleShowA}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto">Bootstrap</strong>
              </Toast.Header>
              <Toast.Body>
                Haridwar, Uttarakhand, India
                <Button onClick={show_message}>
                  <strong>Add Album</strong>
                </Button>
              </Toast.Body>
            </Toast>
          </div>
          <div className="col-md-6">
            <Toast show={showB} onClose={toggleShowA}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto">Album Added</strong>
              </Toast.Header>
              <Toast.Body>added</Toast.Body>
            </Toast>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <Toast show={showC} onClose={toggleShowC}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto">Golf</strong>
              </Toast.Header>
              <Toast.Body>
                Haridwar, Uttarakhand, India
                <Button onClick={show_message1}>
                  <strong>Add Album</strong>
                </Button>
              </Toast.Body>
            </Toast>
          </div>
          <div className="col-md-6">
            <Toast show={showD} onClose={toggleShowD}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto">Album Added</strong>
              </Toast.Header>
              <Toast.Body>added</Toast.Body>
            </Toast>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(TripImages);
