import React, { useCallback, useEffect, useState } from "react";
import { Form, Col, Button, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";

import {
  updateTrip,
  useIndexedState,
  FormImage,
  TripImage,
  imageUpload,
} from "../utils";

const ResturantPhoto = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [restaurants, setRestaurants] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const { modifyAt, withModifyAt } = useIndexedState(
    restaurants,
    setRestaurants
  );

  useEffect(() => {
    if (!trip.resturants) return;
    setRestaurants(trip.resturants);
  }, [setRestaurants, trip.id]);

  const onSubmit = useCallback(() => {
    updateTrip(trip.id, archiveID, {
      resturants: restaurants.map((restaurant) => ({
        ...restaurant,
        imageFile: null,
      })),
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  }, [restaurants, trip.id]);

  const upload = withModifyAt((restaurant, modify) =>
    imageUpload("resturantImages", restaurant.imageFile).then((url) =>
      modify({
        ...restaurant,
        resturantImageURL: url,
      })
    )
  );

  const setImageFile = modifyAt((restaurant, e) => ({
    ...restaurant,
    imageFile: e.target.files[0],
  }));

  const removeImage = modifyAt((restaurant) => ({
    ...restaurant,
    resturantImageURL: null,
  }));

  const renderFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {renderFlashMessage()}
      {restaurants.map((restaurant, index) => (
        <>
          <div key={index}>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <h5>Restaurant {index + 1}</h5>
              </div>
            </div>
            <hr />
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Photo</Form.Label>
                  <FormImage onChange={setImageFile(index)} />
                </Form.Group>
              </Form.Row>
              <Button
                onClick={upload(index)}
                className="btn btn-success btn-sm"
              >
                Upload
              </Button>
              &nbsp;&nbsp;
              <Button
                onClick={removeImage(index)}
                className="btn btn-danger btn-sm remove"
              >
                Remove
              </Button>
            </Form>
            <br />
            <TripImage
              src={restaurant.resturantImageURL}
              alt="Restaurant image"
              height="300px"
              width="400px"
            />
            <br />
          </div>
        </>
      ))}
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Images
      </button>
    </>
  );
};

export default withRouter(ResturantPhoto);
