import { createActivity } from "./activities.js";
import { createCarRental } from "./car-rentals.js";
import { createFlight } from "./flights.js";
import { createOverview } from "./overviews.js";
import { createHotel } from "./hotels.js";
import { createRestaurant } from "./restaurants.js";
import { createTrain } from "./trains.js";
import { createTransfer } from "./transfers.js";
import { fetchAlgoliaResult } from "../../firebase-auth/Config.js";

export const createTrip = (
  { title, subtitle, startDate, endDate, user_id, userName },
  now
) => {
  return {
    title,
    subtitle,
    user_id,
    userName,
    startDate,
    endDate,
    createdAt: now,
    updatedAt: now,
    tripDetails: {
      description: "",
      endDate,
      includes: "",
      max_price: "",
      payment_link: "",
      payment_terms: "",
      price: "",
      price_type: "＄",
      startDate,
      title: "",
      travel_link: "",
    },
    emails: [
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    ],
    multipleTripImages: [],
    tripImages: { tripImageURL: "" },
    activities: [createActivity()],
    attachements: [{ content: "", title: "", file: [] }],
    carRentals: [createCarRental()],
    dayOverViews: [createOverview()],
    flights: [createFlight()],
    hotels: [createHotel()],
    resturants: [createRestaurant()], // TODO: migrate to proper spelling
    trains: createTrain(), // TODO: migrate trains to an array (we can only have one right now, why?)
    transfers: [createTransfer()],
  };
};

export const copyTrip = (trip, user_id, userName, now) => {
  const title = "Copy of " + trip.title;

  return {
    ...trip,
    createdAt: now,
    updatedAt: now,
    user_id,
    userName,
    title,
  };
};

export const sortableFields = {
  title: "title",
  startDate: "startDate",
  subtitle: "subtitle",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
};

export const direction = {
  ascending: "asc",
  descending: "desc",
};

export const flipDirection = (x) =>
  x === direction.ascending ? direction.descending : direction.ascending;

const collectionRef = (db, collection = "Tripss") => db.collection(collection);

export const getTrips =
  (db) =>
  async (search, collection = "Tripss") => {
    var trips = [];

    if (search.length > 2) {
      const algoliaResult = await fetchAlgoliaResult({ collection, search });
      trips = algoliaResult.data;
    } else {
      const ref = collectionRef(db, collection);
      const snapshot = await ref.get();
      snapshot.forEach((doc) => {
        trips.push({ ...doc.data(), key: doc.id });
      });
    }

    console.log(trips);
    return trips;
  };

export const deleteTrip =
  (db) =>
  async (key, collection = "Tripss") => {
    const ref = collectionRef(db, collection);
    await ref.doc(key).delete();
  };

export const archiveTrip =
  (db) => async (collection, key, onDelete, title, subtitle) => {
    const tripsRef = collectionRef(db, "Tripss");
    const doc = await tripsRef.doc(key).get();

    const archiveRef = collectionRef(db, collection);
    archiveRef.doc(key).set(doc.data());

    onDelete(key, true, title, subtitle);
  };

export const unArchiveTrip =
  (db) => async (collection, key, onDelete, title, subtitle) => {
    const archiveRef = collectionRef(db, collection);
    const doc = await archiveRef.doc(key).get();

    const tripsRef = collectionRef(db, "Tripss");
    tripsRef.doc(key).set(doc.data());

    onDelete(key, true, title, subtitle);
  };
