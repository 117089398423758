import React, { useCallback, useEffect, useState } from "react";
import { Form, Col, Alert, Row } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import { createActivity } from "../controllers/activities";
import {
  updateTrip,
  useIndexedState,
  imageUpload,
  FormImage,
  DateTimeField,
  Field,
  TripImage,
} from "../utils";
import {
  dateFromTime,
  stringFromDate,
  timeFromDate,
} from "../../../utils/date-utils";

const AddRentalCar = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);
  const [details, setDetails] = useState([]);
  const [isSave, setIsSave] = React.useState(false);
  const { push, removeAt, modifyAt, withModifyAt } = useIndexedState(
    details,
    setDetails
  );

  useEffect(() => {
    if (!trip.activities) return;

    setDetails(trip.activities);
  }, [trip.id, setDetails, setMaxDate, setMinDate]);

  const onSubmit = useCallback(() => {
    const newActivites = details.map((x) => ({
      ...x,
      imageFile: null,
    }));

    trip.activities = newActivites;
    updateTrip(trip.id, archiveID, {
      activities: newActivites,
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  }, [setIsSave, trip.id, details]);

  const addActivityDetails = push(() => createActivity());

  const setDetailsKey = (key) =>
    modifyAt((activity, e) => ({
      ...activity,
      activityDetails: {
        ...activity.activityDetails,
        [key]: e.target.value,
      },
    }));

  const setStartDate = modifyAt((activity, date) => ({
    ...activity,
    activityDetails: {
      ...activity.activityDetails,
      startDate: stringFromDate(date),
    },
  }));

  const setStartTime = modifyAt((activity, time) => ({
    ...activity,
    activityDetails: {
      ...activity.activityDetails,
      startTime: timeFromDate(time),
    },
  }));

  const setImageFile = modifyAt((activity, e) => ({
    ...activity,
    imageFile: e.target.files[0],
  }));

  const removeImage = modifyAt((activity, e) => ({
    ...activity,
    url: null,
  }));

  const upload = withModifyAt((activity, modify) =>
    imageUpload("activities", activity.imageFile).then((url) =>
      modify({ ...activity, url })
    )
  );

  const renderFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {renderFlashMessage()}
      {details.map((data, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>Activity {index + 1}</h5>
            </div>
            <div className="col-md-6 text-right">
              <Button
                variant="contained"
                color="secondary"
                // className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={removeAt(index)}
              >
                Remove
              </Button>
            </div>
          </div>
          <hr />
          <Form>
            <Form>
              <Form.Group as={Row} className="ml-1">
                <Form.Label>Place Name</Form.Label>
                <Form.Control
                  placeholder="Place"
                  as="textarea"
                  name="activityPlace"
                  style={{ maxWidth: "90%" }}
                  value={data.activityDetails.activityPlace}
                  onChange={setDetailsKey("activityPlace")(index)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="mr-2">Photo</Form.Label>
                <FormImage onChange={setImageFile(index)} />
                <br />
                <Button
                  onClick={upload(index)}
                  className="btn btn-success btn-sm"
                >
                  Upload
                </Button>
                <Button
                  onClick={removeImage(index)}
                  className="btn btn-danger btn-sm remove"
                >
                  Remove
                </Button>
              </Form.Group>
              <Form.Group as={Col}>
                <TripImage
                  src={data.url}
                  alt="Activity preview"
                  height="130px"
                  width="160px"
                />
              </Form.Group>
            </Form>
            <Form.Row>
              <Form.Group>
                <DateTimeField
                  date={
                    data.activityDetails.startDate
                      ? data.activityDetails.startDate
                      : minDate
                  }
                  dateLabel="Start Date"
                  setDate={setStartDate(index)}
                  time={
                    data.activityDetails.startTime
                      ? dateFromTime(data.activityDetails.startTime)
                      : new Date()
                  }
                  timeLabel="Time"
                  setTime={setStartTime(index)}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Field
                label="Address"
                value={data.activityDetails.activityAddress}
                onChange={setDetailsKey("activityAddress")(index)}
              />
            </Form.Row>
          </Form>
        </div>
      ))}
      <button className="marginButtonffor" onClick={addActivityDetails}>
        Add More
      </button>
      <br />
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save
      </button>
    </>
  );
};

export default withRouter(AddRentalCar);
