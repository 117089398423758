import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Alert, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FirebaseSignin from "./FirebaseSignin";
import firebase from "../firebase-auth/Config";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const classes = useStyles();

  const Login = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((u) => {
        console.log(u, "I am A");
      })
      .catch((err) => {
        setError(err.message);
        console.log(err.message, err);
      });
  };

  const passwordReset = (e, email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((u) => {
        alert("An email has been sent to you.");
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs" className="login_frm">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}></Avatar>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            {error !== "" ? (
              <Alert key={"danger"} variant={"danger"}>
                {error}
              </Alert>
            ) : (
              ""
            )}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Grid container>
              <Grid item xs>
                <Link onClick={handleShow} variant="body2" to="">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                {/* <Link to='/signup' variant='body2'>
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
          </form>
          <Modal show={show} onHide={handleClose}>
            {error !== "" ? (
              <Alert key={"danger"} variant={"danger"}>
                {error}
              </Alert>
            ) : (
              ""
            )}
            <Modal.Header closeButton>
              <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Enter Vaild Email to Reset Password</h5>
              <TextField
                variant="outlined"
                // margin="normal"
                required
                fullWidth
                id="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => passwordReset(e, email)}
              >
                Send Reset Request
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <br />
        <Button
          variant="contained"
          size="large"
          color="primary"
          className="text-left"
          onClick={Login}
        >
          Login
        </Button>
        <center>
          <br />
          <br />
          {/* <FirebaseSignin /> */}
          <br />
          <br />
        </center>
      </Container>
    </>
  );
};
export default withRouter(SignIn);
