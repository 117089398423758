import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "date-fns";
import firebase from "../../firebase-auth/Config";
import { storage } from "../../firebase-auth/Config";
import { withRouter } from "react-router-dom";
import "./tripshow.css";

import { TripImage } from "../utils";

// TODO: clean this up
const Overview = ({
  trip,
  match: {
    params: { id },
  },
}) => {
  const [description, setDescription] = useState("");
  const [include, setInclude] = useState("");
  const [price, setPrice] = useState("");
  const [priceType, setPriceType] = useState("");
  const [paymentInfo, setPaymentInfo] = useState("");
  const [companyData, setCompanyData] = useState("");
  const [companyLogo, setCompanyLogo] = useState(
    "golfAwayToursLogos/GolfAwayLogoOval.png"
  );
  const [userId, setUserId] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [defaultEmail, setDefaultEmail] = useState("");
  const [userImage, setUserImage] = useState("");

  var db = firebase.firestore();
  const userRef = firebase.auth();

  useEffect(() => {
    if (!trip) return;

    const allData = trip;

    const baseCall = allData.tripDetails;
    setDescription(baseCall.description);
    setInclude(baseCall.includes);
    const formattedPrice = baseCall.price.replace("$", "");
    setPrice(formattedPrice);
    setPriceType(baseCall.price_type);
    setPaymentInfo(baseCall.payment_terms);
    setUserId(allData.user_id);
  }, [setCompanyData, setCompanyLogo, trip, db]);

  const fetchAgentDetails = () => {
    console.log(userId);
    console.log("useRef", userRef);
    db.collection("VirtualUsers")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if (doc.data().profile) {
            setDefaultEmail(doc.data().email);
            setProfileData(doc.data().profile);
            setUserImage(`misc/${doc.data().profile.image}`);
          }
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  function formatNumber(num) {
    var input = num;
    var n1, n2;
    num = num + "" || "";
    // works for integer and floating as well
    n1 = num.split(".");
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    console.log("Input:", input);
    console.log("Output:", num);
    return num;
  }

  useEffect(() => {
    if (userId) {
      fetchAgentDetails();
    }
  }, [userId]);

  const getTrimmedText = () => {
    var str = companyData.description ? companyData.description : "      ";
    str = str.replace(str.substring(0, 3), "");
    str = str.replace(str.substring(str.length, str.length - 4), "");
    return str;
  };

  return (
    <div>
      <br />
      <div>
        <div className="container">
          <div>
            <div className="titlebar">OVERVIEW</div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="card  pt-4">
                <div style={{ padding: 10 }}>
                  <p dangerouslySetInnerHTML={{ __html: description }} />
                  <br />
                  <h5>
                    <b>What's included?</b>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: include,
                    }}
                  />
                  <br />
                  {price && (
                    <div>
                      <h5>
                        <b>Price and Payment Information</b>
                      </h5>
                      <b>
                        <span>{priceType}</span>
                        <span>{formatNumber(price)}</span>
                      </b>
                      <p dangerouslySetInnerHTML={{ __html: paymentInfo }} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card pt-4">
                <div style={{ padding: 10 }}>
                  <h5>{companyData.branch_name}</h5>
                  <div className="row">
                    {/* <div className="col-md-6">
                      <p>{getTrimmedText()}</p>
                    </div> */}
                    <div className="col-md-6">
                      <TripImage
                        src={companyLogo}
                        defaultURL={
                          "https://www.filepicker.io/api/file/rZOfHdkbTgKfE9KcxwL6"
                        }
                        alt="logo_image"
                        style={{ height: "130px", padding: "0.5rem 0.5rem 0.5rem 0.5rem"}}
                      />
                    </div>
                  </div>
                  <div>
                    <a
                      className="align-top"
                      style={{ border: "none", marginRight: "10px" }}
                      href="https://www.facebook.com/golfawaytours/"
                    >
                      <Button variant="success">Like Us </Button>
                    </a>
                    <iframe
                      src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fgolfawaytours%2F&width=83&layout=button_count&action=like&size=small&share=false&height=21&appId=1648449185216289"
                      width="83"
                      height="21"
                      style={{ border: "none", overflow: "hidden" }}
                      scrolling="no"
                      frameborder="0"
                      allowTransparency="true"
                      allow="encrypted-media"
                    ></iframe>
                    <hr />
                  </div>
                  {profileData ? (
                    <div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="colomn">
                            <div style={{ fontSize: 16 }}>
                              Your Agent,{" "}
                              {profileData.first_name
                                ? profileData.first_name +
                                  " " +
                                  profileData.last_name
                                : ""}
                            </div>
                            <div style={{ paddingTop: 16 }}></div>
                            <TripImage
                              style={{ borderRadius: "15px" }}
                              src={userImage}
                              defaultURL="https://www.filepicker.io/api/file/MoALsixXT6dqLFieCmfM"
                              width="100"
                              height="120"
                              alt="logo_image"
                            />
                            <div style={{ paddingTop: 20 }}></div>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="colomn">
                            <div>
                              <b>Office Phone</b>
                            </div>
                            <div>{profileData.office_number}</div>
                            <div>
                              <b>Mobile Phone</b>
                            </div>
                            <div>{profileData.mobile}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div>
                            <b>Email</b>
                          </div>
                          <a href={"mailto:" + defaultEmail}>{defaultEmail}</a>
                          <div>
                            <b>Twitter</b>
                          </div>
                          <a
                            href={
                              "https://twitter.com/" +
                              profileData.twitter_handle
                            }
                            target="_blank"
                          >
                            {profileData.twitter_handle}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-122 text-center2">
                      <div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="colomn">
                              <div style={{ fontSize: 16 }}>
                                Your Agent, TJ Rule
                              </div>
                              <div style={{ paddingTop: 16 }}></div>
                              <TripImage
                                style={{ borderRadius: "15px" }}
                                src={userImage}
                                defaultURL={
                                  "https://firebasestorage.googleapis.com/v0/b/tripthing-48348.appspot.com/o/misc%2FIMG_2606%20copy.jpg?alt=media&token=a1f42458-76c5-4ec5-a7c6-89e09b98cede"
                                }
                                width="100"
                                height="120"
                                alt="profile pic"
                              />
                              <div style={{ paddingTop: 20 }}></div>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="colomn">
                              <div>
                                <b>Office Phone</b>
                              </div>
                              <div>416-466-4653</div>
                              <div>
                                <b>Mobile Phone</b>
                              </div>
                              <div>647-406-6016</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div>
                              <b>Email</b>
                            </div>
                            <div>
                            <a href="mailto:tj@golfawaytours.com">tj@golfawaytours.com</a>
                            </div>

                            <div>
                              <b>Twitter</b>
                            </div>
                            <div>
                            <a href="https://twitter.com/@golfawaytj" target="_blank">@golfawaytj</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Overview);
