import React, { useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { updateTrip } from "../utils";

const TrainMarkerMapLocation = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [destinationLatitude, setDestinationLatitude] = useState("");
  const [destinationLongitude, setDestinationLongitude] = useState("");
  const [isSave, setIsSave] = useState(false);

  useEffect(() => {
    if (!trip?.trains?.MapLocation) return;

    const baseCall = trip.trains.MapLocation;
    setCurrentLatitude(baseCall.currentLatitude);
    setCurrentLongitude(baseCall.currentLongitude);
    setDestinationLatitude(baseCall.destinationLatitude);
    setDestinationLongitude(baseCall.destinationLongitude);
  }, [trip.id]);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      trains: {
        MapLocation: {
          currentLatitude: currentLatitude,
          currentLongitude: currentLongitude,
          destinationLatitude: destinationLatitude,
          destinationLongitude: destinationLongitude,
        },
      },
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {rendorFlashMessage()}
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              placeholder="Latitude"
              onChange={(e) => setCurrentLatitude(e.target.value)}
              value={currentLatitude}
              name="currentLatitude"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              placeholder="Longitude"
              onChange={(e) => setCurrentLongitude(e.target.value)}
              value={currentLongitude}
              name="currentLongitude"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Destination Latitude</Form.Label>
            <Form.Control
              placeholder="Destination Latitude"
              onChange={(e) => setDestinationLatitude(e.target.value)}
              value={destinationLatitude}
              name="destinationLatitude"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Destination Longitude</Form.Label>
            <Form.Control
              placeholder="Destination Longitude"
              onChange={(e) => setDestinationLongitude(e.target.value)}
              value={destinationLongitude}
              name="destinationLongitude"
            />
          </Form.Group>
        </Form.Row>
      </Form>
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Train Map Location
      </button>
    </>
  );
};

export default withRouter(TrainMarkerMapLocation);
