// import React, { Component } from "react";
// import firebase from "./firebase-auth/Config";
// import { withRouter } from "react-router-dom";
// import './Calendar.css';

// import {
//   ScheduleComponent,
//   Week,
//   Month,
//   EventSettingsModel,
//   Inject,
//   ViewsDirective,
//   ViewDirective,
// } from "@syncfusion/ej2-react-schedule";

// class Calender extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       startDate: new Date().toDateString(),
//       calenderDate: new Date(),
//       trips: "",
//       edit: "",
//       data: [],
//     };
//   }
//   componentDidMount() {
//     var db = firebase.firestore();
//     var cityRef = db.collection("Tripss").doc(this.props.match.params.id);

//     cityRef.get().then((doc) => {
//       if (doc.exists) {
//         const trip = doc.data();
//         this.setState({calenderDate:trip.startDate})
//         if (trip && trip.dayOverViews) {
//           const trip = trip;
//           trip.dayOverViews.map((dayOverView, index) => {
//             if (dayOverView.OverviewDescription !== "") {
//               this.setState({
//                 data: [
//                   ...this.state.data,
//                   {
//                     Id: index + 1,
//                     Subject: dayOverView.OverviewDescription,
//                     StartTime: dayOverView.startDate,
//                     EndTime: dayOverView.endDate+" 00:00:01 GMT+0530",
//                     CategoryColor: "#FFC300"
//                   },
//                 ],
//               });
//             }
//           });
//           const hotels = Object.prototype.toString.call(trip.hotels || trip.hotelInfomation) === '[object Object]' ? [trip.hotels|| trip.hotelInfomation] : (trip.hotels|| trip.hotelInfomation)

//           hotels.map((hotel, index) => {
//             if (hotel.hotelName !== "") {
//               this.setState({
//                 data: [
//                   ...this.state.data,
//                   {
//                     Id: index + 1,
//                     Subject: hotel.hotelName,
//                     StartTime: hotel.hotelCheckin,
//                     EndTime: hotel.hotelCheckout+" 00:00:01 GMT+0530",
//                     CategoryColor: "#C70039"
//                   },
//                 ],
//               });
//             }
//           });
//           const flights = Object.prototype.toString.call(trip.flights) === '[object Object]' ? [trip.flights] : (trip.flights)
//           flights.map((flight, index) =>{

//             if (flight.airports.airportName !== "") {
//               this.setState({
//                 data: [
//                   ...this.state.data,
//                   {
//                     Id: this.state.data[this.state.data.length - 1] + 1,
//                     Subject: flight.airports.airportName,
//                     StartTime: flight.airports.departureDate,
//                     EndTime: flight.airports.arrivalDate+" 00:00:01 GMT+0530",
//                     CategoryColor: "#581845"
//                   },
//                 ],
//               });
//             }
//           })

//           const activities = Object.prototype.toString.call(trip.activities) === '[object Object]' ? [trip.activities] : trip.activities
//           activities.map((activity, index) => {
//             if (activity.activityDetails.activityPlace !== "") {
//               this.setState({
//                 data: [
//                   ...this.state.data,
//                   {
//                     Id: this.state.data[this.state.data.length - 1] + 1,
//                     Subject: activity.activityDetails.activityPlace,
//                     StartTime: activity.activityDetails.startDate,
//                     EndTime: activity.activityDetails.startDate+" 00:00:01 GMT+0530",
//                     CategoryColor: "#2E86C1"
//                   },
//                 ],
//               });
//             }
//           });
//           const carRentals = Object.prototype.toString.call(trip.carRentals) === '[object Object]' ? [trip.carRentals] : trip.carRentals

//           carRentals.map((carRental, index) => {
//             if (carRental.carRentalsDetails.companyName!=="")
//             {
//               this.setState({
//                 data: [
//                   ...this.state.data,
//                   {
//                     Id:this.state.data[this.state.data.length-1]+1,
//                     Subject: carRental.carRentalsDetails.companyName,
//                     StartTime: carRental.carRentalsDetails.startDate,
//                     EndTime: carRental.carRentalsDetails.endDate+" 00:00:01 GMT+0530",
//                     CategoryColor: "#7F8C8D"
//                   },
//                 ],
//               });
//             }
//           })
//           const resturants = Object.prototype.toString.call(trip.resturants) === '[object Object]' ? [trip.resturants] : trip.resturants
//           resturants.map((resturant, index) => {
//             if (resturant.resturantsDetails.resturantName!=="")
//           {
//             this.setState({
//               data: [
//                 ...this.state.data,
//                 {
//                   Id:this.state.data[this.state.data.length-1]+1,
//                   Subject: resturant.resturantsDetails.resturantName,
//                   StartTime: resturant.resturantsDetails.selectedDate,
//                   EndTime: resturant.resturantsDetails.selectedDate+" 00:00:01 GMT+0530",
//                   CategoryColor: "#76D7C4"
//                 },
//               ],
//             });
//           }
//           })
//           const trains = Object.prototype.toString.call(trip.trains) === '[object Object]' ? trip.trains : [trip.trains]
//           if (trains.trainDetails.companyName !== "") {
//             this.setState({
//               data: [
//                 ...this.state.data,
//                 {
//                   Id: this.state.data[this.state.data.length - 1] + 1,
//                   Subject: trip.trains.trainDetails.companyName,
//                   StartTime: trip.trains.trainDetails.departureDate,
//                   EndTime: trip.trains.trainDetails.arrivalDate+" 00:00:01 GMT+0530",
//                   CategoryColor: "#196F3D"
//                 },
//               ],
//             });
//           }

//           const transfers = Object.prototype.toString.call(trip.transfers ||trip.transfer) === '[object Object]' ? [trip.transfers ||trip.transfer] : (trip.transfers ||trip.transfer)
//          console.log("AAA"+JSON.stringify(transfers))
//           transfers.map((transfer, index) => {
//             if (transfer.transferComments.companyName!=="")
//             {
//               this.setState({
//                 data: [
//                   ...this.state.data,
//                   {
//                     Id:this.state.data[this.state.data.length-1]+1,
//                     Subject: transfer.transferComments.companyName?transfer.transferComments.companyName:"Unnamed Transfer",
//                     StartTime: transfer.transferDetails.selectedDate,
//                     EndTime: transfer.transferDetails.selectedDate+" 00:00:01 GMT+0530",
//                     CategoryColor: "red"
//                   },
//                 ],
//               });
//             }
//           })
//         }
//       }
//     });
//   }

// OnEventRendered = (args) => {
//     args.element.style.backgroundColor = args.data.CategoryColor;
// }

//   render() {
//     return (
//       <>
//         <ScheduleComponent
//           currentView="Month"
//           height="550px"
//           selectedDate={this.state.calenderDate}
//           eventSettings={{ dataSource: this.state.data }}
//           eventRendered={this.OnEventRendered}
//           // popupOpen={() => this.props.props.handler("over")}
//         >
//           <ViewsDirective>
//             <ViewDirective option="Month" showWeekend={true} readonly={true} />
//             <ViewDirective option="Week" />
//           </ViewsDirective>
//           <Inject services={[Month, Week]} />
//         </ScheduleComponent>
//       </>
//     );
//   }
// }
// export default withRouter(Calender);

import { render } from "react-dom";
import * as React from "react";
import firebase from "./firebase-auth/Config";
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  EventSettingsModel,
  Week,
  Month,
  Inject,
} from "@syncfusion/ej2-react-schedule";
import { extend } from "@syncfusion/ej2-base";
import { withRouter } from "react-router-dom";
import "./Calendar.css";

const calData = {
  scheduleData: [
    {
      Id: 1,
      Subject: "Indian Gailways",
      StartTime: "Sat Aug 15 2020",
      EndTime: "Sat Aug 15 2020 00:00:01 GMT+0530",
      CategoryColor: "#196F3D",
    },
    {
      Id: 2,
      Subject: "Station Porters",
      StartTime: "Sat Aug 15 2020",
      EndTime: "Sat Aug 15 2020 00:00:01 GMT+0530",
      CategoryColor: "red",
    },
  ],
};
class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date().toDateString(),
      calenderDate: new Date(),
      edit: "",
      data: [],
    };
    this.data = extend([], calData.scheduleData, null, true);
  }

  componentDidMount() {
    const { trip } = this.props;
    this.setState({ calenderDate: trip.startDate });
    if (trip?.dayOverViews) {
      trip.dayOverViews.map((dayOverView, index) => {
        if (dayOverView.OverviewDescription !== "") {
          this.setState({
            data: [
              ...this.state.data,
              {
                Id: index + 1,
                Subject: dayOverView.OverviewDescription,
                StartTime: dayOverView.startDate,
                EndTime: dayOverView.endDate + " 00:00:01 GMT+0530",
                CategoryColor: "#FFC300",
              },
            ],
          });
        }
      });
      const hotels =
        Object.prototype.toString.call(trip.hotels || trip.hotelInfomation) ===
        "[object Object]"
          ? [trip.hotels || trip.hotelInfomation]
          : trip.hotels || trip.hotelInfomation;

      hotels.map((hotel, index) => {
        if (hotel.hotelName !== "") {
          this.setState({
            data: [
              ...this.state.data,
              {
                Id: index + 1,
                Subject: hotel.hotelName,
                StartTime: hotel.hotelCheckin,
                EndTime: hotel.hotelCheckout + " 00:00:01 GMT+0530",
                CategoryColor: "#C70039",
              },
            ],
          });
        }
      });
      const flights =
        Object.prototype.toString.call(trip.flights) === "[object Object]"
          ? [trip.flights]
          : trip.flights;
      flights.map((flight, index) => {
        if (flight.airports.airportName !== "") {
          this.setState({
            data: [
              ...this.state.data,
              {
                Id: this.state.data[this.state.data.length - 1] + 1,
                Subject: flight.airports.airportName,
                StartTime: flight.airports.departureDate,
                EndTime: flight.airports.arrivalDate + " 00:00:01 GMT+0530",
                CategoryColor: "#581845",
              },
            ],
          });
        }
      });

      const activities =
        Object.prototype.toString.call(trip.activities) === "[object Object]"
          ? [trip.activities]
          : trip.activities;
      activities.map((activity, index) => {
        if (activity.activityDetails.activityPlace !== "") {
          this.setState({
            data: [
              ...this.state.data,
              {
                Id: this.state.data[this.state.data.length - 1] + 1,
                Subject: activity.activityDetails.activityPlace,
                StartTime: activity.activityDetails.startDate,
                EndTime:
                  activity.activityDetails.startDate + " 00:00:01 GMT+0530",
                CategoryColor: "#2E86C1",
              },
            ],
          });
        }
      });
      const carRentals =
        Object.prototype.toString.call(trip.carRentals) === "[object Object]"
          ? [trip.carRentals]
          : trip.carRentals;

      carRentals.map((carRental, index) => {
        if (carRental.carRentalsDetails.companyName !== "") {
          this.setState({
            data: [
              ...this.state.data,
              {
                Id: this.state.data[this.state.data.length - 1] + 1,
                Subject: carRental.carRentalsDetails.companyName,
                StartTime: carRental.carRentalsDetails.startDate,
                EndTime:
                  carRental.carRentalsDetails.endDate + " 00:00:01 GMT+0530",
                CategoryColor: "#7F8C8D",
              },
            ],
          });
        }
      });
      const resturants =
        Object.prototype.toString.call(trip.resturants) === "[object Object]"
          ? [trip.resturants]
          : trip.resturants;
      resturants.map((resturant, index) => {
        if (resturant.resturantsDetails.resturantName !== "") {
          this.setState({
            data: [
              ...this.state.data,
              {
                Id: this.state.data[this.state.data.length - 1] + 1,
                Subject: resturant.resturantsDetails.resturantName,
                StartTime: resturant.resturantsDetails.selectedDate,
                EndTime:
                  resturant.resturantsDetails.selectedDate +
                  " 00:00:01 GMT+0530",
                CategoryColor: "#76D7C4",
              },
            ],
          });
        }
      });
      const trains =
        Object.prototype.toString.call(trip.trains) === "[object Object]"
          ? trip.trains
          : [trip.trains];
      if (trains.trainDetails.companyName !== "") {
        this.setState({
          data: [
            ...this.state.data,
            {
              Id: this.state.data[this.state.data.length - 1] + 1,
              Subject: trip.trains.trainDetails.companyName,
              StartTime: trip.trains.trainDetails.departureDate,
              EndTime:
                trip.trains.trainDetails.arrivalDate + " 00:00:01 GMT+0530",
              CategoryColor: "#196F3D",
            },
          ],
        });
      }

      const transfers =
        Object.prototype.toString.call(trip.transfers || trip.transfer) ===
        "[object Object]"
          ? [trip.transfers || trip.transfer]
          : trip.transfers || trip.transfer;
      console.log("AAA" + JSON.stringify(transfers));
      transfers.map((transfer, index) => {
        if (transfer.transferComments.companyName !== "") {
          this.setState({
            data: [
              ...this.state.data,
              {
                Id: this.state.data[this.state.data.length - 1] + 1,
                Subject: transfer.transferComments.companyName
                  ? transfer.transferComments.companyName
                  : "Unnamed Transfer",
                StartTime: transfer.transferDetails.selectedDate,
                EndTime:
                  transfer.transferDetails.selectedDate + " 00:00:01 GMT+0530",
                CategoryColor: "red",
              },
            ],
          });
        }
      });
    }
  }

  OnEventRendered = (args) => {
    args.element.style.backgroundColor = args.data.CategoryColor;
  };

  render() {
    return (
      <ScheduleComponent
        height="650px"
        currentView="Month"
        selectedDate={this.state.calenderDate}
        eventRendered={this.OnEventRendered}
        eventSettings={{ dataSource: this.state.data }}
      >
        <ViewsDirective option="Month" showWeekend={true} readonly={true}>
          <ViewDirective option="Week" />
          <ViewDirective option="Month" />
        </ViewsDirective>
        <Inject services={[Week, Month]} />
      </ScheduleComponent>
    );
  }
}

export default withRouter(Calendar);
