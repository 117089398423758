import React, { Component, Fragment } from 'react';
import firebase from '../firebase-auth/Config';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

import NewUser from './NewUser';
import AlertDialog from '../Reusable/DialogComponent';
const editorConfiguration = {
  className: 'col-12',
};
const emailDiv = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '175px',
  textOverflow: 'ellipsis',
  width: '90%',
}
let outerThis = null;
const columns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
    style: emailDiv,
  },
  {
    dataField: 'phone',
    text: 'Phone',
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '130px'};
    },
  },
  {
    dataField: 'admin',
    text: 'Admin',
    style: { width: '55px' },
    headerStyle: (colum, colIndex) => {
      return { width: '55px', textAlign: 'center',padding: '11px 0'};
    },
    formatter: (cellContent, row) => (
      // <div className='checkbox disabled'>
        <label className="text-center w-100">
          {console.error("rowConet---",row)}
          <input
            type='checkbox'
            // checked={row.roles.admin}
            checked={row.roles?row.roles.admin:false}
            onChange={() => {}}/>
        </label>
      // </div>
    ),
  },
  {
    dataField: 'active',
    text: 'Active',
    style: { width: '55px' },
    headerStyle: (colum, colIndex) => {
      return { width: '55px', textAlign: 'center',padding: '11px 0' };
    },
    formatter: (cellContent, row) => (
      // <div className='checkbox disabled'>
        <label className="text-center w-100">
          <input type='checkbox' checked={row.active} onChange={() => {}} />
        </label>
      // </div>
    ),
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: 'Actions',
    headerStyle: (colum, colIndex) => {
      return { width: '130px'};
    },
    formatter: (cellContent, row) => {
      const tempState = outerThis.state;
      return (
        <div>
          <button
            className='trips_button btn btn-primary'
            onClick={() => {
              // this.setState({ edit_user_id: usr.id });
              const tempState = outerThis.state;
              tempState.edit_user_id = row.id;
              tempState.opp = 'update';
              outerThis.updateState(tempState);
              console.log('CLICKED ON ', outerThis.state);
            }}
          >
            Edit
          </button>
          <button
            className='trips_button btn btn-danger ml-1'
            onClick={() => {
              outerThis.updateState({dialogOpen:true,deleteRow:row.id})
              // outerThis.deleteUser(row.id);
              // // outerThis.setState({ users: [] });
              // outerThis.fetchUsers(outerThis.state, outerThis.updateState);
            }}>
            Delete
          </button>
        </div>
      );
    },
  },
];
class TabUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_user_id: '',
      orderBy: 'name',
      sortBy: 'asc',
      opp: 'create',
      users: [],
      dialogOpen:false,
      deleteRow:''
    };
    this.userRef = firebase.auth();
    this.initialState = this.state;
    this.fetchUsers = this.fetchUsers.bind(this);
    this.updateState = this.setState.bind(this);
    this.resetEditUserId = this.resetEditUserId.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    outerThis = this;
    // this.setState(this.initialState);
    this.fetchUsers(this.state, this.updateState);
  }

  // async fetchCompanyProfile(state, updateState) {
  //   var db = firebase.firestore();
  //   var adminArr = [];
  //  await db.collection('VirtualUsers')
  //     .where('email', '==', this.userRef.currentUser.email)
  //     .get()
  //     .then(function (querySnapshot) {
  //       querySnapshot.forEach(function (doc) {
  //         localStorage.setItem('current_user_uid', doc.id);
  //         // if (doc.data().company_profile) {
  //         //   const tempState = state;
  //         //   tempState.company_profile = doc.data().company_profile;
  //         //   storage
  //         //     .ref()
  //         //     .child('misc/' + doc.data().company_profile.image)
  //         //     .getDownloadURL()
  //         //     .then(function (url) {
  //         //       tempState.company_image_blob = url;
  //         //       updateState(tempState);
  //         //     })
  //         //     .catch(function (error) {
  //         //       console.log('error getting image', error);
  //         //     });
  //         // }
  //         adminArr.push(doc.data().roles.admin);
  //       });
  //     })
  //     .catch(function (error) {
  //       console.log('Error getting documents: ', error);
  //     });
  //   }

  fetchUsers(state, updateState) {
    console.warn("@@@@@",this.state.edit_user_id)
    var adminArr = [];
    var self = this;
    var db = firebase.firestore();
    var authEmail = this.userRef.currentUser.email;
    db.collection('VirtualUsers')
      .get()
      .then(function (querySnapshot) {
        const tempState = state;
        tempState.users = [];
        updateState(tempState);
        querySnapshot.forEach(function (doc) {
          if (doc.data()) {
            let tempData = doc.data();
            if (!doc.data().name) {
              tempData.name = '';
            }
            if (!doc.data().phone) {
              tempData.phone = '';
            }
            // if (!doc.data().admin && doc.data().roles) {
            //   // console.log('ADMIN_ADMIN == ', doc.data().roles.admin);
            //   tempData.admin = doc.data().roles.admin;
            // }
            const tempState = state;
            tempState.users.push({ id: doc.id, ...tempData });
            updateState(tempState);
            console.log('ALL_USERS'+JSON.stringify(state.users));
            if(doc.data().email===authEmail){
              adminArr.push(doc.data().roles.admin)
            }
            self.props.fetchCallback(adminArr[adminArr.length-1])
          }
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }

  deleteUser(id) {
    var db = firebase.firestore();
    var theUser = db.collection('VirtualUsers').doc(id);
    theUser
      .delete()
      .then((docRef) => {})
      .catch((error) => {
        console.log('ERROR UPDATING USER', error);
      });
  }

  resetEditUserId() {
    this.updateState({ edit_user_id: '' });
    this.updateState({ opp: 'create' });
  }

  handleDeleteChoice = (choice) => {
    if(choice){
      this.deleteUser(this.state.deleteRow);
      this.setState({dialogOpen:false},()=>{
        this.fetchUsers(this.state, this.updateState);
      })  
    }
    this.setState({dialogOpen:false})
  }

  render() {
    return (
      <div className='contents-container row'>
        <AlertDialog open={this.state.dialogOpen} dialogTitle={"Are you sure you want to delete this user?"} 
        handleChoice={this.handleDeleteChoice} handleClose={()=>this.setState({dialogOpen:false})}/>
        <h1 className='col-12'>Users</h1>
        {/* Section Instructions */}
        {/* <section className='instructions col-4'></section> */}
        {/* Section Form Content */}
        <section className='content col-12 d-flex flex-column align-self-start'>
          <Fragment>
            <div edit_user_id={this.state.edit_user_id}>
              <NewUser
                edit_user_id={this.state.edit_user_id}
                resetEditUserId={this.resetEditUserId}
                opp={this.state.opp}
                preUsers={this.state.users}
                fetchUsers={() => {
                  this.fetchUsers(this.state, this.updateState);
                }}
              />
            </div>
          </Fragment>
          <div className='ml-3 mt-3'>
            <BootstrapTable
              bootstrap4
              keyField='id'
              data={this.state.users}
              columns={columns}/>
          </div>
        </section>
      </div>
    );
  }
}

export default TabUsers;
