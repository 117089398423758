import React, { useCallback, useEffect, useState, useMemo } from "react";
import "date-fns";
import { Form, Col, Alert } from "react-bootstrap";
import "date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Preview from "./Preview";
import { ckeditor_config } from "../../../Constants/urls";

import { createHotel, hotelAmenities } from "../controllers/hotels";
import {
  updateTrip,
  imageUpload,
  useIndexedState,
  FormImage,
  Field,
  CheckedField,
  DateTimeField,
  TripImage,
} from "../utils";
import {
  dateFromTime,
  stringFromDate,
  timeFromDate,
} from "../../../utils/date-utils";

const AddHotel = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [tab, setTab] = useState("details");
  const [hotels, setHotels] = useState([]);
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);
  const [isSave, setIsSave] = useState(false);
  const { modifyAt, push, removeAt, withModifyAt } = useIndexedState(
    hotels,
    setHotels
  );

  const upload = withModifyAt((hotel, modify) =>
    imageUpload("hotelImages", hotel.imageFile).then((url) =>
      modify({ ...hotel, url })
    )
  );

  const setImageFile = modifyAt((hotel, e) => ({
    ...hotel,
    imageFile: e.target.files[0],
  }));

  const removeImage = modifyAt((hotel) => ({
    ...hotel,
    url: null,
  }));

  const addHotel = push(() => createHotel());

  useEffect(() => {
    if (!trip.transfers) return;

    setHotels(trip.hotels);
  }, [setHotels, trip.hotels]);

  const onSubmit = useCallback(() => {
    const hotelData = hotels.map((x) => ({
      ...x,
      imageFile: null,
    }));

    trip.hotels = hotelData;

    updateTrip(trip.id, archiveID, {
      hotels: hotelData,
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  }, [hotels, trip.id, setIsSave]);

  const setComment = modifyAt((hotel, _e, editor) => ({
    ...hotel,
    hotelComment: editor.getData(),
  }));

  const setField = (field) =>
    modifyAt((hotel, e) => ({
      ...hotel,
      [field]: e.target.value,
    }));

  const setDateField = (field) =>
    modifyAt((hotel, date) => ({
      ...hotel,
      [field]: stringFromDate(date),
    }));

  const setTimeField = (field) =>
    modifyAt((hotel, time) => ({
      ...hotel,
      [field]: timeFromDate(time),
    }));

  const setAmenities = modifyAt((hotel, amenities) => {
    console.log("SEND");
    return {
      ...hotel,
      amenities: amenities,
    };
  });

  const renderFlashMessage = () => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {renderFlashMessage()}
      <button
        className={
          tab === "details" ? "activemarginButtonffor" : "marginButtonffor"
        }
        onClick={() => setTab("details")}
      >
        Details
      </button>
      <button
        className={
          tab === "preview" ? "activemarginButtonffor" : "marginButtonffor"
        }
        onClick={() => setTab("preview")}
      >
        Preview
      </button>

      <br />
      <br />
      {tab === "details" ? (
        <Details
          hotels={hotels}
          removeAt={removeAt}
          minDate={minDate}
          maxDate={maxDate}
          setHotels={setHotels}
          setField={setField}
          setDateField={setDateField}
          setTimeField={setTimeField}
          setAmenities={setAmenities}
          setComment={setComment}
          onSubmit={onSubmit}
          addHotel={addHotel}
          upload={upload}
          setImageFile={setImageFile}
          removeImage={removeImage}
        />
      ) : (
        <Preview />
      )}
    </>
  );
};

const DetailsItem = ({
  hotel,
  index,
  setHotels,
  setCheckinDate,
  setCheckinTime,
  setCheckoutDate,
  setCheckoutTime,
  setName,
  setPhone,
  setAddress,
  setConfirmation,
  setBedType,
  setRoomType,
  setAmenities,
  setLatitude,
  setLongitude,
  setComment,
  remove,
  minDate,
  maxDate,
  setImageFile,
  upload,
  removeImage,
}) => (
  <div key={index}>
    <hr />
    <div className="row">
      <div className="col-md-6">
        <h5>Hotel {index + 1}</h5>
      </div>
      <div className="col-md-6 text-right">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={remove}
        >
          Remove
        </Button>
      </div>
    </div>
    <hr />
    <Form>
      <Form.Row>
        <Field label="Hotel" value={hotel.hotelName} onChange={setName} />
        <Form.Group>
          <DateTimeField
            dateLabel="Check in"
            date={hotel.hotelCheckin ? hotel.hotelCheckin : minDate}
            setDate={setCheckinDate}
            dateAriaLabel="change date"
            timeLabel="Time"
            time={
              hotel.checkinTime ? dateFromTime(hotel.checkinTime) : new Date()
            }
            setTime={setCheckinTime}
            timeAriaLabel="change time"
            minDate={minDate}
            maxDate={maxDate}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Field
          label="Phone"
          value={hotel.hotelPhone}
          onChange={setPhone}
          placeholder="+41 **********"
        />
        <Form.Group>
          <DateTimeField
            dateLabel="Check out"
            date={hotel.hotelCheckout ? hotel.hotelCheckout : maxDate}
            setDate={setCheckoutDate}
            dateAriaLabel="change date"
            timeLabel="Time"
            time={
              hotel.checkoutTime ? dateFromTime(hotel.checkoutTime) : new Date()
            }
            setTime={setCheckoutTime}
            timeAriaLabel="change time"
            minDate={hotel.hotelCheckin}
            maxDate={maxDate}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Field
          label="Address"
          value={hotel.hotelAddress}
          onChange={setAddress}
          as="textarea"
        />
        <Field
          label="Confirmation"
          value={hotel.hotelConfirmation}
          onChange={setConfirmation}
        />
      </Form.Row>
      <Form.Row>
        <Form.Group>
          <Form.Label>
            <b>Description</b>
          </Form.Label>
          <CKEditor
            editor={ClassicEditor}
            onChange={setComment}
            config={ckeditor_config}
            data={hotel.hotelComment}
            value={hotel.hotelComment}
          />
        </Form.Group>
      </Form.Row>
      <h4>Amenities</h4>
      <Form.Row>
        <Field
          label="Bed Type"
          value={hotel.hotelBedtype}
          onChange={setBedType}
        />
        <Field
          label="Room Type"
          value={hotel.hotelRoomtype}
          onChange={setRoomType}
        />
      </Form.Row>

      <div style={{ display: "flex", flexBasis: "33%", flexWrap: "wrap" }}>
        {hotelAmenities.map((amenity) => (
          <CheckedField
            style={{ width: "30%" }}
            label={amenity}
            value={hotel.amenities.includes(amenity)}
            onChange={(event) => {
              if (event.target.checked) {
                setAmenities([...hotel.amenities, amenity]);
              } else {
                setAmenities(
                  hotel.amenities.filter((item) => item !== amenity)
                );
              }
            }}
          />
        ))}
      </div>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>
            <h4>Photo</h4>
          </Form.Label>
          <FormImage onChange={setImageFile} />
          <br />
          <Button onClick={upload} variant="contained" color="primary">
            Upload
          </Button>
          &nbsp;&nbsp;
          <Button
            onClick={removeImage}
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Form.Group>
        <Form.Group as={Col}>
          <br />
          <br />
          <TripImage src={hotel.url} alt="Hotel" height="130px" width="160px" />
          <br />
          <progress value={hotel.progress} max="100" />
        </Form.Group>
      </Form.Row>
      <h4>MapMarker</h4>
      <Form.Row>
        <Field label="Latitude" value={hotel.hotelLat} onChange={setLatitude} />
        <Field
          label="Longitude"
          value={hotel.hotelLong}
          onChange={setLongitude}
        />
      </Form.Row>
    </Form>
    <hr></hr>
  </div>
);

const Details = ({
  hotels,
  removeAt,
  minDate,
  maxDate,
  setHotels,
  setField,
  setDateField,
  setTimeField,
  setAmenities,
  setComment,
  onSubmit,
  addHotel,
  upload,
  removeImage,
  setImageFile,
}) => {
  const { setCheckinDate, setCheckoutDate } = useMemo(
    () => ({
      setCheckinDate: setDateField("hotelCheckin"),
      setCheckoutDate: setDateField("hotelCheckout"),
    }),
    [setDateField]
  );
  const { setCheckinTime, setCheckoutTime } = useMemo(
    () => ({
      setCheckinTime: setTimeField("checkinTime"),
      setCheckoutTime: setTimeField("checkoutTime"),
    }),
    [setTimeField]
  );
  const {
    setName,
    setPhone,
    setAddress,
    setConfirmation,
    setBedType,
    setRoomType,
    setLatitude,
    setLongitude,
  } = useMemo(
    () => ({
      setName: setField("hotelName"),
      setPhone: setField("hotelPhone"),
      setAddress: setField("hotelAddress"),
      setConfirmation: setField("hotelConfirmation"),
      setBedType: setField("hotelBedtype"),
      setRoomType: setField("hotelRoomtype"),
      setLatitude: setField("hotelLat"),
      setLongitude: setField("hotelLong"),
    }),
    [setField]
  );

  return (
    <>
      {hotels.map((hotel, index) => (
        <DetailsItem
          key={index}
          hotel={hotel}
          index={index}
          setHotels={setHotels}
          setCheckinDate={setCheckinDate(index)}
          setCheckoutDate={setCheckoutDate(index)}
          setCheckinTime={setCheckinTime(index)}
          setCheckoutTime={setCheckoutTime(index)}
          setName={setName(index)}
          setPhone={setPhone(index)}
          setAddress={setAddress(index)}
          setConfirmation={setConfirmation(index)}
          setBedType={setBedType(index)}
          setRoomType={setRoomType(index)}
          setAmenities={setAmenities(index)}
          setLatitude={setLatitude(index)}
          setLongitude={setLongitude(index)}
          setComment={setComment(index)}
          remove={removeAt(index)}
          minDate={minDate}
          maxDate={maxDate}
          setImageFile={setImageFile(index)}
          removeImage={removeImage(index)}
          upload={upload(index)}
        />
      ))}
      <button className="marginButtonffor" onClick={addHotel}>
        Add Hotel
      </button>
      <br />
      <button onClick={onSubmit} className="marginButtonBig stickyButton">
        Save Details
      </button>
    </>
  );
};

export default withRouter(AddHotel);
