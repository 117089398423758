import React, { Component } from "react";
import firebase from "../../firebase-auth/Config";
import { Link, withRouter } from "react-router-dom";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotels: [],
      fightDetailsFill: "Details",
    };
  }
  componentDidMount() {
    var db = firebase.firestore();
    var cityRef = db.collection("Tripss").doc(this.props.match.params.id);

    cityRef.get().then((doc) => {
      if (doc.exists) {
        const allData = doc.data();
        console.log(allData, "hotels");
        if (allData && allData.hotels) {
          const baseCall = allData.hotels;
          // baseCall.map(val)
          this.setState(
            {
              hotels: baseCall,
              // minDte: allData.startDate,
              // maxDate: allData.endDate,
            },
            () => {
              console.log(this.state, "*******Hotel State******");
            }
          );
        }
      }
    });
  }

  render() {
    return (
      <>
        <h5>Preview</h5>
        {this.state.hotels.map((hotel, index) => {
          return (
            <div className="container">
              <div className="container">
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <h5>Hotel {index + 1}</h5>
                  </div>
                  <div className="col-md-6 text-right"></div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-4">
                    <b>Checkin:</b>&nbsp;&nbsp;
                    {hotel.checkinTime && <>{hotel.checkinTime}</>}
                    <br />
                    <b>Checkout:</b>&nbsp;&nbsp;
                    {hotel.checkoutTime && <>{hotel.checkoutTime}</>}
                    <br />
                    <b>Name:</b>&nbsp;{hotel.hotelName}
                    <br />
                    <b>Description:</b>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: hotel.hotelComment,
                      }}
                    />
                  </div>
                  <div className="col-md-8">
                    {hotel.hotelConfirmation !== "" ? (
                      <>
                        <b>Confirmation:</b>&nbsp;{hotel.hotelConfirmation}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {hotel.hotelBedtype !== "" ? (
                      <>
                        <b>Bed Type:</b>&nbsp;{hotel.hotelBedtype}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {hotel.hotelPhone !== "" ? (
                      <>
                        <b>Phone:</b>&nbsp;{hotel.hotelPhone}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {hotel.hotelAddress !== "" ? (
                      <>
                        <b>Address:</b>&nbsp;{hotel.hotelAddress}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {hotel.hotelRoomtype !== "" ? (
                      <>
                        <b>Room Type:</b>&nbsp;{hotel.hotelRoomtype}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>{" "}
              <h6>
                <b>Additional Services</b>
              </h6>
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    {hotel.Bar === true ? (
                      <>
                        <b>Bar</b>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {hotel.pool === true ? (
                      <>
                        <b>Pool</b>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {hotel.English === true ? (
                      <>
                        <b>English</b>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-4">
                    {hotel.Gym === true ? (
                      <>
                        <b>Gym</b>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {hotel.Laundry === true ? (
                      <>
                        <b>Laundry</b>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {hotel.Room === true ? (
                      <>
                        <b>Room</b>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-4">
                    {hotel.Mini === true ? (
                      <>
                        <b>Mini</b>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {hotel.Spa === true ? (
                      <>
                        <b>Spa</b>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {hotel.Wifi === true ? (
                      <>
                        <b>Wifi</b>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          );
        })}
      </>
    );
  }
}
export default withRouter(Preview);
