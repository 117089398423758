import React, { useState, useCallback, useEffect } from "react";
import { Col, Row, Nav } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import "./tripshow.css";
import Flights from "./itinerary/flights";
import Hotels from "./itinerary/hotels";
import CarTransfers from "./itinerary/CarTransfers";
import Resturants from "./itinerary/Resturants";
import DayOverviews from "./itinerary/DayOverviews";
import Trains from "./itinerary/Trains";
import Activities from "./itinerary/Activities";
import Transfers from "./itinerary/Transfers";

import { generateItinerary } from "../controllers/itinerary.js";

const EventItem = ({ trip, event }) => {
  console.log(event.type);
  switch (event.type) {
    case "dayOverview":
      return <DayOverviews dataPreload={event.dayOverview} />;
    case "activity":
      return <Activities dataPreload={event.activity} />;
    case "carRental":
      return <CarTransfers dataPreload={event.carRental} />;
    case "restaurant":
      return <Resturants dataPreload={event.restaurant} />;
    case "transfer":
      return <Transfers dataPreload={event.transfer} />;
    case "train":
      return <Trains trains={trip.trains} dataPreload={event.train} />;
    case "flight":
      return <Flights dataPreload={event.flight} />;
    case "hotel":
      return <Hotels dataPreload={event.hotel} />;
    default:
      throw new Error("Invalid itinerary event type");
  }
};

const EventList = ({ trip, events }) => (
  <>
    <div>
      <br />
    </div>
    {events.map((event, index) => (
      <div key={index} className="container">
        <EventItem trip={trip} event={event} />
      </div>
    ))}
  </>
);

const defaultDates = new Map();

const Itinerary = ({ trip }) => {
  const [dates, setDates] = useState(defaultDates);
  const [events, setEvents] = useState([]);
  const [flights, setFlights] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [selectedDate, setSelectedDate] = useState();

  const onFilter = useCallback(
    (e) => {
      e.preventDefault();
      setSelectedDate(e.target.text);
    },
    [setSelectedDate]
  );

  useEffect(() => {
    console.log("TRIP: ", trip);
    const { events, dates, flights, hotels } = generateItinerary(trip);
    setEvents(events);
    setDates(dates);
    setFlights(flights);
    setHotels(hotels);
  }, [trip, setHotels, setDates, setEvents, setFlights]);

  const dayEvents = selectedDate ? dates.get(selectedDate) : [];

  return (
    <>
      <br />
      <div className="container">
        <div className="titlebar">ITINERARY</div>
      </div>
      <div className="container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <EventList trip={trip} events={events} />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <EventList trip={trip} events={flights} />
                </Tab.Pane>
                <Tab.Pane eventKey="three">
                  <EventList trip={trip} events={hotels} />
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <EventList trip={trip} events={dayEvents} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <br />
                <br />
                <div style={{ padding: 10 }} />
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    className="btn btn-primary btn-block btn-sm"
                  >
                    All
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="btn-marg">
                  <Nav.Link
                    eventKey="second"
                    className="btn btn-primary btn-block btn-sm"
                  >
                    Flights
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="btn-marg">
                  <Nav.Link
                    eventKey="three"
                    className="btn btn-primary btn-block btn-sm"
                  >
                    Hotels
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="btn-marg">
                  <>
                    {Array.from(dates.keys()).map((date) => (
                      <Nav.Link
                        eventKey="fifth"
                        onClick={onFilter}
                        className="btn btn-primary btn-block btn-sm"
                      >
                        {date ? date : "No date"}
                      </Nav.Link>
                    ))}
                  </>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default withRouter(Itinerary);
