import React, { Component, Fragment } from 'react';
import firebase from '../firebase-auth/Config';

class TabAccount extends Component {
  constructor(props) {
    super(props);
    this.userRef = firebase.auth();
    this.state = {
      account: {
        name: '',
        email: '',
        password: '',
      },
    };
    this.initialState = this.state;
    this.handleChange = this.handleChange.bind(this);
    this.fetchAccount = this.fetchAccount.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.updateState = this.setState.bind(this);
  }

  componentDidMount() {
    this.setState(this.initialState);
    this.fetchAccount(this.state, this.updateState);
  }

  handleChange(e) {
    const state = this.state;
    state.account[e.target.name] = e.target.value;
    this.setState(state);
  }

  resetPassword() {
    var user = firebase.auth().currentUser;

    // this.userRef
    //   .sendPasswordResetEmail(this.userRef.currentUser.email)
    //   .then(function () {
    //     // Email sent.
    //   })
    //   .catch(function (error) {
    //     // An error happened.
    //   });

    user
      .updatePassword(this.state.account.password)
      .then(function () {
        // alert('password has been reset');
        // Update successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  fetchAccount(state, updateState) {
    var db = firebase.firestore();
    db.collection('VirtualUsers')
      .where('email', '==', this.userRef.currentUser.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          localStorage.setItem('current_user_uid', doc.id);
          const tempState = state;
          tempState.account = doc.data().account;
          if (doc.data().account) {
            updateState(tempState);
          }
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }
  updateAccount() {
    var db = firebase.firestore();
    var virtualUsers = db
      .collection('VirtualUsers')
      .doc(localStorage.getItem('current_user_uid'));
    virtualUsers
      .set(
        {
          account: this.state.account,
        },
        { merge: true }
      )
      .then((docRef) => {})
      .catch((error) => {
        console.log('ERROR UPDATING USER', error);
      });
  }
  render() {
    return (
      <div className='contents-container row'>
        <h1 className='col-12'>Account</h1>
        <section className='instructions col-4'></section>
        <section className='content col-8 d-flex flex-column align-self-start'>
          <Fragment>
            <div className='new-user-form col-12'>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Name</b>
                </label>
                <input
                  name='name'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.account.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Email</b>
                </label>
                <input
                  type='email'
                  name='email'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.account.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Password</b>
                </label>
                <input
                  type='password'
                  name='password'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.account.password}
                  onChange={this.handleChange}
                />
              </div>
              {/* <div className='btn btn-primary' onClick={this.updateAccount}>
                Save
              </div> */}
              <div className='ml-3 btn btn-danger' onClick={this.resetPassword}>
                Reset Password
              </div>
            </div>
          </Fragment>
        </section>
      </div>
    );
  }
}

export default TabAccount;
