import React from 'react';
import '../../App.css';

import {TripImage} from "../trips/utils"

function Footer(props) {
  const { user } = props;

  return (
    <div
      style={{
        color: '#fff',
        backgroundColor: 'rgb(21, 25, 29)',
        maxWidth: '100%',
        bottom: '0px',
        left: '0px',
        right: '0px',
        marginTop: '100px',
        marginBottom: '0px',
        position: 'relative',

        padding: '20px',
      }}
    >
      <div className='row'>
        <div class='w-100' style={{ textAlign: 'center' }}>
          {user ? (
            <>
              {/* <Button>Hello {user.displayName}</Button> */}
              {user.photoURL !== null ? (
                <img
                  src={user.photoURL}
                  className='profile_pic'
                  title={user.displayName}
                  alt={user.displayName}
                />
              ) : (
                <TripImage
                  src="golfAwayToursLogos/GolfAwayLogoOval.png"
                  className="logo"
                  alt="logo_image"
                />
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
export default Footer;
