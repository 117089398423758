import { stringFromDate, timeFromDate } from "../../../utils/date-utils";

export const createFlight = () => ({
  details: {
    airLineName: "",
    airlineCode: "",
    flightNumber: "",
    selectedDate: "",
  },
  airports: {
    airportDestination: "",
    airportDestinationName: "",
    airportName: "",
    airportOrigin: "",
    arrivalDate: stringFromDate(new Date()),
    arrivalTime: timeFromDate(new Date()),
    departureDate: stringFromDate(new Date()),
    departureTime: timeFromDate(new Date()),
    destinationCity: "",
    destinationTerminal: "",
    originCity: "",
    originTerminal: "",
  },
  plane: {
    ClassOfService: "",
    Codeshare: "",
    CodeshareInformationComments: "",
    Equipiment: "",
    MealService: "",
    Regional: "",
    Seats: "",
    Turboprop: "",
    Widebody: "",
    conFirmationNo: "",
  },
  mapMarkerLocation: {
    destLatitude: "",
    destLongitude: "",
    latitude: "",
    longitude: "",
  },
});
