import React, { Component } from 'react';
import firebase from '../firebase-auth/Config';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
    };
  }

  componentDidMount() {
    var arr = [];
    var db = firebase
      .firestore()
      .collection('VirtualUsers')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const { uid, email } = doc.data();

          arr.push({
            uid,
            email,
          });
        });
        this.setState({ user: arr }, () => {});
        console.log(this.state.user, 'did');
      });
  }
  // }

  render() {
    // console.log(this.state.user, "users");
    return (
      <>
        <br />
        <div className='container'>
          <div className='table-responsive'>
            <table className='table table-stripe'>
              <thead className='border-table'>
                <tr style={{ fontSize: '12px' }} className='border-table'>
                  <th className='border-table'>
                    {/* <i className="fa fa-filter"></i>  */}&nbsp;EMAIL
                  </th>
                  <th className='border-table'>
                    {/* <i className="fa fa-filter"></i> */}&nbsp;UID
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.user.map((user) => (
                  <>
                    <tr className='border-table'>
                      <td className='border-table'>{user.email}</td>
                      <td className='border-table'>{user.uid}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default Admin;
