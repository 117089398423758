import { stringFromDate, timeFromDate } from "../../../utils/date-utils";

export const createTransfer = () => ({
  MapLocation: {
    currentLatitude: "",
    currentLongitude: "",
    destinationLatitude: "",
    destinationLongitude: "",
  },
  transferComments: {
    comment: "",
  },
  transferDetails: {
    dropLocation: "",
    pickupLocation: "",
    selectedDate: stringFromDate(new Date()),
    selectedTime: timeFromDate(new Date()),
    returnTime: timeFromDate(new Date()),
    companyName: "",
    contactName: "",
    contactNumber: "",
    confirmationNumber: "",
  },
  transferURL: "",
});
