import { stringFromDate, timeFromDate } from "../../../utils/date-utils";

export const createCarRental = () => ({
  carRentalsDescription: {
    description: "",
  },
  carRentalsDetails: {
    companyName: "",
    companyPhone: "",
    dropLocation: "",
    endDate: stringFromDate(new Date()),
    endTime: timeFromDate(new Date()),
    pickUpLocation: "",
    startDate: stringFromDate(new Date()),
    startTime: timeFromDate(new Date()),
  },
  mapMarkerLocation: {
    latitude: "",
    longtitute: "",
  },
  rentalCarURL: "",
});
