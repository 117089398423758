import React, { Component } from 'react';
import firebase from '../firebase-auth/Config';
import { Tabs, Tab } from '@material-ui/core';

import withFirebaseAuth from 'react-auth-firebase';
import 'date-fns';
import TabProfile from './TabProfile';
import TabUsers from './TabUsers';

class Company extends Component {
  constructor(props) {
    super(props);
    this.userRef = firebase.auth();
    this.state = {
      id: '',
      title: '',
      user_id: '',
      current_tab: 'company_profile',
      isNotAdmin:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTabs = this.handleChangeTabs.bind(this);
  }

  componentDidMount() {
    // this.setState({ userRef: firebase.auth() });
    var db = firebase.firestore();
    db.collection('VirtualUsers')
      .where('email', '==', this.userRef.currentUser.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          localStorage.setItem('current_user_uid', doc.id);
        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }

  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };
  handleChange(event) {
    this.setState({});
  }
  handleChangeTabs(value) {
    this.setState({ value });
    console.log('Company --> State', this.state);
  }

  handleIsAdmin = (val) => this.setState({isNotAdmin:!val})

  render() {
    return (
      <div className='container' style={{ zoom: '90%' }}>
        {/* Tabs */}
        <Tabs
          value={this.state.current_tab}
          indicatorColor='primary'
          onChange={this.handleChangeTabs}
        >
          <Tab
            value='company_profile'
            label='Company Information'
            disabled={this.state.isNotAdmin}
            onClick={() => this.setState({ current_tab: 'company_profile' })}
          />
          <Tab
            value='company_users'
            label='Users'
            onClick={() => this.setState({ current_tab: 'company_users' })}
          />
        </Tabs>
        {/* Content */}
        {this.state.current_tab == 'company_profile' ? (
          <TabProfile />
        ) : (
          <TabUsers fetchCallback={this.handleIsAdmin}/>
        )}
      </div>
    );
  }
}
const authConfig = {
  email: {
    verifyOnSignup: false, // Sends verification email to user upon sign up
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
  google: {
    returnUser: true,
    returnAccessToken: true, // Returns an access token as googleAccessToken prop
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
};

export default withFirebaseAuth(Company, firebase, authConfig);
