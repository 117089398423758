import React, { Component } from 'react';
import Header from '../header/Header';
import './home.css';
import { Link } from 'react-router-dom';
import firebase from '../firebase-auth/Config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchTrips, setTripId } from '../actions/tripActions';
import { fetchProposals, setProposalId } from '../actions/proposalAction';

class Home extends Component {
  constructor(props) {
    super(props);
    this.userRef = firebase.auth();
    this.state = {
      isAdmin:false,
      allTrips: [],
      allProposals: [],
      loading: false,
      messages: [],
      users: [],
      email: '',
      uid: '',
      roles: {
        admin: false,
        user: true,
      },
      active: true,
    };
  }
  componentDidMount() {
    console.log("IIIIII",firebase.auth().currentUser.uid)
    this.props.actions.fetchTrips();
    this.props.actions.fetchProposals();
    let arr = [];
    let adminArr = [];
    var self = this;
    var user_db = firebase.firestore();
    user_db.collection('VirtualUsers')
    .where('email', '==', this.userRef.currentUser.email)
    .get()
    .then((querySnapshot)=>{
      querySnapshot.forEach(function (doc) {
        console.log("auth_data"+JSON.stringify(doc.data()));
        const data = doc.data();
        adminArr.push(data.roles.admin)
        // self.setState({isAdmin:data.roles.admin})
      })
      self.setState({isAdmin:adminArr[adminArr.length-1]})
    });
      
    var db = firebase
      .firestore()
      .collection('VirtualUsers')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const { uid, email } = doc.data();
          arr.push({
            uid,
          });
        });
        this.setState({ users: arr }, () => {
          this.clickUsers();
          console.log(JSON.stringify(this.state.users)+ '----users (from HOME.js)');
        });
      });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.myTrips) {
      this.setState({ allTrips: nextProps.myTrips });
    }
    if (nextProps.myProposals) {
      this.setState({ allProposals: nextProps.myProposals });
    }
  }

  setIdToRedux = (id) => {
    this.props.actions.setTripId({ tripId: id });
  };
  getCreatedAt = (trip) => {
    return trip.doc._document.proto.createTime.split('T')[0];
  };
  getUpdatedAt = (trip) => {
    return trip.doc._document.proto.updateTime.split('T')[0];
  };
  clickUsers = (e) => {
    const { users, dbUID } = this.state;
    const existingUser = this.state.users.filter(
      (key) => key.uid === this.userRef.currentUser.uid
    );
    if (this.userRef.currentUser && this.userRef.currentUser) {
      var db = firebase.firestore();
      var cityRef = db.collection('VirtualUsers');
      if (existingUser.length === 0) {
        var setWithMerge = cityRef
          .add({
            email: this.userRef.currentUser.email,
            uid: this.userRef.currentUser.uid,
            active: true,
            roles: {
              admin: false,
              user: true,
            },
          })
          .then((docRef) => {
            this.setState({
              id: docRef.id,
              email: '',
              uid: '',
              active: true,
            });
          });
      }
    }
  };
  render() {
    const { allTrips } = this.state;
    const { allProposals } = this.state;
    const { myTrips } = this.props;
    console.log(allProposals, 'At Home.js');
    console.log(allTrips, 'At');
    console.log(this.state.users, 'users (from HOME.js render)');

    return (
      <>
        <div style={{ margin: '50px' }}>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <center>
                <h2>
                  {' '}
                  WELCOME TO GOLF TJ <br />
                </h2>
              </center>
            </div>
            <div className='col-md-3'></div>
          </div>
        </div>
        {/* <div className="container"> */}

        <div className='col-lg-12'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='boxes_data_trip_propsal'>
                <Link to={'/Trips'}>
                  <div className='home_trip'>YOUR TRIPS</div>
                </Link>
              </div>
              <div className='bottom_boxes'>
                <div className='scrollable'>
                  RECENT TRIPS
                  <ul
                    style={{
                      listStyleType: 'none',
                      textDecoration: 'none',
                      color: '#fff',
                    }}
                  >
                    {allTrips.map((trip, ind) => (
                      <li key={ind}>
                        <i className='fa fa-pencil-square-o'>
                          &nbsp;&nbsp;
                          <Link to={`/trip_builder/${trip.key}`}>
                            <span
                              style={{
                                color: '#fff',
                                textDecoration: 'none',
                                textTransform: 'uppercase',
                              }}
                            >
                              {trip.title}
                            </span>
                          </Link>
                        </i>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='boxes_data_trip_propsal'>
                <Link to={'/proposals'}>
                  <div className='home_trip'>YOUR PROPOSALS</div>
                </Link>
              </div>
              <div className='bottom_boxes'>
                <div className='scrollable'>
                  RECENT PROPOSALS
                  <ul
                    style={{
                      listStyleType: 'none',
                      textDecoration: 'none',
                      color: '#fff',
                    }}
                  >
                    {allProposals.map((proposals) => (
                      <li key={proposals.key}>
                        <i className='fa fa-pencil-square-o'>
                          &nbsp;&nbsp;
                          <Link to={`/proposals/${proposals.key}`}>
                            <span
                              style={{
                                color: '#fff',
                                textDecoration: 'none',
                                textTransform: 'uppercase',
                              }}
                            >
                              {proposals.title}
                            </span>
                          </Link>
                        </i>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='boxes_data'>
                <Link to={'/personal_info'}>
                <div className='home_trip'>YOUR PERSONAL INFORMATION</div>
                </Link>
              </div>
              {
                this.state.isAdmin &&
                <div className='boxes_data'>
                <Link to={'/company'}>
                  <div className='home_trip'>YOUR COMPANY INFORMATION</div>
                </Link>
              </div>
             } 
              
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myTrips: state.trips.all_trips,
  myProposals: state.proposals.all_proposals,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        fetchTrips,
        setTripId,
        fetchProposals,
        setProposalId,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
