import { stringFromDate, timeFromDate } from "../../../utils/date-utils";

export const createActivity = () => ({
  activitiesDescription: {
    Description: "",
  },
  activityDetails: {
    activityAddress: "",
    activityPlace: "",
    startDate: stringFromDate(new Date()),
    startTime: timeFromDate(new Date()),
  },
  activityImageURL: "",
  mapMarkerLocation: {
    latitude: "",
    longtitute: "",
  },
});
