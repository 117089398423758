import React, { Component } from "react";
import Config from "../firebase-auth/Config";
import withFirebaseAuth from "react-auth-firebase";
import Button from "@material-ui/core/Button";

class FirebaseSignin extends Component {
  render() {
    const { signInWithGoogle, signOut } = this.props;
    // console.log(this.props, 'aman')
    return (
      <div>
        <Button
          style={{width:'100%'}}
          onClick={signInWithGoogle}
          type="submit"
          variant="contained"
          color="primary"
        >
          Sign In With Google
        </Button>
        {/* onClick={signOut} */}
        {/* <button onClick={signInWithFacebook}>Signin with Facebook</button>
        <button onClick={signInWithGithub}>Signin with Github</button>
        <button onClick={signInWithTwitter}>Signin with Twitter</button> */}
      </div>
    );
  }
}

const authConfig = {
  email: {
    verifyOnSignup: false, // Sends verification email to user upon sign up
    saveUserInDatabase: true // Saves user in database at /users ref
  },
  google: {
    returnUser: true,
    returnAccessToken: true, // Returns an access token as googleAccessToken prop
    saveUserInDatabase: true // Saves user in database at /users ref
  },
  facebook: {
    // redirect: true, // Opens a pop up by default
    returnAccessToken: true, // Returns an access token as googleAccessToken prop
    saveUserInDatabase: true // Saves user in database at /users ref
  },
  github: {
    // redirect: true,
    returnAccessToken: true,
    saveUserInDatabase: true
  },
  twitter: {
    // redirect: true,
    returnAccessToken: true,
    returnSecret: true,
    saveUserInDatabase: true
  }
};
export default withFirebaseAuth(FirebaseSignin, Config, authConfig);
