import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import withFirebaseAuth from "react-auth-firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import Newtrip from "./Newtrip";
import Triplist from "./components/trips/Trips";
import Tab from "./Tabs";
import Login from "./components/form/Login_form";
import routes from "./Constants/routes";
import Home from "./components/Home/Home";
import Header from "./components/header/Header";
import Dashboard from "./components/trips/Dashboard";
import TripShow from "./components/trips/TripShow";
import Footer from "./components/header/Footer";
import CreateTrip from "./components/trips/CreateTrip";
import EditTrip from "./components/trips/EditTrip";
import Config from "./components/firebase-auth/Config";
import EmailNotification from "./components/trips/EmailNotification";
import CreateProposal from "./components/proposals/CreateProposal";
import Proposals from "./components/proposals/proposals";
import EditProposal from "./components/proposals/editProposal";
import ProposalShow from "./components/proposals/ProposalShow";
import CopyTrip from "./components/trips/CopyTrip";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchUsers } from "./components/actions/userAction";
import Signup from "./components/form/Signup";
import Pdf from "./components/trips/Pdf";
import Admin from "./components/admin/admin";
import saveUsers from "./components/saveUsers/saveUsers";
import Company from "./components/company/Company";
import PersonalInfo from "./components/personal_information/PersonalInformation";
import firebase from "./components/firebase-auth/Config";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      email: "",
      uid: "",
      roles: {
        admin: false,
        user: true,
      },
      active: true,
      keys: [],
      dbUID: "",
      // users: ""?
    };
  }
  componentDidMount() {
    this.props.actions.fetchUsers();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.users) {
      this.setState({ users: nextProps.users });
    }
  }
  render() {
    if (this.props && this.props.user === null)
      return (
        <>
          <div className="App">
            <Router>
              <Switch>
                <Route exact path={routes.root} component={Login} />
                <Route
                  exact
                  path="/TripShow/:id/:archiveID?"
                  component={TripShow}
                />
                <Route exact path="/signup" component={Signup} />
              </Switch>
            </Router>
          </div>
        </>
      );
    const { user } = this.props;

    return (
      <>
        <div className="App">
          <Router>
            <Header />
            <Switch>
              <Route exact path={routes.root} component={Home} />
              <Route exact path={routes.root} component={Login} />
              <Route
                exact
                path="/TripShow/:id/:archiveID?"
                component={TripShow}
              />
              <Route
                exact
                path={routes.trip}
                render={(props) => <Triplist {...props} collection="Tripss" />}
              />
              <Route
                exact
                path={"/Archived-Trips-Went"}
                render={(props) => (
                  <Triplist {...props} collection="Archived Trips - Went" />
                )}
              />
              <Route
                exact
                path={"/Archived-Trips-Didnt-Go"}
                render={(props) => (
                  <Triplist
                    {...props}
                    collection="Archived Trips - Didn't Go"
                  />
                )}
              />
              <Route
                exact
                path="/CopyTrip/:id/:archiveID?"
                component={CopyTrip}
              />
              <Route exact path={routes.newtrip} component={Newtrip} />
              <Route
                exact
                path={routes.proposal_builder}
                component={CreateProposal}
              />
              <Route
                exact
                path="/proposal_builder/:id"
                component={EditProposal}
              />
              <Route exact path="/proposals/:id" component={ProposalShow} />
              <Route exact path={routes.trip_builder} component={Tab} />
              <Route exact path={routes.createtrip} component={CreateTrip} />
              <Route exact path={routes.edittrip} component={EditTrip} />
              <Route exact path={routes.dashboard} component={Dashboard} />
              <Route
                exact
                path="/trip_builder/:id/:archiveID?"
                component={Tab}
              />
              <Route exact path="/proposals" component={Proposals} />
              <Route exact path="/pdf/:id/:archiveID?" component={Pdf} />
              <Route exact path="/myadmin" component={Admin} />
              <Route exact path="/saveuser" component={saveUsers} />
              <Route exact path="/company" component={Company} />
              <Route
                exact
                path="/trip/:id/email"
                component={EmailNotification}
              />
              <Route exact path="/personal_info" component={PersonalInfo} />
            </Switch>
            {/* {user.uid === "Konu8AnYtLM4o5j9tBEdsvLUyRU2" ||"RcZg7LjT7WeR9DEZrnxaHwQ2e133" ||"lvKsRbBmZ5VqAyAXksRplcTV56y2"? ( */}
            <Footer user={user} />
            {/* ) : ( */}
            <>
              {/* {this.props.signOut()}
              {alert('You Are Not Authorised Please Contact To Admin')} */}
            </>
          </Router>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users.all_users,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        fetchUsers,
      },
      dispatch
    ),
  };
};
const authConfig = {
  email: {
    verifyOnSignup: false, // Sends verification email to user upon sign up
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
  google: {
    returnUser: true,
    returnAccessToken: true, // Returns an access token as googleAccessToken prop
    saveUserInDatabase: true, // Saves user in database at /users ref
  },
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebaseAuth(App, Config, authConfig));
