import React, { useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { updateTrip } from "../utils";

const TransferMarkerMapLocation = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [destinationLatitude, setDestinationLatitude] = useState("");
  const [destinationLongitude, setDestinationLongitude] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [carTransfer, setCarTransfer] = useState([]);

  useEffect(() => {
    if (!trip?.transfers || !trip?.transfer) return;

    const baseCall =
      Object.prototype.toString.call(trip.transfers || trip.transfer) ===
      "[object Object]"
        ? [trip.transfers || trip.transfer]
        : trip.transfers || trip.transfer;
    setCarTransfer(baseCall);
  }, [trip.id]);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      transfers: carTransfer,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {rendorFlashMessage()}
      {carTransfer.map((data, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>Transfer Car {index + 1}</h5>
            </div>
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  placeholder="Latitude"
                  onChange={(e) =>
                    setCurrentLatitude(
                      (carTransfer[index].MapLocation.currentLatitude =
                        e.target.value)
                    )
                  }
                  value={data.MapLocation.currentLatitude}
                  name="currentLatitude"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  placeholder="Longitude"
                  onChange={(e) =>
                    setCurrentLongitude(
                      (carTransfer[index].MapLocation.currentLongitude =
                        e.target.value)
                    )
                  }
                  value={data.MapLocation.currentLongitude}
                  name="currentLongitude"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Destination Latitude</Form.Label>
                <Form.Control
                  placeholder="Destination Latitude"
                  onChange={(e) =>
                    setDestinationLatitude(
                      (carTransfer[index].MapLocation.destinationLatitude =
                        e.target.value)
                    )
                  }
                  value={data.MapLocation.destinationLatitude}
                  name="destinationLatitude"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Destination Longitude</Form.Label>
                <Form.Control
                  placeholder="Destination Longitude"
                  onChange={(e) =>
                    setDestinationLongitude(
                      (carTransfer[index].MapLocation.destinationLongitude =
                        e.target.value)
                    )
                  }
                  value={data.MapLocation.destinationLongitude}
                  name="destinationLongitude"
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      ))}
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Transfer Map Location
      </button>
    </>
  );
};

export default withRouter(TransferMarkerMapLocation);
