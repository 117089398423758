import React, { useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { updateTrip } from "../utils";

const RenatlMarkerLocation = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [allCar, setAllCar] = useState([]);

  useEffect(() => {
    if (!trip?.carRentals) return;

    const baseCall =
      Object.prototype.toString.call(trip.carRentals) === "[object Object]"
        ? [trip.carRentals]
        : trip.carRentals;
    setAllCar(baseCall);
  }, []);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      carRentals: allCar,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {rendorFlashMessage()}
      {allCar.map((data, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <h5>Rental Car {index + 1}</h5>
            </div>
            {/* <div className="col-md-6 text-right">
            </div> */}
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  placeholder="Latitude"
                  onChange={(e) =>
                    setLatitude(
                      (allCar[index].mapMarkerLocation.latitude =
                        e.target.value)
                    )
                  }
                  value={data.mapMarkerLocation.latitude}
                  name="latitude"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  placeholder="Longitude"
                  onChange={(e) =>
                    setLongitude(
                      (allCar[index].mapMarkerLocation.longitude =
                        e.target.value)
                    )
                  }
                  value={data.mapMarkerLocation.longitude}
                  name="longitude"
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      ))}
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Car Rental MapMarkerLocation
      </button>
    </>
  );
};

export default withRouter(RenatlMarkerLocation);
