import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Form, Col, Alert } from "react-bootstrap";
import ".././specialCase.css";
import "date-fns";
import "date-fns";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuidv4 } from "uuid";
import { ckeditor_config } from "../../../Constants/urls";

import { createOverview } from "../controllers/overviews";
import {
  imageUpload,
  useIndexedState,
  updateTrip,
  FormImage,
  DateField,
  Field,
  TripImage,
} from "../utils";
import { stringFromDate } from "../../../utils/date-utils";
import firebase from "firebase";

const defaultTab = "Details";
const defautDays = [];

const AddOverview = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const storageRef = firebase.storage().ref();
  const [tab, setTab] = useState(defaultTab);
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);
  const [isSave, setIsSave] = useState(false);
  const [dayOverViews, setDayOverViews] = useState(defautDays);
  const { push, modifyAt, removeAt, withModifyAt } = useIndexedState(
    dayOverViews,
    setDayOverViews
  );

  const addDay = push(() => createOverview(minDate, maxDate));

  const setImageFile = modifyAt((day, e) => ({
    ...day,
    imageFile: e.target.files[0],
  }));

  const setStartDate = modifyAt((day, date) => ({
    ...day,
    startDate: stringFromDate(date),
  }));

  const setEndDate = modifyAt((day, date) => ({
    ...day,
    endDate: stringFromDate(date),
  }));

  const upload = withModifyAt((day, modify) =>
    imageUpload("dayOverViews", day.imageFile).then((url) =>
      modify({ ...day, url })
    )
  );

  const removeImage = modifyAt((day) => ({
    ...day,
    url: null,
  }));

  const setEditorDescription = modifyAt((day, _e, editor) => ({
    ...day,
    editorDescription: editor.getData(),
  }));

  const setKey = (key) =>
    modifyAt((day, e) => ({
      ...day,
      [key]: e.target.value,
    }));

  useEffect(() => {
    if (!trip.dayOverViews) return;

    setDayOverViews(
      trip.dayOverViews.map((x) => ({
        ...x,
        startDate: x.startDate,
        endDate: x.endDate,
        uuid: uuidv4(),
      }))
    );
  }, [setDayOverViews, trip.dayOverViews]);

  const renderFlashMessage = () => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={() => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  const onSubmit = useCallback(() => {
    const dayOverviewsData = dayOverViews.map((x) => ({
      ...x,
      imageFile: null,
      uuid: null,
    }));

    trip.dayOverViews = dayOverviewsData;
    updateTrip(trip.id, archiveID, {
      dayOverViews: dayOverviewsData,
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  }, [dayOverViews, setIsSave, trip.id]);

  return (
    <>
      {renderFlashMessage()}
      <button
        className={
          tab === "Details" ? "activemarginButtonffor" : "marginButtonffor"
        }
        onClick={() => setTab("Details")}
      >
        Details
      </button>
      <button
        className={
          tab === "Preview" ? "activemarginButtonffor" : "marginButtonffor"
        }
        onClick={() => setTab("Preview")}
      >
        Preview
      </button>

      <br />
      <br />
      {tab === "Preview" ? (
        <PreviewTab dayOverViews={dayOverViews} />
      ) : (
        <DetailsTab
          dayOverViews={dayOverViews}
          minDate={minDate}
          maxDate={maxDate}
          addDay={addDay}
          onSubmit={onSubmit}
          setImageFile={setImageFile}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          removeAt={removeAt}
          upload={upload}
          removeImage={removeImage}
          setEditorDescription={setEditorDescription}
          setKey={setKey}
          storage={storageRef}
        />
      )}
    </>
  );
};

const DetailsTab = ({
  dayOverViews,
  minDate,
  maxDate,
  addDay,
  onSubmit,
  setImageFile,
  setStartDate,
  setEndDate,
  removeAt,
  upload,
  removeImage,
  setKey,
  setEditorDescription,
  storage,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const uploadPdf = (file, index) => {
    setIsLoading(true);

    const temp = file[0];

    if (temp.type !== "application/pdf") {
      return alert("Only PDF files are allowed");
    }

    const fileRef = storage.child(`/files/${temp.name}`);

    fileRef
      .put(temp)
      .then((res) => {
        fileRef.getDownloadURL().then((url) => {
          const newAttachment = {
            file: url,
            fileName: temp.name,
          };

          if (dayOverViews[index]?.attachments) {
            dayOverViews[index].attachments.push(newAttachment);
          } else {
            dayOverViews[index].attachments = [newAttachment];
          }

          setIsLoading(false);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {dayOverViews.map((dayOverView, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>DayOverview {index + 1}</h5>
            </div>
            <div className="col-md-6 text-right">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={removeAt(index)}
              >
                Remove
              </Button>
            </div>
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <DateField
                  label="Start Date"
                  minDate={minDate}
                  maxDate={maxDate}
                  value={
                    dayOverView.startDate ? dayOverView.startDate : minDate
                  }
                  onChange={setStartDate(index)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <DateField
                  label="End Date"
                  minDate={minDate}
                  maxDate={maxDate}
                  value={dayOverView.endDate ? dayOverView.endDate : maxDate}
                  onChange={setEndDate(index)}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="mr-2">Photo</Form.Label>
                <FormImage onChange={setImageFile(index)} />
                <br />
                <Button
                  onClick={upload(index)}
                  className="btn btn-success btn-sm"
                >
                  Upload
                </Button>
                <Button
                  onClick={removeImage(index)}
                  className="btn btn-danger btn-sm remove"
                >
                  Remove
                </Button>
              </Form.Group>
              <Form.Group as={Col}>
                <TripImage
                  src={dayOverView.url}
                  alt="Day preview"
                  height="130px"
                  width="160px"
                />
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Primary City</Form.Label>
              <Form.Control
                as="input"
                name="OverviewDescription"
                value={dayOverView.OverviewDescription}
                onChange={setKey("OverviewDescription")(index)}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group>
                <Form.Label>
                  <b>Description</b>
                </Form.Label>
                <CKEditor
                  id={dayOverView.uuid}
                  data={dayOverView.editorDescription}
                  onChange={setEditorDescription(index)}
                  editor={ClassicEditor}
                  config={ckeditor_config}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Field
                label="Latitude"
                value={dayOverView.latitude}
                onChange={setKey("latitude")(index)}
              />
              <Field
                label="Longitude"
                value={dayOverView.longitude}
                onChange={setKey("longitude")(index)}
              />
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="mr-2">
                  <b>Attachments</b>
                </Form.Label>
                <br />
                <input
                  disabled={isLoading}
                  type="file"
                  className="mb-4"
                  accept="application/pdf"
                  onChange={(e) => uploadPdf(e.target.files, index)}
                />
                <br />

                <span>Uploaded Files:</span>

                {dayOverView.attachments?.length ? (
                  <ul>
                    {dayOverView.attachments?.map((file, fileIndex) => (
                      <li key={fileIndex}>
                        <a href={file.file} target="_blank">
                          {file.fileName}
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span> None</span>
                )}
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      ))}
      <button className="marginButtonffor" onClick={addDay}>
        Add DayOverview
      </button>
      <br />
      <br />

      <button onClick={onSubmit} className="marginButtonBig stickyButton">
        Save Details
      </button>
    </>
  );
};

const PreviewTab = ({ dayOverViews }) => (
  <>
    <h5>Overview Preview</h5>
    {dayOverViews.map((dayOverview, index) => (
      <div className="container" key={index}>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <b>Primary City</b>:&nbsp;
              {dayOverview.OverviewDescription}
              <br />
              <b>Description</b>:
              <p
                dangerouslySetInnerHTML={{
                  __html: dayOverview.editorDescription,
                }}
              />
            </div>
            <div className="col-md-4">
              <b>Start Date</b>:&nbsp;{dayOverview.startDate}
              <br />
              <b>End Date</b>: &nbsp;{dayOverview.endDate}
              <br />
              <b>latitude</b>: &nbsp;{dayOverview.latitude}
              <br />
              <b>longitude</b>: &nbsp;{dayOverview.longitude}
              <br />
            </div>
            <div className="col-md-4">
              <b>Attachments</b>: &nbsp;
              {dayOverview.attachments?.length ? (
                <ul>
                  {dayOverview.attachments?.map((file, fileIndex) => (
                    <li key={fileIndex}>
                      <a href={file.file} target="_blank">
                        {file.fileName}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <span> None</span>
              )}
            </div>
          </div>
        </div>
        <hr></hr>
      </div>
    ))}
  </>
);

export default withRouter(AddOverview);
