import React, { useState } from "react";
import "../../App.css";
import Info_body from "./info_body";

const InfoPage = () => {
  const [clicker, SetClicker] = useState("ppp");

  const formhandler = (clicker) => {
    if (clicker === "ppp") {
      return (
        <>
          <div>
            <Info_body />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="info_main" style={{ marginBottom: "150px" }}>
        <div className="info_head">
          <div className="info_head_text white">
            <h2 style={{ color: "#fff", marginTop: "1px", fontWeight: 900 }}>
              ADD INFORMATION
            </h2>
          </div>
        </div>
        {/* <Button
          onClick={() => {
            SetClicker("ppp");
          }}
          varient="Primary"
          type="submit"
        >
          Add Information
        </Button> */}

        <div name="multiple">{formhandler(clicker)}</div>
      </div>
    </>
  );
};
export default InfoPage;
