import { stringFromDate, timeFromDate } from "../../../utils/date-utils";

export const createTrain = () => ({
  MapLocation: {
    currentLatitude: "",
    currentLongitude: "",
    destinationLatitude: "",
    destinationLongitude: "",
  },
  trainDetails: {
    arrivalDate: stringFromDate(new Date()),
    arrivalTime: timeFromDate(new Date()),
    companyName: "",
    departureDate: stringFromDate(new Date()),
    departureTime: timeFromDate(new Date()),
    destinationStation: "",
    originStation: "",
    trainName: "",
    trainNumber: "",
  },
  trainURL: "",
  trainsComment: {
    trainClass: "",
    trainComment: "",
  },
});
