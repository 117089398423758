import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

const TripInformation = ({ trip, index }) => {
  const [data, setData] = useState();

  useEffect(() => {
    const tempData = index;
    tempData.file = Array.isArray(tempData.file)
      ? tempData.file
      : [
          {
            file: tempData.file,
            fileName: tempData.fileName,
          },
        ];

    setData(tempData);
  }, [index]);

  return (
    <div className="container">
      {data && (
        <>
          <br />
          <div className="titlebar">
            {data.title ? data.title : "Please Add Additional Information"}
          </div>
          <div className="card">
            <div class="row">
              <div className="col-md-8">
                <div style={{ padding: 10 }}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                  />
                  {data.file?.[0]?.file && (
                    <>
                      <br />
                      <span>Attached Files:</span>
                      <ul>
                        {data.file.map((file) => (
                          <>
                            {file?.file && (
                              <li>
                                <a href={file.file} target="_blank">
                                  {file.fileName}
                                </a>
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(TripInformation);
