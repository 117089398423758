import React, { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditor_config } from "../../../Constants/urls";
import { updateTrip } from "../utils";

const TrainDescription = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [trainComment, setTrainComment] = useState("");
  const [trainClass, setTrainClass] = useState("");
  const [isSave, setIsSave] = useState(false);
  const handleTrainComment = (e, editor) => {
    setTrainComment(editor.getData());
  };

  useEffect(() => {
    if (!trip?.trains?.trainsComment) return;

    const baseCall = trip.trains.trainsComment;
    setTrainComment(baseCall.trainComment);
    setTrainClass(baseCall.trainClass);
  }, [trip.id]);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      trains: {
        trainsComment: {
          trainComment: trainComment,
          trainClass: trainClass,
        },
        updatedAt: new Date(),
      },
    });
    setIsSave(true);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {rendorFlashMessage()}
      <Form>
        <Form.Row>
          <Form.Group>
            <Form.Label>Class</Form.Label>
            <Form.Control
              onChange={(e) => setTrainClass(e.target.value)}
              value={trainClass}
              name="trainClass"
              placeholder="Class"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group>
            <Form.Label>
              <b>Comments</b>
            </Form.Label>
            <CKEditor
              editor={ClassicEditor}
              config={ckeditor_config}
              onChange={handleTrainComment}
              data={trainComment}
              value={trainComment}
            />
          </Form.Group>
        </Form.Row>
      </Form>
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Save Train Comments
      </button>
    </>
  );
};

export default withRouter(TrainDescription);
