import React, { useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { updateTrip } from "../utils";

const Plane = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [data, setData] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [seats, setSeats] = useState("");
  const [classes, setClasses] = useState("");
  const [meal, setMeal] = useState("");
  const [equipiment, setEquipiment] = useState("");
  const [codeShare, setCodeshare] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [code, setCode] = useState("unchecked");
  const [allFlights, setAllFlights] = useState([]);

  useEffect(() => {
    if (trip?.flights) {
      const baseCall =
        Object.prototype.toString.call(trip.flights) === "[object Object]"
          ? [trip.flights]
          : trip.flights;
      setAllFlights(baseCall);
      // setData(trip);
      // const baseCall = trip.flights.plane;
      // setConfirmation(baseCall.conFirmationNo);
      // setSeats(baseCall.Seats);
      // setClasses(baseCall.ClassOfService);
      // setMeal(baseCall.MealService);
      // setEquipiment(baseCall.Equipiment);
      // setCodeshare(baseCall.CodeshareInformationComments);
      // setCode(baseCall.Codeshare);
    }
  }, [trip?.flights]);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      flights: allFlights,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };
  const handleCodeShare = (e, index) => {
    e.target.checked === true ? setCode("checked") : setCode("unchecked");
  };
  const setCodeshareInfo = (e) => {
    setCodeshare(e);
  };
  return (
    <>
      {rendorFlashMessage()}
      <>
        {allFlights.map((data, index) => (
          <div key={index}>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <h5>Flight {index + 1}</h5>
              </div>
            </div>
            <hr />
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Confirmation number</Form.Label>
                  <Form.Control
                    placeholder="conFirmationNo"
                    name="conFirmationNo"
                    value={data.plane.conFirmationNo}
                    onChange={(e) =>
                      setConfirmation(
                        (allFlights[index].plane.conFirmationNo =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Seats</Form.Label>
                  <Form.Control
                    placeholder="Seats"
                    name="Seats"
                    value={data.plane.Seats}
                    onChange={(e) =>
                      setSeats((allFlights[index].plane.Seats = e.target.value))
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Class of Service</Form.Label>
                  <Form.Control
                    placeholder="Class"
                    name="Class"
                    value={data.plane.ClassOfService}
                    onChange={(e) =>
                      setClasses(
                        (allFlights[index].plane.ClassOfService =
                          e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Meal Servicee</Form.Label>
                  <Form.Control
                    placeholder="Meal"
                    name="Meal"
                    value={data.plane.MealService}
                    onChange={(e) =>
                      setMeal(
                        (allFlights[index].plane.MealService = e.target.value)
                      )
                    }
                  />
                </Form.Group>
              </Form.Row>
              <h6>Aircraft's Details</h6>
              <Form.Group as={Col}>
                <Form.Label>Equipiment</Form.Label>
                <Form.Control
                  placeholder="Equipiment"
                  name="Equipiment"
                  value={data.plane.Equipiment}
                  onChange={(e) =>
                    setEquipiment(
                      (allFlights[index].plane.Equipiment = e.target.value)
                    )
                  }
                />
              </Form.Group>
            </Form>
            <Form.Row>
              <h6>Code-share Details</h6>
            </Form.Row>
            <Form.Row>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  checked={code === "unchecked" ? false : true}
                  onChange={(e) => handleCodeShare(e, index)}
                  label="Codeshare"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group>
                <Form.Label>Codeshare Information & Comments</Form.Label>
                <Form.Control
                  placeholder="Codeshare"
                  name="Codeshare"
                  value={data.plane.CodeshareInformationComments}
                  onChange={(e) =>
                    setCodeshareInfo(
                      (allFlights[index].plane.CodeshareInformationComments =
                        e.target.value)
                    )
                  }
                />
              </Form.Group>
            </Form.Row>
          </div>
        ))}
      </>
      <button onClick={onSubmit} className="marginButtonBig stickyButton">
        Save Details.
      </button>
    </>
  );
};

export default withRouter(Plane);
