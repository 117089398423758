import React, { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import "date-fns";
import { withRouter } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditor_config } from "../../../Constants/urls";
import { updateTrip } from "../utils";

const ActivityDescription = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [Description, setDescription] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [details, setDetails] = useState([]);

  const handleDescription = (e, index, editor) => {
    setDescription(
      (details[index].activitiesDescription.Description = editor.getData())
    );
  };

  useEffect(() => {
    if (!trip.activities) return;

    const baseCall =
      Object.prototype.toString.call(trip.activities) === "[object Object]"
        ? [trip.activities]
        : trip.activities;

    setDetails(baseCall);
  }, []);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      activities: details,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const addActivityDetails = (e, index) => {
    console.log(details, "hfdsgdsd");
    setDetails([
      ...index,
      {
        activitiesDescription: {
          Description: "",
        },
        activityDetails: {
          activityAddress: "",
          activityPlace: "",
          startDate: new Date().toDateString(),
          startTime: new Date(),
        },
        activityImageURL: "",
        mapMarkerLocation: {
          latitude: "",
          longtitute: "",
        },
      },
    ]);
  };

  const removeActivityDetails = (e, index) => {
    e.preventDefault();

    console.log(details);
    details.pop(index);
    setDetails(details);
    console.log(details);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {rendorFlashMessage()}
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            {details.map((data, index) => (
              <div key={index}>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <h5>Activity {index + 1}</h5>
                  </div>
                </div>
                <hr />
                <Form>
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>
                        <b>Description</b>
                      </Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(e, editor) =>
                          handleDescription(e, index, editor)
                        }
                        data={data.activitiesDescription.Description}
                        config={ckeditor_config}
                        value={data.activitiesDescription.Description}
                        name="Description"
                      />
                    </Form.Group>
                  </Form.Row>
                </Form>
                {/* <button onClick={(e) => removeActivityDetails(e, index)}>
                  Remove
                </button> */}
              </div>
            ))}
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
      {/* <button onClick={(e) => addActivityDetails(e, details)}>
        Add Details
      </button> */}
      <br />
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Add Activities Description
      </button>
    </>
  );
};
export default withRouter(ActivityDescription);
