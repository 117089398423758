import React, { Component } from 'react';
import firebase from '../firebase-auth/Config';
import { storage } from '../firebase-auth/Config';

class TabProfile extends Component {
  constructor(props) {
    super(props);
    this.userRef = firebase.auth();
    this.state = {
      profile: {
        first_name: '',
        last_name: '',
        office_address: '',
        longitude: '',
        latitude: '',
        office_number: '',
        mobile: '',
        alternate_email: '',
        twitter_handle: '',
        skype_username: '',
        linkedin_page: '',
        pinterest_page: '',
        timezone: '',
        about_me: '',
        contact_information: '',
        image: '',
      },
    };
    this.initialState = this.state;
    this.userId = ''
    this.handleChange = this.handleChange.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.fetchProfile = this.fetchProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.fileInput = React.createRef();
    this.updateState = this.setState.bind(this);
    // this.updateState = this.updateState.bind(this);
  }
  componentDidMount() {
    console.warn("yy----",localStorage.getItem('current_user_uid'))
    this.setState(this.initialState);
    this.fetchProfile(this.state, this.updateState);
  }

  handleChange(e) {
    const state = this.state;
    state.profile[e.target.name] = e.target.value;
    this.setState(state,()=>console.log(this.state));
  }
  handleImage(e) {
    const state = this.state;
    state.profile['image'] = this.fileInput.current.files[0].name;
    state.company_image_blob = URL.createObjectURL(e.target.files[0]);
    state.company_image = this.fileInput.current.files[0];
    this.setState(state);
  }

  fetchProfile(state, updateState) {
    var db = firebase.firestore();
    const self = this;
    db.collection('VirtualUsers')
      .where('uid', '==', this.userRef.currentUser.uid)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(function (doc) {
          // console.error("docid...",doc.id)
          localStorage.setItem('current_user_uid', doc.id);
          self.userId = String(doc.id);
          if (doc.data().profile) {
            const tempState = state;
            tempState.profile = doc.data().profile;
            updateState(tempState);
            storage
              .ref()
              .child('misc/' + doc.data().profile.image)
              .getDownloadURL()
              .then(function (url) {
                tempState.company_image_blob = url;
                updateState(tempState);
              })
              .catch(function (error) {
                console.log('error getting image', error);
              });
          }else{
            const tempState = state;
            let fullName =  doc.data().name;
            let nameArr = fullName.split(" ");
            tempState.profile.first_name = nameArr[0];
            tempState.profile.last_name = nameArr[1];
            tempState.profile.alternate_email = doc.data().email;
            updateState(tempState);
          }

        });
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }

  updateProfile() {
    console.log(this.userId)
    var db = firebase.firestore();
    // var virtualUsers = db.collection('VirtualUsers').doc(localStorage.getItem('current_user_uid'));
    var virtualUsers = db.collection('VirtualUsers').doc(this.userId);
    console.log(this.state.profile)
    virtualUsers
      .set(
        {
          profile: {...this.state.profile},
        },
        { merge: true }
      )
      .then((docRef) => {})
      .catch((error) => {
        console.log('ERROR UPDATING USER', error);
      });

    if (this.state.company_image_blob != this.initialState.company_image_blob) {
      const uploadImage = storage
        .ref()
        .child(`misc/${this.state.company_image.name}`)
        .put(this.state.company_image, { contentType: 'image/png' });
      uploadImage.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        (error) => {
          console.log(error, 'This Is error');
        },
        () => {
          storage
            .ref('misc')
            .child(this.state.profile.image.name)
            .getDownloadURL()
            .then((url) => {
              // const state = this.state.company_profile;
              // state['url'] = url;
              // this.state.progress = 0;
            });
        }
      );
    }
  }

  render() {
    return (
      <div className='contents-container row'>
        <h1 className='col-12'>Profile</h1>
        {/* Section Form Content */}
        <section className='content col-8 d-flex flex-column align-self-start'>
          <div className='col-12 d-flex align-self-start'>
            <div className='row col-12'>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>First Name</b>
                </label>
                <input
                  name='first_name'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.first_name}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>Last Name</b>
                </label>
                <input
                  name='last_name'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.last_name}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Office Address</b>
                </label>
                <input
                  name='office_address'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.office_address}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>Latitute</b>
                </label>
                <input
                  name='latitude'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.latitude}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>Longitude</b>
                </label>
                <input
                  name='longitude'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.longitude}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>Office Number</b>
                </label>
                <input
                  name='office_number'
                  type='tel'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.office_number}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>Mobile</b>
                </label>
                <input
                  name='mobile'
                  type='tel'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.mobile}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>Alternate Email (Optional)</b>
                </label>
                <input
                  name='alternate_email'
                  type='email'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.alternate_email}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>Twitter Handle (Optional)</b>
                </label>
                <input
                  name='twitter_handle'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.twitter_handle}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>Skype Username (Optional)</b>
                </label>
                <input
                  name='skype_username'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.skype_username}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-6 form-group'>
                <label className=''>
                  <b>LinkedIn Page (Optional)</b>
                </label>
                <input
                  name='linkedin_page'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.linkedin_page}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Pinterest Page URL (Optional)</b>
                </label>
                <input
                  name='pinterest_page'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.pinterest_page}
                  onChange={this.handleChange}
                />
              </div>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>About Me</b>
                </label>
                <textarea
                  name='about_me'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.about_me}
                  onChange={this.handleChange}
                ></textarea>
              </div>
              <div className='col-12 form-group'>
                <label className=''>
                  <b>Contact information</b>
                </label>
                <textarea
                  name='contact_information'
                  className='col-12 form-control'
                  placeholder=''
                  value={this.state.profile.contact_information}
                  onChange={this.handleChange}
                ></textarea>
              </div>
              <div
                className='ml-3 btn btn-primary'
                onClick={this.updateProfile}
              >
                Save
              </div>
            </div>
          </div>
        </section>
        {/* Section Instructions */}
        <section className='instructions col-4'>
          <div>
            <h3>About Your User Profile</h3>
            This information is visible to you customers in various places in
            Tripwing.
            <br />
            <br />
            All of this information is personalized to you as an individual.
            <br />
            <br />
            Writing in all-caps reflects poorly on you. Please refrain from
            doing so.
            <br />
            <br />
            <span className='text-danger'>
              Multiple agents using the same account is grounds for termination
              of your account. We are a small business like any other and don't
              appriciate theft.
            </span>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <img
              src={
                this.state.company_image_blob ||
                'https://via.placeholder.com/150x160'
              }
              height='150px'
              width='150px'
              style={
                {
                  // backgroundColor: '#000000',
                }
              }
              className='mr-2'
            />
            <input
              type='file'
              onChange={this.handleImage}
              ref={this.fileInput}
              className=''
            />
          </div>
        </section>
      </div>
    );
  }
}

export default TabProfile;
