// import { databaseRef } from "../firebase-auth/Config";
import { SET_USERS, SET_USER_ID } from '../actions/types';
import firebase from '../firebase-auth/Config';

export const fetchUsers = () => async (dispatch) => {
  const user = [];
 try{
  firebase
  .firestore()
  .collection('users')
  .get()
  .then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      // debugger;
      const { user_name, user_id, status } = doc.data();
      user.push({
        key: doc.id,
        user_name,
        status,
        user_id,
      });
    });
    dispatch({
      type: SET_USERS,
      payload: user,
    });
  });
 }catch(error){
   console.log(error)
 }
};

export const setTripId = (setUserId) => async (dispatch) => {
  dispatch({
    type: SET_USER_ID,
    payload: setUserId,
  });
};
