import {
  dateTimeFromStrings,
  dateFromString,
  stringFromDate,
} from "../../../utils/date-utils";

const activityEvent = (activity) =>
  activity.activityDetails.activityPlace
    ? {
        activity,
        timestamp: dateTimeFromStrings(
          activity.activityDetails.startDate,
          activity.activityDetails.startTime
        ),
      }
    : null;

const dayOverviewEvent = (dayOverview) =>
  dayOverview.OverviewDescription
    ? {
        dayOverview,
        timestamp: dateFromString(dayOverview.startDate),
      }
    : null;

const flightEvent = (flight) =>
  flight.details.airLineName
    ? {
        flight,
        timestamp: dateTimeFromStrings(
          flight.airports.departureDate,
          flight.airports.departureTime
        ),
      }
    : null;

const hotelEvent = (hotel) =>
  hotel.hotelName
    ? {
        hotel,
        timestamp: dateTimeFromStrings(hotel.hotelCheckin, hotel.checkinTime),
      }
    : null;

const carRentalEvent = (carRental) =>
  carRental.carRentalsDetails.companyName
    ? {
        carRental,
        timestamp: dateTimeFromStrings(
          carRental.carRentalsDetails.startDate,
          carRental.carRentalsDetails.startTime
        ),
      }
    : null;

const restaurantEvent = (restaurant) =>
  restaurant.resturantsDetails.resturantName
    ? {
        restaurant,
        timestamp: dateTimeFromStrings(
          restaurant.resturantsDetails.selectedDate,
          restaurant.resturantsDetails.selectedTime
        ),
      }
    : null;

const trainEvent = (train) =>
  train.trainDetails.companyName
    ? {
        train,
        timestamp: dateTimeFromStrings(
          train.trainDetails.departureDate,
          train.trainDetails.departureTime
        ),
      }
    : null;

const transferEvent = (transfer) =>
  transfer.transferDetails.dropLocation
    ? {
        transfer,
        timestamp: dateTimeFromStrings(
          transfer.transferDetails.selectedDate,
          transfer.transferDetails.selectedTime
        ),
      }
    : null;

const MAPPING = {
  activity: activityEvent,
  dayOverview: dayOverviewEvent,
  flight: flightEvent,
  hotel: hotelEvent,
  carRental: carRentalEvent,
  restaurant: restaurantEvent,
  train: trainEvent,
  transfer: transferEvent,
};

const event = (type) => (x) => {
  const y = MAPPING[type](x);

  return y ? { type, ...y } : null;
};

const getEvents = (trip) => {
  console.log(trip);
  return [
    ["activity", trip?.activities || []],
    ["dayOverview", trip.dayOverViews],
    ["flight", trip.flights],
    ["hotel", trip.hotels],
    ["carRental", trip.carRentals],
    ["restaurant", trip.resturants],
    ["train", [trip.trains]],
    ["transfer", trip.transfers],
  ].reduce(
    (acc, [type, xs]) => [...acc, ...xs.map(event(type)).filter(Boolean)],
    []
  );
};
const EVENT_TYPE_PRIORITY = [
  "dayOverview",
  "activity",
  "flight",
  "carRental",
  "transfer",
  "train",
  "hotel",
];

const EVENT_TYPE_TO_PRIORITY = EVENT_TYPE_PRIORITY.reduce(
  (acc, type, i) => ({ ...acc, [type]: i }),
  {}
);

const compareEvents = (a, b) => {
  if (a.timestamp < b.timestamp) return -1;
  if (a.timestamp > b.timestamp) return 1;

  return EVENT_TYPE_TO_PRIORITY[a.type] < EVENT_TYPE_TO_PRIORITY[b.type]
    ? -1
    : 1;
};

export const generateItinerary = (trip) => {
  console.log("TRIP: ", trip);
  const events = getEvents(trip).sort(compareEvents);
  console.log("EVENTS:", events);
  const dates = new Map();
  const flights = [];
  const hotels = [];
  for (const event of events) {
    const dateString = stringFromDate(event.timestamp);
    const date = dateString === "Invalid date" ? "No Date" : dateString;
    if (dates.has(date)) dates.get(date).push(event);
    else dates.set(date, [event]);

    if (event.type === "flight") flights.push(event);
    else if (event.type === "hotel") hotels.push(event);
  }

  return { dates, flights, hotels, events };
};
