import React, { useState, useEffect } from "react";
import { Form, Col, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { updateTrip } from "../utils";

const ActivityMarkerLocation = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [latitude, setLatitude] = useState("");
  const [longtitute, setLongtitute] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    if (!trip.activities) return;

    const baseCall =
      Object.prototype.toString.call(trip.activities) === "[object Object]"
        ? [trip.activities]
        : trip.activities;

    setDetails(baseCall);
  }, []);

  const onSubmit = (e) => {
    updateTrip(trip.id, archiveID, {
      activities: details,
      updatedAt: new Date(),
    });
    setIsSave(true);
  };

  const addActivityDetails = (e, index) => {
    console.log(details, "hfdsgdsd");
    setDetails([
      ...index,
      {
        activitiesDescription: {
          Description: "",
        },
        activityDetails: {
          activityAddress: "",
          activityPlace: "",
          startDate: new Date().toDateString(),
          startTime: new Date(),
        },
        activityImageURL: "",
        mapMarkerLocation: {
          latitude: "",
          longtitute: "",
        },
      },
    ]);
  };

  const removeActivityDetails = (e, index) => {
    e.preventDefault();

    console.log(details);
    details.pop(index);
    setDetails(details);
    console.log(details);
  };

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  return (
    <>
      {rendorFlashMessage()}
      {details.map((data, index) => (
        <div key={index}>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <h5>Activity {index + 1}</h5>
            </div>
            {/* <div className="col-md-6 text-right">
            </div> */}
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  placeholder="Latitude"
                  name="latitute"
                  onChange={(e) =>
                    setLatitude(
                      (details[index].mapMarkerLocation.latitude =
                        e.target.value)
                    )
                  }
                  value={data.mapMarkerLocation.latitude}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  placeholder="Longitude"
                  name="longtitute"
                  onChange={(e) =>
                    setLongtitute(
                      (details[index].mapMarkerLocation.longtitute =
                        e.target.value)
                    )
                  }
                  value={data.mapMarkerLocation.longtitute}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      ))}
      <button className="marginButtonBig stickyButton" onClick={onSubmit}>
        Add Activities MapMarkerLocation
      </button>
    </>
  );
};
export default withRouter(ActivityMarkerLocation);
