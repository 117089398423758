import React, { useCallback, useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import "date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { createRestaurant } from "../controllers/restaurants";
import { updateTrip, useIndexedState, Field, DateTimeField } from "../utils";
import {
  dateFromTime,
  stringFromDate,
  timeFromDate,
} from "../../../utils/date-utils";

const RestaurantDetails = ({
  trip,
  match: {
    params: { archiveID },
  },
}) => {
  const [minDate, setMinDate] = useState(trip.startDate);
  const [maxDate, setMaxDate] = useState(trip.endDate);
  const [isSave, setIsSave] = React.useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const { modifyAt, push, removeAt } = useIndexedState(
    restaurants,
    setRestaurants
  );

  useEffect(() => {
    if (!trip.resturants) return;

    setRestaurants(trip.resturants);
  }, [setRestaurants, trip.id]);

  const onSubmit = useCallback(() => {
    trip.resturants = restaurants;
    updateTrip(trip.id, archiveID, {
      resturants: restaurants,
      updatedAt: new Date(),
    }).then(() => setIsSave(true));
  }, [restaurants, trip.id]);

  const rendorFlashMessage = (e) => {
    if (isSave) {
      return (
        <Alert
          key={"success"}
          variant={"success"}
          onClose={(e) => setIsSave(!isSave)}
          dismissible
        >
          successfully Save Details.
        </Alert>
      );
    }
  };

  const addRestaurant = push(() => createRestaurant());

  const setName = modifyAt((restaurant, e) => ({
    ...restaurant,
    resturantsDetails: {
      ...restaurant.resturantsDetails,
      resturantName: e.target.value,
    },
  }));

  const setAddress = modifyAt((restaurant, e) => ({
    ...restaurant,
    resturantsDetails: {
      ...restaurant.resturantsDetails,
      resturantAddress: e.target.value,
    },
  }));

  const setDate = modifyAt((restaurant, date) => ({
    ...restaurant,
    resturantsDetails: {
      ...restaurant.resturantsDetails,
      selectedDate: stringFromDate(date),
    },
  }));

  const setTime = modifyAt((restaurant, time) => ({
    ...restaurant,
    resturantsDetails: {
      ...restaurant.resturantsDetails,
      selectedTime: timeFromDate(time),
    },
  }));

  return (
    <>
      {rendorFlashMessage()}
      {restaurants.map((restaurant, i) => (
        <div key={i}>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <h5>Restaurant {i + 1}</h5>
            </div>
            <div className="col-md-6 text-right">
              <Button
                variant="contained"
                color="secondary"
                // className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={removeAt(i)}
              >
                Remove
              </Button>
            </div>
          </div>
          <hr />
          <Form>
            <Form.Row>
              <Field
                label="Restaurant Name"
                placeholder="Hotel"
                name="resturantName"
                value={restaurant.resturantsDetails.resturantName}
                onChange={setName(i)}
              />
              <Form.Group>
                <DateTimeField
                  dateLabel="Date"
                  date={
                    restaurant.resturantsDetails.selectedDate
                      ? restaurant.resturantsDetails.selectedDate
                      : minDate
                  }
                  setDate={setDate(i)}
                  dateAriaLabel="change date"
                  timeLabel="Time"
                  time={
                    restaurant.resturantsDetails.selectedTime
                      ? dateFromTime(restaurant.resturantsDetails.selectedTime)
                      : new Date()
                  }
                  setTime={setTime(i)}
                  timeAriaLabel="change time"
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Field
                label="Address"
                as="textarea"
                placeholder="Address"
                name="resturantAddress"
                value={restaurant.resturantsDetails.resturantAddress}
                onChange={setAddress(i)}
              />
              <Form.Group>
                <FormControlLabel
                  control={<Checkbox value="remember" color="" />}
                  label="Reservation Mode"
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      ))}
      <button className="marginButtonffor" onClick={addRestaurant}>
        Add Restaurant
      </button>
      <br />
      <br />
      <button onClick={onSubmit} className="marginButtonBig stickyButton">
        Save Restaurant
      </button>
    </>
  );
};

export default withRouter(RestaurantDetails);
