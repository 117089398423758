import React from "react";
import "../tripshow.css";

import { TripImage } from "../../utils";

// TODO: clean this up
const Hotels = ({ dataPreload }) => {
  return (
    <>
      {dataPreload ? (
        <div>
          <>
            {dataPreload.hotelName !== "" ? (
              <>
                <div style={{ padding: 10 }} />
                <br />
                <div>
                  <div className="titlebar">
                    {dataPreload.hotelName ? dataPreload.hotelName : ""}
                  </div>
                </div>
                <div className="card">
                  <div className="scrollablecard">
                    <div>
                      <div className="row">
                        <div className="col-md-5">
                          <p>
                            <i
                              class="fa fa-clock-o"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            ></i>
                            Check In:&nbsp;
                            {dataPreload.hotelCheckin}
                          </p>

                          <p>
                            <i
                              class="fa fa-clock-o"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            ></i>
                            Check Out:&nbsp;
                            {dataPreload.hotelCheckout}
                          </p>

                          {dataPreload.hotelAddress && (
                            <p>
                              <i
                                class="fa fa-map-marker"
                                aria-hidden="true"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Address: {dataPreload.hotelAddress}
                            </p>
                          )}

                          <br />

                          {dataPreload.hotelPhone && (
                            <p>
                              <i
                                class="fa fa-phone-square"
                                aria-hidden="true"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Phone:{dataPreload.hotelPhone}
                            </p>
                          )}

                          {dataPreload.hotelRoomtype && (
                            <p>
                              Room Type:&nbsp;
                              {dataPreload.hotelRoomtype}
                            </p>
                          )}

                          {dataPreload.hotelBedtype && (
                            <p>
                              <i
                                class="fa fa-bed"
                                aria-hidden="true"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Bed Type:&nbsp;
                              {dataPreload.hotelBedtype}
                            </p>
                          )}

                          {dataPreload.hotelConfirmation && (
                            <p>
                              <i
                                class="fa fa-hashtag"
                                aria-hidden="true"
                                style={{ marginRight: "6px" }}
                              ></i>
                              Confirmation Number:&nbsp;
                              {dataPreload.hotelConfirmation}
                            </p>
                          )}

                          {dataPreload.hotelComment && (
                            <p>
                              <i
                                class="fa fa-commenting"
                                aria-hidden="true"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Description:{" "}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: dataPreload.hotelComment,
                                }}
                              />
                            </p>
                          )}
                        </div>
                        {dataPreload.url !== "" ? (
                          <div className="col-md-7">
                            <TripImage
                              src={dataPreload.url}
                              defaultURL={null}
                              alt="Hotel"
                              width="400"
                              height="280"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          {dataPreload.amenities.length > 0 && (
                            <>
                              <h5 style={{ marginTop: "20px" }}>
                                <b>Amenities</b>
                              </h5>
                              <div>
                                {dataPreload.amenities.map((amenity) => (
                                  <p>{amenity}</p>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        </div>
      ) : (
        ""
      )}{" "}
    </>
  );
};

export default Hotels;
