import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form, Col, Button } from "react-bootstrap";
import firebase from "../firebase-auth/Config";
class CreateProposal extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection("proposals");
    this.state = {
      proposalTitle: "",
      proposalDescription: ""
    };
  }
  inputChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };
  onSubmit = e => {
    e.preventDefault();
    const { proposalTitle, proposalDescription } = this.state;
    this.ref
      .add({
        proposalTitle,
        proposalDescription
      })
      .then(docRef => {
        this.setState({
          id: docRef.id,
          proposalTitle: "",
          proposalDescription: ""
        });
        this.props.history.push("/proposal_builder/" + this.state.id);
      })
      .catch(error => {
        console.error("Error adding document: ", error);
      });
  };
  render() {
    const { proposalTitle } = this.state;
    return (
      <>
        <br />
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12"> */}
            <div className="edit_proposal">
              <h2>ADD PROPOSAL</h2>
              <b>
                Make an awesome proposal in seconds with our proposal building
                tool.
              </b>
              {/* </div> */}
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="col-md-8">
            <div className="panel panel-default">
              <div className="panel-body">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label for="title">
                      <b>Title:</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="proposalTitle"
                      value={proposalTitle}
                      onChange={this.inputChange}
                      placeholder="Title"
                    />
                  </div>
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateProposal;
