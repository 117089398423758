import React, { useEffect, useState } from "react";
import "date-fns";
import { withRouter } from "react-router-dom";

const Preview = ({ trip }) => {
  const [carRental, setCarRental] = useState([]);

  useEffect(() => {
    if (!trip?.carRentals) return;

    const baseCall =
      Object.prototype.toString.call(trip.carRentals) === "[object Object]"
        ? [trip.carRentals]
        : trip.carRentals;
    setCarRental(baseCall);
  }, []);

  var flightdetails;
  if (carRental == "") {
    flightdetails = " No CarRental";
  } else {
    flightdetails = "Activities Preview";
  }

  return (
    <>
      <p>
        {flightdetails}
        <>
          {carRental.map((data, index) => (
            <>
              {" "}
              <hr />
              <h5>Rental Car&nbsp;&nbsp;{[index + 1]}</h5>
              <hr />
              <br />
              {data ? (
                <div className="row">
                  <div className="col-md-4">
                    Pickup Date:&nbsp;
                    {data.carRentalsDetails.startDate} <br />
                    Pickup Time:&nbsp;
                    {data.carRentalsDetails.startTime} <br />
                    Drop Date:&nbsp;{data.carRentalsDetails.endDate} <br />
                    Drop Time:&nbsp;
                    {data.carRentalsDetails.endTime} <br />
                    Pickup Location:&nbsp;
                    {data.carRentalsDetails.pickUpLocation} <br />
                    <h6>Car Rental Description</h6>
                    Description:&nbsp;
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data.carRentalsDescription.description,
                      }}
                    />{" "}
                    <br />
                  </div>
                  <div className="col-md-4">
                    <h6>Car Rental Details</h6>
                    Company Name:&nbsp;{data.carRentalsDetails.companyName}{" "}
                    <br />
                    Company Phone:&nbsp;{
                      data.carRentalsDetails.companyPhone
                    }{" "}
                    <br />
                    Drop Location:&nbsp;{
                      data.carRentalsDetails.dropLocation
                    }{" "}
                    <br />
                  </div>
                  <div className="col-md-4">
                    <h6>Map Location</h6>
                    Latitude:&nbsp;{data.mapMarkerLocation.latitude} <br />
                    Longitude:&nbsp;{data.mapMarkerLocation.longitude}
                  </div>
                </div>
              ) : (
                "no"
              )}
            </>
          ))}
        </>
      </p>
    </>
  );
};

export default withRouter(Preview);
