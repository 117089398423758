import { v4 as uuidv4 } from "uuid";
import { stringFromDate } from "../../../utils/date-utils";

export const createOverview = () => ({
  uuid: uuidv4(),
  startDate: stringFromDate(new Date()),
  endDate: stringFromDate(new Date()),
  editorDescription: "",
  OverviewDescription: "",
  latitude: "",
  longitude: "",
  url: "",
  attachments: [],
});
