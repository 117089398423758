import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { withRouter, Link } from "react-router-dom";

const Newtrip = () => {
  const [selectedstartDate, setSelectedstartDate] = React.useState(new Date());
  
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [Title, setTitle] = useState("");

  const handlestartDateChange = date => {
    setSelectedstartDate(date);
  };
  const handleEndDateChange = date => {
    setSelectedDate(date);
  };
  const inputFieldHandler = e => {
    e.target.name = e.target.value;
    setTitle((e.target.value = e.target.name));
    console.log(Title);
    console.log(selectedDate);
    console.log(selectedstartDate);
  };
  return (
    <div>
      <Container>
        <br />
        <Row>
          <h2>Create A Trip</h2>
        </Row>
        <Row>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  onChange={inputFieldHandler}
                  name="title"
                  placeholder="Enter Trip"
                />
              </Form.Group>

              {/* <Form.Group as={Col} >
                            <Form.Label>Title</Form.Label>
                            <Form.Control placeholder="Enter Title" />
                        </Form.Group> */}
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    value={selectedstartDate}
                    onChange={handlestartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>

              <Form.Group as={Col}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="End Date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Form.Group>
            </Form.Row>

            {/* <Form.Group >
                        <Form.Label></Form.Label>
                        <Form.Control as="textarea" placeholder="Description" />
                    </Form.Group>
                    <h3>Pricing</h3>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <Form.Label>Currency</Form.Label>
                            <Form.Control as="select" value="heelo" >
                                <option>$</option>
                                <option>$</option>
                                <option>$</option>
                            </Form.Control> 
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Price</Form.Label>
                            <Form.Control placeholder="Price" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group >
                        <Form.Label></Form.Label>
                        <Form.Control as="textarea" placeholder="PaymentTerms" />
                    </Form.Group> */}
            <Link
              to={{
                pathname: "/overview",
                state: {Title, selectedDate,selectedstartDate }
              }}
            >
              <Button variant="primary" type="submit">
                Create Trip
              </Button>
            </Link>
          </Form>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(Newtrip);
