import React from "react";
import "../tripshow.css";

// TODO: clean this up
const Flights = ({ dataPreload }) => {
  return (
    <>
      {dataPreload ? (
        <div>
          <>
            {dataPreload?.details?.airLineName === "" ? (
              ""
            ) : (
              <>
                <div style={{ padding: 10 }} />
                <br />
                <br />
                <div>
                  <div className="titlebar">
                    {/* &nbsp;&nbsp; <b>Airline</b> */}
                    {dataPreload.details.airLineName
                      ? dataPreload.details.airLineName
                      : " "}
                  </div>
                </div>
                <div className="card">
                  <div className="scrollablecard">
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <h4>
                            <b>Details</b>
                          </h4>
                          <p>
                            <b>
                              {" "}
                              Departure{" "}
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </b>
                            &nbsp;
                            {dataPreload.airports.departureDate}
                          </p>
                          <p>
                            <b>
                              Departure{" "}
                              <i class="fa fa-clock-o" aria-hidden="true"></i>
                            </b>
                            &nbsp;
                            {dataPreload.airports.departureTime}
                          </p>
                          <p>
                            <b>
                              Arrival{" "}
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </b>
                            &nbsp;
                            {dataPreload.airports.arrivalDate}
                          </p>
                          <p>
                            <b>
                              Arrival{" "}
                              <i class="fa fa-clock-o" aria-hidden="true"></i>
                            </b>
                            &nbsp;
                            {dataPreload.airports.arrivalTime}
                          </p>
                          <br />
                          {dataPreload.details.airLineName !== "" ? (
                            <>Name: &nbsp; {dataPreload.details.airLineName} </>
                          ) : (
                            ""
                          )}{" "}
                          <br />
                          {/* Airport Code: {flight.details.airlineCode} */}
                          {/* <br /> */}
                          {dataPreload.details.flightNumber !== "" ? (
                            <>
                              {/* <i class="fa fa-plane" aria-hidden="true"></i> */}
                              Flight Number:&nbsp;{" "}
                              {dataPreload.details.flightNumber}
                            </>
                          ) : (
                            ""
                          )}
                          <br />
                          {/* <i class="fa fa-calendar" aria-hidden="true"></i>
                        Date:&nbsp;{flight.details.selectedDate} */}
                          {/* <div className="col-md-5"> */}
                          <br />
                          {/* </div> */}
                        </div>
                        <div className="col-md-6">
                          <h5>
                            <b>Plane Information</b>
                          </h5>
                          {dataPreload.plane.ClassOfService !== "" ? (
                            <p>
                              <b>Class:</b>&nbsp;
                              {dataPreload.plane.ClassOfService}
                            </p>
                          ) : (
                            ""
                          )}
                          {dataPreload.plane.Codeshare === "checked" ? (
                            <p>
                              <b>Code Share:</b>&nbsp;
                              {dataPreload.plane.Codeshare === "checked"
                                ? "Yes"
                                : " "}
                            </p>
                          ) : (
                            ""
                          )}
                          {dataPreload.plane.CodeshareInformationComments !==
                          "" ? (
                            <p>
                              <b>Codeshare Information Comments:</b>&nbsp;
                              {dataPreload.plane.CodeshareInformationComments}
                            </p>
                          ) : (
                            ""
                          )}
                          {dataPreload.plane.Equipiment !== "" ? (
                            <p>
                              <b>Equipiment:</b>&nbsp;
                              {dataPreload.plane.Equipiment}
                            </p>
                          ) : (
                            ""
                          )}
                          {dataPreload.plane.MealService !== "" ? (
                            <p>
                              <b>Meal Service:</b>&nbsp;
                              {dataPreload.plane.MealService}
                            </p>
                          ) : (
                            ""
                          )}
                          {/* <p>
                                                        <b>Regional:</b>&nbsp;
                                {flight.plane.Regional === "checked"
                                                            ? "yes"
                                                            : " "}
                                                    </p> */}
                          {dataPreload.plane.Seats !== "" ? (
                            <p>
                              <b>Seats:</b>&nbsp;{dataPreload.plane.Seats}
                            </p>
                          ) : (
                            ""
                          )}
                          {dataPreload.plane.conFirmationNo !== "" ? (
                            <p>
                              <b>Confirmation No:</b>&nbsp;
                              {dataPreload.plane.conFirmationNo}
                            </p>
                          ) : (
                            ""
                          )}
                          <br />
                          <b>Airport Details</b>
                          <br />
                          {dataPreload.airports.originCity !== "" ? (
                            <>
                              Origin Airport: &nbsp;{" "}
                              {dataPreload.airports.originCity}{" "}
                            </>
                          ) : (
                            ""
                          )}{" "}
                          <br />
                          {dataPreload.airports.airportName !== "" ? (
                            <>
                              Origin Code: &nbsp;{" "}
                              {dataPreload.airports.airportName}{" "}
                            </>
                          ) : (
                            ""
                          )}{" "}
                          <br />
                          {dataPreload.airports.destinationCity !== "" ? (
                            <>
                              Destination Airport: &nbsp;{" "}
                              {dataPreload.airports.destinationCity}{" "}
                            </>
                          ) : (
                            ""
                          )}{" "}
                          <br />
                          {dataPreload.airports.airportDestinationName !==
                          "" ? (
                            <>
                              Destination Code: &nbsp;{" "}
                              {dataPreload.airports.airportDestinationName}{" "}
                            </>
                          ) : (
                            ""
                          )}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
          {/* // ))} */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Flights;
