import React, { useRef, useEffect, useState } from "react";
import RentalCarDescription from "./RentalCarDescription";
import CarRentalDetails from "./CarRentalDetails";
import RentalMarkerMapLocation from "./RentalMarkerMapLocation";
import Preview from "./Preview";

const AddRentalCar = ({ trip }) => {
  const [RentalCarfillDetails, setRentalCarfillDetails] = useState("Details");

  const renderElementForRentalCar = (RentalCarfillDetails) => {
    if (RentalCarfillDetails === "Details")
      return <CarRentalDetails trip={trip} />;
    if (RentalCarfillDetails === "carDescription")
      return <RentalCarDescription trip={trip} />;
    if (RentalCarfillDetails === "Location")
      return <RentalMarkerMapLocation trip={trip} />;
    if (RentalCarfillDetails === "Preview") return <Preview trip={trip} />;
  };

  return (
    <>
      <button
        className={
          RentalCarfillDetails === "Details"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setRentalCarfillDetails("Details")}
      >
        Details
      </button>
      <button
        className={
          RentalCarfillDetails === "carDescription"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setRentalCarfillDetails("carDescription")}
      >
        Description
      </button>
      {/* <button
        className={
          RentalCarfillDetails === "Photo"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setRentalCarfillDetails("Photo")}
      >
        Photo
      </button> */}
      <button
        className={
          RentalCarfillDetails === "Location"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setRentalCarfillDetails("Location")}
      >
        Map Marker Location
      </button>
      <button
        className={
          RentalCarfillDetails === "Preview"
            ? "activemarginButtonffor"
            : "marginButtonffor"
        }
        onClick={() => setRentalCarfillDetails("Preview")}
      >
        Preview
      </button>
      <br />
      <br />
      {renderElementForRentalCar(RentalCarfillDetails)}
    </>
  );
};

export default AddRentalCar;
